import { ApiRequest } from "app-api";
import { createQueryParams } from "utils/create-query-params";

export function getLocationsRequest(projectId : string, filterData : any | void = null) : ApiRequest<any> {
    let url = `/v1/projects/${projectId}/locations`;
    if(filterData){
        let params = createQueryParams({
            ...filterData
        })
        url = `/v1/projects/${projectId}/locations${params}`
    }
    return {
        method : 'GET',
        url 
    }; 
}

export function getLocationRequest(projectId : string, id : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/projects/${projectId}/locations/${id}`,
    };
}

export function createLocationRequest(projectId : string, data : any) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/projects/${projectId}/locations`,
        data: data
    };
}

export function updateLocationRequest(projectId : string, data : any) : ApiRequest<any>{
    return {
        method : 'PUT',
        url: `/v1/projects/${projectId}/locations`,
        data: data
    };
}

export function deleteLocationRequest(projectId : string, id : string) : ApiRequest<any>{
    return {
        method : 'DELETE',
        url: `/v1/projects/${projectId}/locations/${id}`
    };
}
