import { ApiRequest } from "app-api";
import { createQueryParams } from "utils/create-query-params";

export function searchUsersRequest(data: any): ApiRequest<any> {
    let url = '/v1/users';
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `/v1/users/search${params}`;
    }
    return {
        method: 'GET',
        url
    };
}

export function getUsersRequest(data: any | void = null): ApiRequest<any> {
    let url = '/v1/users';
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `/v1/users${params}`;
    }
    return {
        method: 'GET',
        url
    };
}

export function getUserRequest(data: string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/users/${data}`
    };
}

export function createUserRequest(data: any): ApiRequest<any> {
    return {
        method: 'POST',
        url: '/v1/users',
        data: data
    };
}

export function updateUserRequest(data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: '/v1/users',
        data: data
    };
}

export function deleteUserRequest(data: string): ApiRequest<any> {
    return {
        method: 'DELETE',
        url: `/v1/users/${data}`
    };
}

export function updateUserPermissionRequest(userId: string, permissionId : string, value : boolean): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/users/${userId}/permission/${permissionId}`,
        data : {
            isGranted : value
        }
    };
}
