import control from '../control'
export default class controlCarousel extends control {

  static get definition() {
    return {
      mi18n: 'carousel',
      icon : '🎞️',
      type : 'carousel',
      multiple: true,
    }
  }

  configure(){
    this.config.carouselComponents = []
  }

  build() {
    const { id } = this.config
    const components = []

    const wrapper = this.markup('ul', components, { className: 'carousel-components' })
    
    this.dom = this.markup('div', wrapper, { 'data-carousel-components-id' : id })
    return this.dom 
  }

  onRender() {
    // console.log($('#' + this.config.name).val())
  }
  
  
}

control.register(['carousel'], controlCarousel)
