import { createSelector } from '@reduxjs/toolkit'
import { ApplicationState } from 'reducers'

export const getLocales = createSelector((state: ApplicationState) => state, (state : any) => {
    let types = state.locale?.types;
    if(types){
        return Object.keys(types).map((key) => ({ key, value: types[key]?.name }));
    }
    else return [];
})
