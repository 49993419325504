import { ApiRequest } from "app-api";
import { createQueryParams } from "utils/create-query-params";

export function getLocalizationsRequest(source : string, filterData : any | null = null) : ApiRequest<any>{
    let params = createQueryParams({
        source,
        ...filterData
    })
    
    return {
        method : 'GET',
        url: `/v1/localizations${params}`
    };
}

export function postLocalizationsRequest(data : any) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/localizations`,
        data : data
    };
}

export function putLocalizationsRequest(data : any) : ApiRequest<any>{
    return {
        method : 'PUT',
        url: `/v1/localizations`,
        data : data
    };
}

export function getLocalizationsKeyRequest(key : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/localizations/keys/${key}`,
    };
}

export function postLocalizationsKeyRequest(data : any) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/localizations/keys`,
        data : data
    };
}

export function getLocaleKeysRequest(source : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/localizations/keys?source=${source}`,
    };
}

export function getLocaleInterfacesRequest(source : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/localizations/interfaces?source=${source}`
    };
}