import React, { createRef, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Spin, Modal } from 'antd';
import jQuery from 'jquery';
import { apiBaseUri } from 'configs';
import DisplayLogic, { DisplayLogicHandle } from 'components/display-logic';

import './index.css';
import _ from 'lodash';

(window as any).jQuery = jQuery;
(window as any).$ = jQuery;

require("jquery-ui-sortable");
require('form-builder');

export default function Layout({ surveyId, questions, onSave }: LayoutProps) {
    const localeMappings = {
        'ar': 'ar-SA',
        // 'ar' : 'ar-TN',
        'ca': 'ca-ES',
        'cs': 'cs-CZ',
        'da': 'da-DK',
        'de': 'de-DE',
        'el': 'el-GR',
        'en': 'en-US',
        'es': 'es-ES',
        'fa': 'fa-IR',
        'fi': 'fi-FI',
        'fr': 'fr-FR',
        'he': 'he-IL',
        'hu': 'hu-HU',
        'it': 'it-IT',
        'ja': 'ja-JP',
        'my': 'my-MM',
        'nb': 'nb-NO',
        'nl': 'nl-NL',
        'pl': 'pl-PL',
        'pt': 'pt-BR',
        'qz': 'qz-MM',
        'ro': 'ro-RO',
        'ru': 'ru-RU',
        'sl': 'si-SI',
        'th': 'th-TH',
        'tr': 'tr-TR',
        'uk': 'uk-UA',
        'vi': 'vi-VN',
        'zh': 'zh-CN',
        // 'zh' : 'zh-TW',
    } as any;

    const { type: localeType } = useSelector((state: any) => state.locale);
    const { accessToken } = useSelector((state: any) => state.auth);

    const builderRef = createRef();

    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [formattedQuestions, setFormattedQuestions] = useState<any>([]);
    const [currentEditingDisplayLogic, setCurrentEditingDisplayLogic] = useState<any>({});

    const displayLogicHandleRef = useRef<DisplayLogicHandle>(null);

    useEffect(() => {
        setIsInitialized(true);
    }, []);

    useEffect(() => {
        if (Array.isArray(questions)) {
            let mapped = questions.map((q: any) => {
                //pre-process custom component mappings
                if (q.data.subtype === "hear-x-component" || q.data.subtype === "ocs-component" || q.data.subtype === "peek-component" || q.data.subtype === "geo-location") {
                    q.data.mappings = q.data.mappings.map((d: any) => {
                        
                        // prevent from reformatting
                        if (typeof d.name === 'string' || d.name instanceof String) {

                            d.name = {
                                text: d.name,
                                editable: false
                            };
    
                            d.value = {
                                editable: true,
                                text: d.value
                            }

                        }

                        return d;
                    });
                }

                return q.data
            });

            setFormattedQuestions(JSON.stringify(mapped));
        }
    }, [questions]);

    useEffect(() => {
        let locale = 'en-US';
        if (localeType && localeMappings[localeType]) {
            locale = localeMappings[localeType];
        }
        let ocsParametersNames = [
            {
                name: 'pn_impaired',
                value: 'OCS_PN_IMPAIRED'
            },
            {
                name: 'or_impaired',
                value: 'OCS_OR_IMPAIRED'
            },
            {
                name: 'sm_impaired',
                value: 'OCS_SM_IMPAIRED'
            },
            {
                name: 'w1_impaired',
                value: 'OCS_W1_IMPAIRED'
            },
            {
                name: 'w2_impaired',
                value: 'OCS_W2_IMPAIRED'
            },
            {
                name: 'wrf_impaired',
                value: 'OCS_WRF_IMPAIRED'
            },
            {
                name: 'wrt_impaired',
                value: 'OCS_WRT_IMPAIRED'
            },
            {
                name: 'em_impaired',
                value: 'OCS_EM_IMPAIRED'
            },
            {
                name: 'trs_impaired',
                value: 'OCS_TRS_IMPAIRED'
            },
            {
                name: 'trp_impaired',
                value: 'OCS_TRP_IMPAIRED'
            },
            {
                name: 'rfm_impaired',
                value: 'OCS_RFM_IMPAIRED'
            },
            {
                name: 'rfr_impaired',
                value: 'OCS_RFR_IMPAIRED'
            },
            {
                name: 'svs_impaired',
                value: 'OCS_SVS_IMPAIRED'
            },
            {
                name: 'svf_impaired',
                value: 'OCS_SVF_IMPAIRED'
            },
            {
                name: 'sis_impaired',
                value: 'OCS_SIS_IMPAIRED'
            },
            {
                name: 'sir_impaired',
                value: 'OCS_SIR_IMPAIRED'
            },
            {
                name: 'tot_completed',
                value: 'OCS_TOT_COMPLETED'
            },
            {
                name: 'tot_impaired',
                value: 'OCS_TOT_IMPAIRED'
            },
            {
                name: 'score_total',
                value: 'OCS_SCORE'
            }
        ];
        let fields = [
            {
                label: 'Geo Location',
                subtype: 'geo-location',
                enforceSubtypeAsNamePrefix: true,
                attrs: {
                    type: 'mappings',
                },
                icon: '📍',
                mappings: [
                    {
                        direction: 'output',
                        name: {
                            text: 'LATITUDE',
                            editable: false
                        },
                        value: {
                            editable: true
                        }
                    },
                    {
                        direction: 'output',
                        name: {
                            text: 'LONGITUDE',
                            editable: false
                        },
                        value: {
                            editable: true
                        }
                    }
                ]
            },
            {
                label: 'HearX Inputs',
                attrs: {
                    type: 'mappings',
                },
                icon: '👂',
                subtype: 'hear-x-component',
                enforceSubtypeAsNamePrefix: true,
                mappings: [
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_500_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_1000_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_2000_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_4000_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_500_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_1000_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_2000_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_4000_Thresh',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_500_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_1000_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_2000_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_4000_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_500_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_1000_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_2000_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_4000_Noise',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_PTA',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_PTA',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_L_Retest',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_R_Retest',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_Duration',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    },
                    {
                        direction: 'ouput',
                        name: {
                            text: 'HX_False_Rate',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: ''
                        }
                    }
                ]
            },
            {
                label: 'Peek Inputs',
                attrs: {
                    type: 'mappings',
                },
                icon: '👁️',
                subtype: 'peek-component',
                enforceSubtypeAsNamePrefix: true,
                mappings: [
                    {
                        direction: 'input',
                        name: {
                            text: 'cutoff',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'true'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'distance',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: '0.4,1.0,2.0'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'progression',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'binary'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'scale',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'near'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'diagnostics',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'true'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'levels',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: '0.0:6/6, 0.1:6/7.5'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'show',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'true'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'cantSeeButton',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'true'
                        }
                    },
                    {
                        direction: 'input',
                        name: {
                            text: 'lockVolume',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'false'
                        }
                    },
                    {
                        direction: 'output',
                        name: {
                            text: 'pass',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'PEEK_PASS'
                        }
                    },
                    {
                        direction: 'output',
                        name: {
                            text: 'logMar',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'PEEK_LOGMAR_SCORE'
                        }
                    },
                    {
                        direction: 'output',
                        name: {
                            text: 'label',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'PEEK_LABEL'
                        }
                    },
                    {
                        direction: 'output',
                        name: {
                            text: 'lux',
                            editable: false
                        },
                        value: {
                            editable: true,
                            text: 'PEEK_LUX_LEVEL'
                        }
                    }
                ]
            },
            {
                label: 'OCS Inputs',
                attrs: {
                    type: 'mappings',
                },
                icon: '🧠',
                subtype: 'ocs-component',
                enforceSubtypeAsNamePrefix: true,
                mappings: [
                    ...ocsParametersNames.map(({ name, value }) => {
                        return {
                            direction: 'output',
                            name: {
                                text: name,
                                editable: false
                            },
                            value: {
                                editable: true,
                                text: value
                            }
                        }
                    })
                ]
            },
            {
                label: 'Display Field',
                attrs: {
                    type: 'display-field',
                },
                icon: '🏷️',
            },
        ] as any[];


        const templates = {
            'display-field': function (fieldData: any) {
                const { value } = fieldData;
                let text = value ? `value of [${value}]` : 'variable name is required!';
                return {
                    field: `<div>${text}</div>`,
                }
            },
        };

        let data = {
            formData: formattedQuestions,
            disableFields: ['mappings'],
            onSave: function (_event: any, data: any) {
                onSave(JSON.parse(data));
            },
            onRequestDisplayLogic,
            accessToken,
            imageUploadUrl: `${apiBaseUri}/v1/files/upload-carousel-image?reference-id=${surveyId}`,
            imageRetrievalUrl: `${apiBaseUri}/v1/files/`,
            i18n: {
                locale
            },
            fields,
            templates
        }
        if (builderRef && isInitialized === true) {
            let component = (builderRef.current as any);
            if (component.children[0]) {
                component.removeChild(component.children[0]);
            }
            ($(component) as any).formBuilder(data);
        }
    }, [isInitialized, localeType, formattedQuestions]);

    function onRequestDisplayLogic(data: any) {
        const { type, name, value, callback, rule } = data;
        const payload = {
            type,
            name,
            value,
            rule: rule || {},
            callback
        };
        setCurrentEditingDisplayLogic(payload);
    }

    return (
        <Spin spinning={!isInitialized}>
            <div className='builder'>
                {isInitialized && <div id="fb-editor" ref={builderRef as any} />}
            </div>
            {currentEditingDisplayLogic?.name && <Modal
                title={<div></div>}
                visible={!!currentEditingDisplayLogic?.name}
                destroyOnClose={true}
                maskClosable={false}
                onCancel={() => setCurrentEditingDisplayLogic({})}
                footer={null}
                forceRender={true}
                width={1300}
            >
                <DisplayLogic ref={displayLogicHandleRef} existingRule={currentEditingDisplayLogic.rule} onSave={(data) => {
                    const { rule, name, callback } = currentEditingDisplayLogic;
                    if (name && rule) {
                        callback(data);
                        setCurrentEditingDisplayLogic({});
                    }
                }} isLoading={false} />
            </Modal>}
        </Spin>
    );

}

Layout.propTypes = {
    surveyId: PropTypes.string.isRequired,
    questions: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
};

type LayoutProps = {
    surveyId: string,
    questions: [],
    onSave: (data: any) => void
}