import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, Spin, message } from 'antd';

import { useAppDispatch } from 'store';
import { NavigateOptions, useLocation, useMatch, useNavigate } from 'react-router-dom';
import useApi from 'hooks/useApi';
import { createPasswordChangeRequest, createPasswordResetRequest } from 'api/auth.api';

import './index.css'
import { fetchIdentity } from 'reducers/thunks/permissions.thunk';

const { Title } = Typography;
const { useForm } = Form;

export default function Layout(_: LayoutProps) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const [form] = useForm();

    const messageKey = 'password-update-command-key';

    const isChangePasswordRequest = useMatch('app/change-password');

    const [allowCancel, setAllowCancel] = useState<boolean>(false);

    const [{
        error : errorPasswordChange,
        loading: loadingPasswordChange,
        data: dataPasswordChange,
        isSuccess: isSuccessPasswordChange
    }, executePasswordChangeApi] = useApi<any, any>();

    const [{
        loading: loadingPasswordReset,
        data: dataPasswordReset,
        isSuccess: isSuccessPasswordReset
    }, executePasswordResetApi] = useApi<any, any>();

    useEffect(() => {
        if (isSuccessPasswordChange) {
            message.success({ content: 'Password updated!', key : messageKey });
            dispatch(fetchIdentity(undefined) as any);
            if (allowCancel) {
                navigate(-1);
            }
        }
        else {
            if(errorPasswordChange){
                message.error({ content: errorPasswordChange, key : messageKey });
                form.setFields([
                    {
                        name: 'currentPassword',
                        errors: [ errorPasswordChange ]
                    },
                ]);
            }
        }
    }, [loadingPasswordChange, dataPasswordChange, isSuccessPasswordChange, allowCancel, errorPasswordChange]);

    useEffect(() => {
        if (isSuccessPasswordReset) {
            message.success({ content: 'Password updated!', key : messageKey });
            dispatch(fetchIdentity(undefined) as any)
        }
        else {
            if(errorPasswordChange){
                message.error({ content: errorPasswordChange, key : messageKey });
            }
        }
    }, [loadingPasswordReset, dataPasswordReset, isSuccessPasswordReset]);

    useEffect(() => {
        if(state){
            let { allowCancel: value } = state as any;
            if (value) {
                setAllowCancel(value);
            }
        }
    }, [state]);

    function onFinish(values: any) {
        message.loading({ content: 'Updating...',  key : messageKey });
        if (isChangePasswordRequest) {
            executePasswordChangeApi(createPasswordChangeRequest(values));
        }
        else {
            executePasswordResetApi(createPasswordResetRequest(values));
        }
    };

    return (
        <Spin spinning={loadingPasswordChange || loadingPasswordReset}>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={20} sm={16} md={12} lg={10} xl={8} xxl={6}>
                    <Form
                        name="basic"
                        form={form}
                        initialValues={{
                            currentPassword: '',
                            newPassword: '',
                            confirmNewPassword: ''
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        {isChangePasswordRequest && <>
                            <Title level={4}>Current Password</Title>
                            <Form.Item
                                name="currentPassword"
                                rules={[{ required: true, message: 'Please input current password!' }]}
                                hasFeedback
                            >
                                <Input placeholder={'current password'} type={'password'} />
                            </Form.Item>
                        </>}

                        <Title level={4}>New Password</Title>
                        <Form.Item
                            name="newPassword"
                            rules={[{ required: true, message: 'Please input new password!' }]}
                            hasFeedback
                        >
                            <Input placeholder={'new password'} type={'password'} />
                        </Form.Item>

                        <Title level={4}>Confirm New Password</Title>
                        <Form.Item
                            name="confirmNewPassword"
                            rules={[
                                { required: true, message: 'Please input new confirm new password!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                            ]}
                            dependencies={['newPassword']}
                            hasFeedback
                        >
                            <Input placeholder={'confirm new password'} type={'password'} />
                        </Form.Item>

                        <Form.Item style={{ "display": "grid", "justifyContent": "center" }} >
                            {allowCancel && <Button
                                style={{ minWidth: '160px', minHeight: '40px', marginRight: '10px' }}
                                onClick={() => {
                                    navigate(-1);
                                }}

                            >
                                Close
                            </Button>}
                            <Button style={{ minWidth: '160px', minHeight: '40px' }} type="primary" htmlType="submit">
                                Change Password
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Spin>
    );
}

Layout.propTypes = {

};

type LayoutProps = NavigateOptions & {

}
