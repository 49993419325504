import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IdentityInfo } from 'app-system';
import { fetchIdentity } from './thunks/permissions.thunk';

interface User {
    id : String | null
    name : String | null
}
interface AuthenticatedState {
    isAuthenticated: boolean,
    isPrivileged: boolean,
    accessToken?: string,
    user : User,
    permissions : any,
    roles : any[],
    projects : any[],
    isPasswordChangeRequested : boolean,
    isLoading : boolean
}

const initialState = {
    isAuthenticated: false,
    isPrivileged: false,
    user : { 
        id : null,
        name : null
    },
    permissions : { },
    roles : [],
    projects : [],
    isPasswordChangeRequested : false,
    isLoading : false
} as AuthenticatedState;

const counterSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated(state) {
            return {
                ...state,
                isAuthenticated : true
            }
        },
        unsetIsAuthenticated(state) {
            return {
                ...state,
                isAuthenticated : false
            }
        },
        signIn(state, action: PayloadAction<string>) {
            return {
                ...state,
                accessToken : action.payload,
                isAuthenticated : true
            }
        },
        signOut(state) {
            return {
                ...state,
                accessToken :undefined,
                isAuthenticated : false
            }
        },
        set(state, action: PayloadAction<boolean>) {
            return {
                ...state,
                isAuthenticated : action.payload
            }
        },
    },
    extraReducers: {
        [fetchIdentity.pending.type] : (state : any) => {
            state.isLoading = true;
        },
        [fetchIdentity.fulfilled.type]: (state : any, action : PayloadAction<IdentityInfo>) => { 
            let { permissions, isPasswordChangeRequested, user, roles, projects, hasSystemRole } = action.payload;
            state.permissions = permissions
            state.isPasswordChangeRequested = isPasswordChangeRequested || false
            state.roles = roles
            state.isPrivileged = hasSystemRole;
            state.projects = projects
            state.user = {
                ...user
            }
            state.isLoading = false;
        },
        [fetchIdentity.rejected.type] : (state : any) => {
            state.isLoading = false;
        },
    },
})

export const { signIn, signOut, setIsAuthenticated, unsetIsAuthenticated } = counterSlice.actions
export default counterSlice.reducer