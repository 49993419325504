import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { IndexLayout, NotFound } from './layouts';

import LandingPage from 'pages/landing';
import SignInPage from 'pages/signin';
import SignUpPage from 'pages/signup';
import ChangePassword from 'pages/change-password';
import FormBuilderTest from 'pages/form-builder-test';

import AppRouteRoot from 'pages/app';
import { NavigateSignin, NavigateSignup, NavigateNotFound, NavigateResetPassword, NavigateStatistics } from 'constants/paths';
import Statistics from 'pages/app/statistics';

const routes = [
  { 
    path: '/', 
    element: <IndexLayout /> ,
    children: [
      { path: '/', element: <LandingPage /> },
      { path: NavigateSignin, element: <SignInPage /> },
      { path: NavigateSignup, element: <SignUpPage /> },
      { path: 'form-builder-test', element: <FormBuilderTest /> },
      { path: NavigateResetPassword, element: <ChangePassword /> },
      { path: NavigateStatistics, element: <Statistics /> },
    ]
  },

  {...AppRouteRoot},
  
  { path: NavigateNotFound, element: <NotFound /> },
  { path: '*', element: <Navigate to={NavigateNotFound} replace /> }
]

export default () => useRoutes(routes);