import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Select } from 'antd';
import { useAppDispatch } from 'store';
import { changeLocale, fetchLocaleTypes } from 'reducers/thunks/locale.thunk';

import './index.css'

const { Option } = Select;

export default function View() {
    const dispatch = useAppDispatch();

    let fallback = { code: 'en', name: 'English' }

    const { type, types } = useSelector((state : any) => state.locale);

    const [candidateLocales, setCandidateLocales] = useState<any[]>([fallback]);

    useEffect(() => {
        dispatch(fetchLocaleTypes(type) as any)
    }, [])

    useEffect(() => {
        if (types) {
            let keys = Object.keys(types) as any;
            if (Array.isArray(keys) && keys.length > 0) {
                setCandidateLocales(keys.map((k : any) => ({
                    code: k,
                    name: types[k]?.name || k
                })))
            }
        }
    }, [types])

    function handleChange(value: any) {
        dispatch(changeLocale(value) as any)
    }

    let zIndex = 100000000;

    return (
        <div style={{
            'position': 'fixed',
            'right': '5px',
            'bottom': '5px',
            'zIndex': zIndex
        }}>
            <Select
                value={type}
                defaultActiveFirstOption={true}
                onChange={handleChange}
                style={{ 
                    'zIndex': zIndex,
                    'minWidth' : '120px'
                 }}
                showSearch
            >
                {candidateLocales.map((d: any) => {
                    let { code, name } = d;
                    return <Option key={code} value={code}>{code}-{name}</Option>;
                })}
            </Select>
        </div>
    );
}