import React from 'react';
import { Navigate } from 'react-router-dom';

import IndexLayout from './Layout.Index';

import { ProtectedLayout } from 'layouts';
import { NavigateChangePassword, NavigateNotFound, NavigateProfile } from 'constants/paths';

import Dashboard from './dashboard';
import Statistics from './statistics';

import Profile from './profile';

import UsersManagement from './users-management';
import RolesManagement from './roles-management';
import Localization from './localization';
import DownloadDataset from './download-dataset';
import ChangePassword from '../change-password';

import ProjectsManagement from './projects-management';
import ProjectsManagementProjectEdit from './projects-management/edit-project';
import ProjectsManagementProjectLocations from './projects-management/project-locations';

import SurveysManagement from './surveys-management';
import EditSurvey from './surveys-management/edit-survey';
import EditSurveyModule from './surveys-management/edit-survey-module';
import EditSurveyModuleSection from './surveys-management/edit-survey-module-section';
import EditSurveyModuleSectionQuestionGroup from './surveys-management/question-group';
import DisplayLogic from './surveys-management/display-logic';
import HouseholdQuestionnaire from './surveys-management/household-questionnaire';

const root = {
  element: <ProtectedLayout />,
  children: [
    {
      path: 'app',
      element: <IndexLayout />,
      children: [
        {
          path: 'dashboard',
          children: [
            {
              name: 'Dashboard',
              path: '',
              element: <Dashboard />
            }
          ]
        },
        {
          path: 'statistics',
          children: [
            {
              name: 'Statistics',
              path: '',
              element: <Statistics />
            }
          ]
        },
        {
          name: 'Users Management',
          path: 'users-management',
          element: <UsersManagement />
        },
        {
          path: 'roles-management',
          element: <RolesManagement />
        },
        {
          path: 'localization',
          element: <Localization />
        },
        {
          path: 'dataset-download',
          element: <DownloadDataset />
        },
        {
          name: 'Project Management',
          path: 'projects-management',
          children: [
            {
              path: '',
              element: <ProjectsManagement />
            },
            {
              name: 'Create',
              path: 'project/create',
              element: <ProjectsManagementProjectEdit />
            },
            {
              name: 'Edit',
              path: 'project/edit/:projectId',
              children: [
                {
                  path: '',
                  element: <ProjectsManagementProjectEdit />
                },
                {
                  name: 'Locations',
                  path: 'locations',
                  element: <ProjectsManagementProjectLocations />
                },
                {
                  name: 'Users',
                  path: 'users',
                  element: <UsersManagement />
                },
                {
                  name: 'Roles',
                  path: 'roles',
                  element: <RolesManagement />
                }
              ]
            },
          ]
        },

        {
          name: 'Surveys Management',
          path: 'surveys-management',
          children: [
            {
              path: '',
              element: <SurveysManagement />
            },
            {
              name: 'Create Survey',
              path: 'survey/create',
              element: <EditSurvey />
            },
            {
              name: 'Edit Survey',
              path: 'survey/:mode/:surveyId',
              children: [
                {
                  path: '',
                  element: <EditSurvey />,
                },
                {
                  name: 'Create Module',
                  path: 'modules/create',
                  element: <EditSurveyModule />
                },
                {
                  name: 'Household Questionnaire',
                  path: 'household-questionnaire',
                  element: <HouseholdQuestionnaire />
                },
                {
                  name: 'Edit Module',
                  path: 'modules/edit/:moduleId',
                  children: [
                    {
                      path: '',
                      element: <EditSurveyModule />
                    },
                    {
                      name: 'Display Logic',
                      path: 'display-logic',
                      element: <DisplayLogic />
                    },
                    {
                      name: 'Create Section',
                      path: 'sections/create',
                      element: <EditSurveyModuleSection />
                    },
                    {
                      name: 'Edit Section',
                      path: 'sections/edit/:sectionId',
                      children: [
                        {
                          path: '',
                          element: <EditSurveyModuleSection />,
                        },
                        {
                          name: 'Display Logic',
                          path: 'display-logic',
                          element: <DisplayLogic />
                        },
                        {
                          name: 'Create Question Group',
                          path: 'question-group/create',
                          element: <EditSurveyModuleSectionQuestionGroup />
                        },
                        {
                          name: 'Edit Question Group',
                          path: 'question-group/edit/:questionGroupId',
                          children: [
                            {
                              path: '',
                              element: <EditSurveyModuleSectionQuestionGroup />
                            },
                            {
                              name: 'Display Logic',
                              path: 'display-logic',
                              element: <DisplayLogic />
                            }
                          ]
                        },
                      ]
                    },
                  ]
                },
              ]
            },
          ]
        },

        { path: NavigateProfile, element: <Profile /> }
      ]
    },

    { path: NavigateChangePassword, element: <ChangePassword /> },

    { path: '*', element: <Navigate to={NavigateNotFound} replace /> }
  ]
}

export default root;
