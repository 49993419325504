import { ApiRequest } from "app-api";
import { createQueryParams } from "utils/create-query-params";


export function getSurveyModuleRoleAccessLevelsRequest(): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/module-role-access-levels`,
    };
}

export function getSurveysRequest(projectId : string, data: any | void = null) : ApiRequest<any>{
    let url = `/v1/projects/${projectId}/surveys`;
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `/v1/projects/${projectId}/surveys${params}`;
    }
    return {
        method: 'GET',
        url
    };
}

export function getSurveyRequest(projectId : string, id : string) : ApiRequest<any>{
    return {
        method: 'GET',
        url : `/v1/projects/${projectId}/surveys/${id}`
    };
}

export function getSurveyDataSheetRequest(projectId : string, surveyId : string) : ApiRequest<any>{
    return {
        method: 'GET',
        url : `/v1/projects/${projectId}/surveys/${surveyId}/data-sheet`
    };
}

export function createSurveyRequest(projectId : string, data: any): ApiRequest<any> {
    return {
        method: 'POST',
        url: `/v1/projects/${projectId}/surveys`,
        data: data
    };
}

export function updateSurveyRequest(projectId : string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys`,
        data 
    };
}

export function deleteSurveyRequest(projectId : string, id : string) : ApiRequest<any>{
    return {
        method : 'DELETE',
        url: `/v1/projects/${projectId}/surveys/${id}`
    };
}

export function cloneSurveyRequest(projectId : string, id : string) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/projects/${projectId}/surveys/${id}/clone`
    };
}

export function exportKeysSurveyRequest(projectId : string, id : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/projects/${projectId}/surveys/${id}/export-keys`
    };
}

export function importKeysAsFileSurveyRequest(projectId : string, id : string, file : any) : ApiRequest<any>{
    let formData = new FormData();
    formData.append('file', file)
    return {
        method : 'POST',
        url: `/v1/projects/${projectId}/surveys/${id}/import-keys`,
        data: formData,
        headers : {
            'Content-Type' : 'multipart/form-data'
        }
    };
}

export function updateSurveyHouseholdQuestionnaireRequest(projectId : string, surveyId : string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/household-questionnaire`,
        data
    };
}

export function getSurveyModulesForDisplayLogicRequest(projectId : string, surveyId : string, data: any | void = null): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules-for-display-logic`,
    };
}

export function getSurveyModulesRequest(projectId : string, surveyId : string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules`,
    };
}

export function updateSurveyModulesReOrderRequest(projectId : string, surveyId : string, data : any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/reorder`,
        data: data
    };
}

export function getSurveyModuleRequest(projectId : string, surveyId : string, moduleId: string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}`,
    };
}

export function deleteSurveyModuleRequest(projectId : string, surveyId : string, moduleId: string): ApiRequest<any> {
    return {
        method: 'DELETE',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}`,
    };
}

export function cloneSurveyModuleRequest(projectId : string, surveyId : string, id : string) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${id}/clone`
    };
}

export function updateSurveyModuleDisplayLogicRequest(projectId : string, surveyId : string, moduleId: string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/display-logic`,
        data: data
    };
}

export function updateSurveyModuleAccessLevelRequest(projectId : string, surveyId : string, moduleId: string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/access-levels`,
        data: data
    };
}

export function createSurveyModuleRequest(projectId : string, surveyId : string, data: any): ApiRequest<any> {
    return {
        method: 'POST',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules`,
        data: data
    };
}

export function getSurveyModuleSectionsRequest(projectId : string, surveyId : string, moduleId : string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections`,
    };
}

export function updateSurveyModuleSectionsReOrderRequest(projectId : string, surveyId : string, moduleId : string, data : any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/reorder`,
        data: data
    };
}

export function getSurveyModuleSectionRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}`,
    };
}

export function cloneSurveyModuleSectionRequest(projectId : string, surveyId : string, moduleId : string, id : string) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${id}/clone`
    };
}

export function updateSurveyModuleSectionDisplayLogicRequest(projectId : string, surveyId : string, moduleId: string,  sectionId: string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/display-logic`,
        data: data
    };
}

export function createSurveyModuleSectionRequest(projectId : string, surveyId : string, moduleId : string, data: any): ApiRequest<any> {
    return {
        method: 'POST',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections`,
        data: data
    };
}

export function updateSurveyModuleRequest(projectId : string, surveyId : string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules`,
        data: data
    };
}


export function updateSurveyModuleSectionRequest(projectId : string, surveyId : string, moduleId : string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections`,
        data: data
    };
}

export function deleteSurveyModuleSectionRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string): ApiRequest<any> {
    return {
        method: 'DELETE',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}`,
    };
}


export function createSurveyModuleSectionQuestionGroupRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string, data : any): ApiRequest<any> {
    return {
        method: 'POST',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups`,
        data
    };
}

export function updateSurveyModuleSectionQuestionGroupsReOrderRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string, data : any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups/reorder`,
        data: data
    };
}

export function getSurveyModuleSectionQuestionGroupsRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups`,
    };
}

export function getSurveyModuleSectionQuestionGroupRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string, id : string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups/${id}`,
    };
}

export function cloneSurveyModuleSectionQuestionGroupRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string, id : string) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups/${id}/clone`
    };
}

export function updateSurveyModuleSectionQuestionGroupRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string, data : any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups`,
        data
    };
}

export function updateSurveyModuleSectionQuestionGroupDisplayLogicRequest(projectId : string, surveyId : string, moduleId: string,  sectionId: string, questionGroupId : string, data: any): ApiRequest<any> {
    return {
        method: 'PUT',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups/${questionGroupId}/display-logic`,
        data: data
    };
}

export function deleteSurveyModuleSectionQuestionGroupsRequest(projectId : string, surveyId : string, moduleId : string, sectionId: string, id : string): ApiRequest<any> {
    return {
        method: 'DELETE',
        url: `/v1/projects/${projectId}/surveys/${surveyId}/modules/${moduleId}/sections/${sectionId}/question-groups/${id}`,
    };
}


