import React, { useEffect } from 'react';
import { Row, Col, Spin } from 'antd';

import { useAppDispatch } from 'store';

import Tile from 'components/tile';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import useWithPermissions from 'hooks/useWithPermissions';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks';

export default function Layout(props: LayoutProps) {
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const { isLoading } = useSelector((state : any) => state.auth);

    useEffect(() => {
        dispatch(setSectionHeadTitle(translate('dashboard.header', "Welcome!")));
    }, [])

    const _tiles = [
        {
            caption: 'User Management',
            icon: 'users',
            path: '/app/users-management',
            permissions: ['removed']
        },
        {
            caption: 'Data Sync',
            icon: 'sync',
            permission: 'permission-global:data:sync'
        },
        {
            caption: 'Manage Projects',
            icon: 'projects',
            path: '/app/projects-management',
            permissions: ['permission-global:project:view']
        },
        {
            caption: 'Survey Management',
            icon: 'surveys',
            path: '/app/surveys-management',
            permissions: ['permission-global:survey:view', 'permission-project:survey:view']
        },
        {
            caption: 'Dashboard',
            icon: 'dashboard',
            path: '/app/statistics',
            permissions: ['permission-global:dataset:download', 'permission-project:dataset:download']
        },
        {
            caption: 'Download Datasets',
            icon: 'download',
            path: '/app/dataset-download',
            permissions: ['permission-global:dataset:download', 'permission-project:dataset:download']
        },
        {
            caption: 'Localization',
            icon: 'localization',
            path: '/app/localization',
            permissions: ['permission-global:localization:view']
        }
    ]

    const [tiles] = useWithPermissions(_tiles);

    return (
        <div>
            <Spin spinning={isLoading}>
                {!isLoading && <Row justify="center" align="middle" style={{ minHeight: '70vh' }}>
                    <Col xs={20} sm={20} md={20} lg={20} xl={16} xxl={16}>
                        <Row justify="center" gutter={[{ xs: 4, sm: 8, md: 12, lg: 16 , xl: 16 , xxl: 16 }, { xs: 8, sm: 16, md: 24, lg: 32, xl: 16 , xxl: 16  }]}>
                            {tiles.map((i: any, index: number) => {
                                let { caption, icon, path } = i;
                                return (
                                    <Col key={index} xs={12} sm={12} md={8} lg={6} xl={6} xxl={6}>
                                        <Tile caption={caption} icon={icon} path={path} />
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>}
            </Spin>
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}