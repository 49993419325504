import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Input, Typography, Radio, Spin, Button, Empty } from 'antd';
import debounce from 'lodash.debounce';

import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';

import './index.css';
import { useApi, usePermissions } from 'hooks';
import Table from 'components/table';
import { getLocalizationsRequest, putLocalizationsRequest } from 'api/localization.api';
import { getLocaleTypesRequest } from 'api/locales.api';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import AddKeyModel, { AddKeyModelHandle } from './add-key.model';
import AddLanguageModel, { AddLanguageModelHandle } from './add-language.model';

const { Title } = Typography;
const { Search } = Input;

export default function Layout(_: LayoutProps) {
    const dispatch = useAppDispatch();

    const [columns, setColumns] = useState<any[]>([]);
    const [localizations, setLocalizations] = useState<any[]>([]);
    const [localeTypes, setLocaleTypes] = useState<any[]>([]);
    const [source, setSource] = useState<string>();
    const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false);
    const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false);
    const [unsavedLocalizations, setUnsavedLocalizations] = useState<any[]>([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

    const addKeyModelRef = useRef<AddKeyModelHandle>(null);
    const addLaguageModelRef = useRef<AddLanguageModelHandle>(null);

    const [hasLocalizationEditPermission] = usePermissions('permission-global::localization:edit');
    const [hasLocalizationKeyCreatePermission] = usePermissions('permission-global:localization:key:create');
    const [hasLocalizationLanguageCreatePermission] = usePermissions('permission-global:localization:language:create');

    const [{
        loading: loadingGetLocalizations,
        data: dataGetLocalizations,
        isSuccess: isSuccessGetLocalizations
    }, executeGetLocalizationsApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetLocaleTypes,
        data: dataGetLocaleTypes,
        isSuccess: isSuccessGetLocaleTypes
    }, executeGetLocaleTypesApi] = useApi<any, any>([]);

    const [{
        loading: loadingSaveLocalizations,
        data: dataSaveLocalizations,
        isSuccess: isSuccessSaveLocalizations
    }, executeSaveLocalizationsApi] = useApi<any, any>([]);

    const debouncedSearch = useCallback(
        debounce(nextValue => {
            if (nextValue) {
                if (nextValue.length > 2) {
                    let params = {
                        'filter-key': nextValue
                    };
                    executeGetLocalizationsApi(getLocalizationsRequest(source as string, params));
                }
            }
            else {
                executeGetLocalizationsApi(getLocalizationsRequest(source as string));
            }
        }, 500),
        [source],
    );

    useEffect(() => {
        dispatch(setSectionHeadTitle("System Localizations"));
        executeGetLocaleTypesApi(getLocaleTypesRequest());
    }, [])

    useEffect(() => {
        if (isSuccessGetLocalizations && Array.isArray(dataGetLocalizations)) {
            setLocalizations(dataGetLocalizations);
            setEditModeEnabled(dataGetLocalizations.length > 0);
        }
    }, [dataGetLocalizations, isSuccessGetLocalizations]);

    useEffect(() => {
        if (isSuccessGetLocaleTypes && Array.isArray(dataGetLocaleTypes)) {
            setLocaleTypes(dataGetLocaleTypes);
        }
    }, [dataGetLocaleTypes, isSuccessGetLocaleTypes]);

    useEffect(() => {
        if (isSuccessSaveLocalizations) {
            setIsOnEditMode(false);
        }
    }, [dataSaveLocalizations, isSuccessSaveLocalizations]);

    useEffect(() => {
        setHasUnsavedChanges(Array.isArray(unsavedLocalizations) && unsavedLocalizations.length > 0);
    }, [unsavedLocalizations]);

    useEffect(() => {
        if (Array.isArray(localizations)) {
            let unsavedItems = localizations.filter(i => i.hasUnsavedChanges);
            let hasItems = unsavedItems.length > 0;
            setHasUnsavedChanges(hasItems);
            if (hasItems) {
                setUnsavedLocalizations(unsavedItems);
            }
        }
    }, [localizations]);

    useEffect(() => {
        if (source) {
            executeGetLocalizationsApi(getLocalizationsRequest(source));
        }
    }, [source]);

    useEffect(() => {
        const columns = [
            {
                title: (text: any, record: any) => {
                    return (
                        <div style={{ display: 'inline-block', width: '100%' }}>
                            <Search
                                placeholder="search"
                                style={{ width: '100%', borderColor: '#d9d9d9' }}
                                onSearch={(value : string) => debouncedSearch(value)}
                                allowClear={true}
                            />
                        </div>
                    );
                },
                dataIndex: '_key',
                key: '_key',
                fixed: 'left',
                width: 200,
                render: (text: any, record: any) => {
                    let { _key, _interface } = record;
                    return (<>
                        <span style={{ marginLeft: '0', fontSize: '10px' }} >
                            {_interface}
                        </span>
                        <br />
                        <span style={{ marginLeft: '10px' }} >
                            {_key}
                        </span>
                    </>);
                }
            },
            {
                title: 'en-English (fallback locale)',
                dataIndex: 'en',
                key: 'en',
                fixed: 'left',
                width: 200,
                render: (text: any, record: any, index: number) => renderEditInput('en', text, record, index),
            }
        ];
        let languages = localeTypes.filter(i => i.code !== 'en' && i.isActive).map((i: any) => {
            let { code, name } = i;
            return {
                title: `${code}-${name}`,
                dataIndex: code,
                key: code,
                render: (text: any, record: any, index: number) => renderEditInput(code, text, record, index),
            };
        })

        let actions = [];

        if (hasLocalizationEditPermission) {
            actions.push({
                dataIndex: 'actions',
                key: 'actions',
                fixed: 'right',
                overridenWidth: 50,
                render: (text: any, record: any) => {
                    let { _key } = record;
                    return (
                        <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={() => addKeyModelRef.current?.openForEdit(_key)}
                        />
                    );
                },
            });
        }

        let data = [...columns, ...languages, ...actions];
        setColumns(data);
    }, [localeTypes, isOnEditMode, hasLocalizationEditPermission]);

    function onSaveChanges() {
        let data = unsavedLocalizations.map(i => ({
            ...i,
            source,
        }));
        executeSaveLocalizationsApi(putLocalizationsRequest(data))
    }

    function onLocaleValueChange(value: string, type: string, record: any, index: number) {
        let data = {
            ...record,
            [type]: value,
            hasUnsavedChanges: true
        }
        setLocalizations(items => items.map((item, i) => i === index ? data : item))
    }

    return (<>
        <Row justify="center" align="middle" >
            <Col span={23}>
                <div className={'switch'} >
                    <Title level={4} className={'title'}> Source</Title>
                    <Radio.Group value={source} onChange={(e) => e?.target?.value && setSource(e.target.value)}>
                        <Radio.Button value="web">Web</Radio.Button>
                        <Radio.Button value="mobile">Mobile</Radio.Button>
                    </Radio.Group>
                </div>

                <div style={{ float: 'right' }}>
                    {renderEditButtons()}
                </div>
            </Col>
            <Col span={23} style={{ 'marginTop': '1%' }}>
                {source && <Spin spinning={loadingGetLocalizations || loadingGetLocaleTypes}>
                    <div className="table-container" >
                        <Table
                            columns={columns as any}
                            source={localizations}
                            rowKey="key"
                            size={'small'}
                            pagination={{ pageSize: 7 }}
                            scroll={{ x: 1500, }}
                            className='localization-table'

                            maxWidthPerCell={300}
                        />
                    </div>
                </Spin>}
                {(!source) &&
                    <>
                        <Empty description={
                            <Title level={5} style={{ color : '#6597c3' }}>Please select source</Title>
                        } />
                    </>
                }
            </Col>
        </Row>

        <AddKeyModel ref={addKeyModelRef} onClose={() => {
            source && executeGetLocalizationsApi(getLocalizationsRequest(source, null));
        }} />

        <AddLanguageModel ref={addLaguageModelRef} onClose={() => {
            source && executeGetLocalizationsApi(getLocalizationsRequest(source, null));
        }} />
    </>)

    function renderEditButtons() {
        let buttons = [];

        if (hasLocalizationKeyCreatePermission) {
            let addKeyButton = <Button
                type="primary"
                onClick={() => addKeyModelRef.current?.openForAdd()}
            >
                Add Key
            </Button>
            buttons.push(addKeyButton);
        }

        if (hasLocalizationLanguageCreatePermission) {
            let addLanguageButton = <Button
                type="primary"
                onClick={() => addLaguageModelRef.current?.openForAdd()}
            >
                Add Language
            </Button>
            buttons.push(addLanguageButton);
        }

        if (hasLocalizationEditPermission) {
            if (isOnEditMode) {
                let saveChangesButton = <Button
                    type="primary"
                    loading={loadingSaveLocalizations}
                    onClick={() => onSaveChanges()}
                    disabled={!hasUnsavedChanges}
                >
                    Save Changes
                </Button>
                buttons.push(saveChangesButton)
            }

            let editButton = <Button
                shape="circle"
                icon={isOnEditMode ? <CloseCircleOutlined /> : <EditOutlined />}
                onClick={() => setIsOnEditMode(m => !m)}
                disabled={!editModeEnabled}
            />
            buttons.push(editButton)
        }


        return buttons.map((button: any, index: number) => <div key={index} style={{ marginLeft: '5px', display: 'inline-block' }}>
            {button}
        </div>)
    }

    function renderEditInput(type: string, text: string, record: any, index: number) {
        let { key } = record;
        let value = record[type];
        if (isOnEditMode) {
            return <Input
                placeholder={key}
                value={value}
                onFocus={event => event.target.select()}
                onChange={e => e?.target?.value && onLocaleValueChange(e.target.value, type, record, index)}
            />
        }
        return <span>{text}</span>
    }
}

type LayoutProps = {

}