import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BreadcrumbInfo, MenuItemInfo } from 'app-system';
import { fetchIdentity } from './thunks/permissions.thunk';


interface State {
    isDrawerOpen: boolean
    menu: MenuItemInfo,
    sectionHeadTitle?: String,
    breadcrumbs: BreadcrumbInfo[],
}

const initialState = {
    isDrawerOpen: true,
    sectionHeadTitle: undefined,
    breadcrumbs: [] as BreadcrumbInfo[]
} as State;

const counterSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        toggleDrawerState(state) {
            state.isDrawerOpen = !state.isDrawerOpen;
        },
        setSectionHeadTitle(state, action: PayloadAction<string>) {
            state.sectionHeadTitle = action.payload;
        },
        setBreadcrumbs(state, action: PayloadAction<BreadcrumbInfo[]>) {
            if(Array.isArray(action.payload)){
                state.breadcrumbs = action.payload
            }
        },
    },
    extraReducers: {
        [fetchIdentity.fulfilled.type]: (state: any, action: PayloadAction<{ menu: MenuItemInfo }>) => {
            let { menu } = action.payload;
            state.menu = menu
        },
    },
})

export const { toggleDrawerState, setSectionHeadTitle, setBreadcrumbs } = counterSlice.actions
export default counterSlice.reducer