import React, { useEffect, useState } from 'react';
import { useApi } from 'hooks';
import { Button, Col, DatePicker, Space, Row, Select, Spin, Table, Tooltip, Typography } from 'antd';
import type { DatePickerProps } from 'antd';

import { getSurveyAvailabilityStatisticsRequest } from 'api/statistics.api';

import './index.scss';
import { useSelector } from 'react-redux';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';
import { getLocationsRequest } from 'api/locations.api';
import { ClearOutlined, RedoOutlined } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;
const { Title } = Typography;
const { Option } = Select;

export default function Layout() {

    const currentUserProjectId = useSelector(getUserDefaultProjectId);

    const [overallData, setOverallData] = useState<any[]>([]);
    const [perClusterData, setPerClusterData] = useState<any[]>([]);
    const [selectedClusterId, setSelectedClusterId] = useState<number>();
    const [clusters, setClusters] = useState<any[]>([]);
    const [selectedDate, setDate] = useState<string>();
    const [selectedStartDate, setStartDate] = useState<string>();

    const datepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        setDate(dateString);
    };

    const startDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        setStartDate(dateString);
    };

    const [overallTableRefreshToken, setOverallTableRefreshToken] = useState<number>(0);
    const [perClusterTableRefreshToken, setPerClusterTableRefreshToken] = useState<number>(0);

    const [{
        loading: loadingGetOverallData,
        data: dataGetOverallData,
        isSuccess: isSuccessGetOverallData
    }, executeGetOverallDataApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetPerClusterData,
        data: dataGetPerClusterData,
        isSuccess: isSuccessGetPerClusterData
    }, executeGetPerClusterDataApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetProjectLocations,
        data: dataGetProjectLocations,
        isSuccess: isSuccessGetProjectLocations
    }, executeGetProjectLocationsApi] = useApi<any, any>();

    useEffect(() => {
        if (currentUserProjectId) {
            let params = { 'type': 'cluster' };
            executeGetProjectLocationsApi(getLocationsRequest(currentUserProjectId, params));
        }
    }, [currentUserProjectId])

    useEffect(() => {
        if (currentUserProjectId) {
            executeGetOverallDataApi(getSurveyAvailabilityStatisticsRequest(currentUserProjectId));
        }
    }, [currentUserProjectId, overallTableRefreshToken])

    useEffect(() => {
        if (currentUserProjectId) {
            let params = { 'cluster-id': selectedClusterId, 'date': selectedDate, 'start-date': selectedStartDate };
            executeGetPerClusterDataApi(getSurveyAvailabilityStatisticsRequest(currentUserProjectId, params));
        }
    }, [currentUserProjectId, selectedClusterId, selectedDate, selectedStartDate, perClusterTableRefreshToken])

    useEffect(() => {
        if (isSuccessGetOverallData === true && Array.isArray(dataGetOverallData)) {
            setOverallData(dataGetOverallData);
        }
    }, [dataGetOverallData, isSuccessGetOverallData]);

    useEffect(() => {
        if (isSuccessGetPerClusterData === true && Array.isArray(dataGetPerClusterData)) {
            setOverallData(dataGetPerClusterData);
        }
    }, [dataGetPerClusterData, isSuccessGetPerClusterData]);

    useEffect(() => {
        if (isSuccessGetProjectLocations === true && Array.isArray(dataGetProjectLocations)) {
            setClusters(dataGetProjectLocations);
        }
    }, [dataGetProjectLocations, isSuccessGetProjectLocations]);

    function clearFiltersAndRefreshTable() {
        setSelectedClusterId(undefined);
        setDate('');
        setStartDate('');
        setOverallTableRefreshToken((v: any) => v + 1);
        setTimeout(function() {
            $('.ant-picker-input > input').val('');
        }, 1000);
    }

    return <Row className='statistics' >
        <Col span={24} style={{ width: '100%' }}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={7}>
                            <Title level={4} >
                                Project Availability
                            </Title>
                        </Col>
                        <Col span={5}>
                            <DatePicker placeholder='Select Start Date' onChange={startDatepickerOnChange} style={{ 'width': '100%'}}/>
                        </Col>
                        <Col span={5}>
                            <DatePicker placeholder='Select As At Date' onChange={datepickerOnChange} style={{ 'minWidth': '100%', 'marginLeft': '10px'}}/>
                        </Col>
                        <Col span={5}>
                            <Select
                                placeholder={'Filter by Cluster'}
                                defaultActiveFirstOption={false}
                                value={selectedClusterId}
                                onChange={(value: any) => setSelectedClusterId(value)}
                                loading={loadingGetProjectLocations}
                                style={{ 'minWidth': '100%', 'marginLeft': '20px' }}
                            >
                                {Array.isArray(clusters) && clusters.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                            </Select>
                        </Col>
                        <Col span={2}>
                            <Tooltip title="Refresh">
                                <Button
                                    onClick={() => clearFiltersAndRefreshTable()}
                                    type="primary" shape="circle" icon={<RedoOutlined />} style={{ 'marginLeft': '30px' }} />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Spin spinning={loadingGetOverallData}>
                        <Table bordered dataSource={overallData} size="small" style={{ padding: '5px' }} pagination={false}>
                            <Column title="Status" dataIndex="status" key="status" />
                            <ColumnGroup title="Household">
                                <Column title="#" dataIndex="householdCount" key="householdCount" align='center'/>
                                <Column title="%" dataIndex="householdPercentage" key="householdPercentage" align='center'/>
                            </ColumnGroup>
                            <ColumnGroup title="Participant">
                                <Column title="#" dataIndex="participantCount" key="participantCount" align='center'/>
                                <Column title="%" dataIndex="participantPercentage" key="participantPercentage" align='center'/>
                            </ColumnGroup>
                        </Table>
                    </Spin>
                </Col>
            </Row>
        </Col>
    </Row>
}

Layout.propTypes = {

};

type LayoutProps = {
    surveyId: string | undefined
}