import { MenuOutlined } from "@ant-design/icons";
import { TableProps } from "antd";
import { useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";
import { sortBy, isNumber } from 'lodash';

import Table from "./index";


const Layout = ({ source, columns, onFinish, ...rest }: LayoutProps) => {

    const [dataSource, setDataSource] = useState(source);
    const [draggableColumns, setDraggableColumns] = useState<any>([]);

    const [selectedItems, setSelectedItems] = useState<any>([]);

    useEffect(() => {
        if(Array.isArray(source)){
            let mapped = source.map((i : any, index : number) => {
                return {
                    ...i,
                    index : isNumber(i?.index)? i?.index : index
                }
            });
            let ordered = sortBy(mapped, 'index');
            setDataSource(ordered);
        }
    }, [source]);

    useEffect(() => {
        if (Array.isArray(columns)) {
            let items = [
                ...columns,
                {
                    dataIndex: "",
                    overridenWidth: 5,
                    className: "drag-visible",
                    render: (d: any, dd: any, i: number) => (<TableDragHandle active={selectedItems.includes(i)} />)
                }
            ];
            setDraggableColumns(items);
        }
    }, [columns]);

    const TableDragHandle : any = SortableHandle(({ active }: any) => (
        <MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
    ));

    const TableSortableItem: any  = SortableElement((props: any) => <tr {...props} />);
    const TableSortableContainer: any  = SortableContainer((props: any) => <tbody {...props} />);

    const merge = (a: any, b: any, i = 0) => {
        let aa = [...a];
        return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
    };

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        let tempDataSource = dataSource;

        if (oldIndex !== newIndex) {
            if (!selectedItems.length) {
                let movingItem = tempDataSource[oldIndex];
                tempDataSource.splice(oldIndex, 1);
                tempDataSource = merge(tempDataSource, [movingItem], newIndex);
            } 
            else {
                let filteredItems = [] as any[];
                selectedItems.forEach((d: any) => {
                    filteredItems.push(tempDataSource[d]);
                });
                let newData = [] as any;
                tempDataSource.forEach((d, i) => {
                    if (!selectedItems.includes(i)) {
                        newData.push(d);
                    }
                });
                tempDataSource = [...newData];
                tempDataSource = merge(tempDataSource, filteredItems, newIndex);
            }
            setDataSource(tempDataSource);
            setSelectedItems([]);

            let remapped = tempDataSource.map((item : any, index : number) => {
                return {
                    ...item,
                    index
                }
            })
            onFinish(remapped);
        }
    };

    const DraggableContainer = (props: any) => (
        <TableSortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource
            .findIndex((x) => x.index === restProps["data-row-key"]);

        return (
            <TableSortableItem
                index={index}
                {...restProps}
                selected={selectedItems.length}
                onClick={(e: any) => {
                    if (e.ctrlKey || e.metaKey) {
                        selectedItems.includes(index) ? selectedItems.splice(selectedItems.indexOf(index), 1) : selectedItems.push(index);
                        setSelectedItems(selectedItems);
                    } else {
                        setSelectedItems([]);
                    }
                }}
            />
        );
    };

    return (
        <Table
            columns={draggableColumns}
            source={dataSource}
            {...rest}
            rowKey="index"
            components={{
                body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow
                }
            }}
        />
    );

};

type LayoutProps = TableProps<any> & {
    columns: any[],
    source: any[],
    height?: number,
    maxWidthPerCell: number,
    onFinish : (items : any[]) => void
};

export default Layout;