import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Spin, FormItemProps, DatePicker, message, Modal, Tooltip, InputNumber } from 'antd';
import useApi from 'hooks/useApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { createProjectRequest, getProjectRequest, updateProjectRequest } from 'api/projects.api';
import moment from "moment";
import { EditOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const { useForm } = Form;
const { TextArea } = Input;

export default function Layout(props: LayoutProps) {
    const messageKey = 'project-command-key';

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [form] = useForm();
    const { projectId } = useParams();

    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [project, setProject] = useState<any>({});
    const [disclaimerMessage, setDisclaimerMessage] = useState<string>('');
    const [isDisclaimerMessageModalVisible, setIsDisclaimerMessageModalVisible] = useState<boolean>(false);

    const [{
        loading: loadingCommand,
        isSuccess: isCommand,
        data: dataCommand,
        error: errorCommand
    }, executeCommandApi] = useApi<any, any>();

    const [{
        loading: loadingGetProject,
        data: dataGetProject,
        isSuccess: isSuccessGetProject
    }, executeGetProjectApi] = useApi<any, any>();

    useEffect(() => {
        if (projectId) {
            dispatch(setSectionHeadTitle("Edit Project"));
            executeGetProjectApi(getProjectRequest(projectId));
            setIsCreation(false);
        }
        else {
            dispatch(setSectionHeadTitle("Create Project"));
            setIsCreation(true);
        }
    }, [projectId]);

    useEffect(() => {
        if (isSuccessGetProject && dataGetProject && dataGetProject.id) {
            let { users, disclaimerMessage, ...rest } = dataGetProject;
            let data = {
                ...rest,
                maxHouseholdsPerCluster : rest.maxHouseholdsPerCluster || 1000
            }
            data.startDate = moment(data.startDate);
            setProject(dataGetProject);
            setDisclaimerMessage(disclaimerMessage);

            form.setFieldsValue(data);
        }
    }, [isSuccessGetProject, dataGetProject]);

    useEffect(() => {
        if (isCommand === true && dataCommand.id) {
            if (isCreation === true) {
                navigate(`/app/projects-management/project/edit/${dataCommand.id}`, { replace: true });
            }
            else {
                navigate('/app/projects-management');
            }
            message.success({ content: `${(isCreation ? 'Created successfully' : 'Updated successfully')}`, key: messageKey });
        }
        else if (isCommand === false) {
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [isCommand, dataCommand]);

    function onFinish(values: any) {
        message.loading({ content: (isCreation ? 'Creating...' : 'Updating...'), key: messageKey });
        if (isCreation) {
            let data = {
                ...values
            };
            executeCommandApi(createProjectRequest(data));
        }
        else {
            let data = {
                ...project,
                ...values,
                disclaimerMessage
            };
            executeCommandApi(updateProjectRequest(data));
        }
    }

    function onHandleLocationManagements() {
        project?.id && navigate(`/app/projects-management/project/edit/${project?.id}/locations`);
    }

    function onHandleUserManagements() {
        project?.id && navigate(`/app/projects-management/project/edit/${project?.id}/users`);
    }

    function onHandleRolesManagements() {
        project?.id && navigate(`/app/projects-management/project/edit/${project?.id}/roles`);
    }

    function saveDisclaimerMessage() {

    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    return (
        <Spin spinning={loadingGetProject || loadingCommand}>
            <Row className={'create-user-form'}>
                <Col span={22} offset={1}>
                    <Form
                        name="basic"
                        form={form}
                        initialValues={{
                            startDate: moment(),
                            maxHouseholdsPerCluster: 1000
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Row>
                            <Col span={8} >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        { required: true, message: 'Please input project name!' },
                                        { max: 100 },
                                    ]}
                                    {...formItemProps}
                                >
                                    <Input placeholder='project name' />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label="Project Id"
                                    name="projectId"
                                    {...formItemProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input project id!'
                                        },
                                        {
                                            required: true,
                                            pattern: new RegExp(/^[a-zA-Z0-9_]*$/i),
                                            message: "project id should be alphanumeric only!"
                                        },
                                        { max: 100 }
                                    ]}
                                >
                                    <Input placeholder='project id' />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label="Default Password"
                                    name="defaultPassword"
                                    {...formItemProps}
                                    {...{ wrapperCol: { span: 21 } }}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input default password!'
                                        },
                                        { max: 100 }
                                    ]}
                                >
                                    <Input.Password
                                        type={'password'}
                                        placeholder='default password'
                                        iconRender={(visible: Boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24} >
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                        { max: 1000 }
                                    ]}
                                    {...formItemProps}
                                    {...{ wrapperCol: { span: 23 } }}
                                >
                                    <TextArea placeholder='description' />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={8} >
                                <Form.Item
                                    label="Start Date"
                                    name="startDate"
                                    {...formItemProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input project start date!'
                                        }
                                    ]}
                                >
                                    <DatePicker
                                        placeholder='project start date'
                                        disabledDate={(current: any) => {
                                            var startdate = moment(new Date(), "YYYY-MM-DD");
                                            startdate = startdate.subtract(1, "days");
                                            return current && current <= startdate;
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label="Max Households per Cluster"
                                    name="maxHouseholdsPerCluster"
                                    {...formItemProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input max households per cluster!'
                                        }
                                    ]}
                                >
                                    <InputNumber min={0} max={1000} style={{ width: '100%' }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label="Max visits per household"
                                    name="maxVisitsPerHousehold"
                                    {...formItemProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input max visits per household!'
                                        }
                                    ]}
                                >
                                    <InputNumber min={0} max={1000} style={{ width: '100%' }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label="Max visits per module"
                                    name="maxVisitsPerModule"
                                    {...formItemProps}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input max visits per module!'
                                        }
                                    ]}
                                >
                                    <InputNumber min={0} max={1000} style={{ width: '100%' }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {(!isCreation) && <Row
                            style={{ marginTop: '20px' }}>
                            <Tooltip title="Edit data disclaimer message">
                                <EditOutlined />
                                <span
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                        color: '#0067bf',
                                        fontWeight: 'bold'
                                    }}
                                    onClick={() => setIsDisclaimerMessageModalVisible(true)}
                                >
                                    Edit Disclaimer message
                                </span>
                            </Tooltip>
                        </Row>}
                        {(!isCreation) && <Row>
                            <Col span={8} >
                                <Form.Item
                                    label={<><br /></>}
                                    name={'button_roles_management'}
                                    {...formItemProps}
                                    className='form-item-no-title'
                                >
                                    <Button
                                        style={{ width: '100%' }} type='primary'
                                        onClick={onHandleRolesManagements}
                                    >
                                        Role Management
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label={<><br /></>}
                                    name={'button_user_management'}
                                    {...formItemProps}
                                    className='form-item-no-title'
                                >
                                    <Button
                                        style={{ width: '100%' }} type='primary'
                                        onClick={onHandleUserManagements}
                                    >
                                        User Management
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    label={<><br /></>}
                                    name={'button_location_management'}
                                    {...formItemProps}
                                    {...{ wrapperCol: { span: 21 } }}
                                    className='form-item-no-title'
                                >
                                    <Button
                                        style={{ width: '100%' }} type='primary'
                                        onClick={onHandleLocationManagements}
                                    >
                                        Manage Locations
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>}

                        <Row>
                            <Col span={23} >
                                <Form.Item style={{ 'marginTop': '3%', float: 'right' }}>
                                    <Button type="primary" htmlType="submit">
                                        {isCreation ? 'Create' : 'Update'}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
            <Modal
                title="Edit data disclaimer message"
                visible={isDisclaimerMessageModalVisible}
                onOk={() => {
                    saveDisclaimerMessage();
                    setIsDisclaimerMessageModalVisible(false);
                }}
                onCancel={() => setIsDisclaimerMessageModalVisible(false)}
                okText="Save"
                width={1000}
            >
                <TextArea
                    rows={10}
                    placeholder="max length is 1000 characters"
                    maxLength={1000}
                    value={disclaimerMessage}
                    onChange={(event) => {
                        if (event?.target?.value) {
                            setDisclaimerMessage(event?.target?.value);
                        }
                    }}
                />
            </Modal>
        </Spin>
    )
}

Layout.propTypes = {

};

type LayoutProps = {

}