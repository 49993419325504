import React, { useEffect, useState } from 'react';
import { useApi } from 'hooks';
import { Button, Col, DatePicker, Row, Select, Spin, Table, Tooltip, Typography, Modal } from 'antd';
import type { DatePickerProps } from 'antd';

import { getHouseholdRosterRequest, getParticipantsForDashboardFilterRequest } from 'api/statistics.api';

import './index.scss';
import { useSelector } from 'react-redux';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';
import { getLocationsRequest } from 'api/locations.api';
import { BarsOutlined, CheckCircleFilled, ClearOutlined, CloseCircleFilled, RedoOutlined } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;
const { Title } = Typography;
const { Option } = Select;

export default function Layout() {

    const currentUserProjectId = useSelector(getUserDefaultProjectId);

    const [overallData, setOverallData] = useState<any[]>([]);
    const [perClusterData, setPerClusterData] = useState<any[]>([]); 
    const [selectedClusterId, setSelectedClusterId] = useState<number>();
    const [selectedParticipantId, setSelectedParticipantId] = useState<number>();
    const [clusters, setClusters] = useState<any[]>([]);
    const [participants, setParticipants] = useState<any[]>([]);

    const [overallTableRefreshToken, setOverallTableRefreshToken] = useState<number>(0);
    const [perClusterTableRefreshToken, setPerClusterTableRefreshToken] = useState<number>(0);

    const [{
        loading: loadingGetOverallData,
        data: dataGetOverallData,
        isSuccess: isSuccessGetOverallData
    }, executeGetOverallDataApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetPerClusterData,
        data: dataGetPerClusterData,
        isSuccess: isSuccessGetPerClusterData
    }, executeGetPerClusterDataApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetProjectLocations,
        data: dataGetProjectLocations,
        isSuccess: isSuccessGetProjectLocations
    }, executeGetProjectLocationsApi] = useApi<any, any>();

    const [{
        loading: loadingGetParticipants,
        data: dataGetParticipants,
        isSuccess: isSuccessGetParticipants
    }, executeGetParticipantsApi] = useApi<any, any>();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
        if (currentUserProjectId) {
            let params = { 'type': 'cluster' };
            executeGetProjectLocationsApi(getLocationsRequest(currentUserProjectId, params));
        }
    }, [currentUserProjectId])

    useEffect(() => {
        if (currentUserProjectId) {
            executeGetParticipantsApi(getParticipantsForDashboardFilterRequest(currentUserProjectId));
        }
    }, [currentUserProjectId])

    useEffect(() => {
        if (currentUserProjectId) {
            executeGetOverallDataApi(getHouseholdRosterRequest(currentUserProjectId));
        }
    }, [currentUserProjectId, overallTableRefreshToken])

    useEffect(() => {
        if (currentUserProjectId) {
            let params = { 'cluster-id': selectedClusterId, 'participant-id': selectedParticipantId };
            executeGetPerClusterDataApi(getHouseholdRosterRequest(currentUserProjectId, params));
        }
    }, [currentUserProjectId, selectedClusterId, selectedParticipantId, perClusterTableRefreshToken])

    useEffect(() => {
        if (isSuccessGetOverallData === true && Array.isArray(dataGetOverallData)) {
            setOverallData(dataGetOverallData);
        }
    }, [dataGetOverallData, isSuccessGetOverallData]);

    useEffect(() => {
        if (isSuccessGetPerClusterData === true && Array.isArray(dataGetPerClusterData)) {
            setOverallData(dataGetPerClusterData);
        }
    }, [dataGetPerClusterData, isSuccessGetPerClusterData]);

    useEffect(() => {
        if (isSuccessGetProjectLocations === true && Array.isArray(dataGetProjectLocations)) {
            setClusters(dataGetProjectLocations);
        }
    }, [dataGetProjectLocations, isSuccessGetProjectLocations]);

    useEffect(() => {
        if (isSuccessGetParticipants === true && Array.isArray(dataGetParticipants)) {
            setParticipants(dataGetParticipants);
        }
    }, [dataGetParticipants, isSuccessGetParticipants]);

    function getParticipantStatusIcon(status:string) {
        if (status === 'Available') {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'green' }} />;
        }
        else {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'black' }} />;
        }
    }

    function getModuleStatusIcon(status:string) {
        if (status === 'Not Applicable') {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'black' }} />;
        } 
        else if (status === 'Completed') {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'green' }} />;
        }
        else if (status === 'Pending (Eligible)') {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'orange' }} />;
        }
        else if (status === 'Partially Incomplete') {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'blue' }} />;
        }
        else if (status === 'Partially Incomplete, Refused') {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'red' }} />;
        }
        else {
            return <div style={{ margin:'0 auto', width: 16, height: 16, borderRadius: 8, backgroundColor: 'blue' }} />;
        }
    }

    function getServiceApIcon(status:any) {
        if (status == 1) {
            return <CheckCircleFilled style={{ color: 'green', fontSize: '16px' }} />;
        }
        else if (status == 2) {
            return <CloseCircleFilled style={{ color: 'red', fontSize: '16px' }} />;
        }
        else if (status == 0) {
            return <CloseCircleFilled style={{ color: 'black', fontSize: '16px' }} />;
        }
        else if (status == "novalue") {
            return "";
        }
    }

    function clearFiltersAndRefreshTable() {
        setSelectedClusterId(undefined);
        setSelectedParticipantId(undefined);
        setOverallTableRefreshToken((v: any) => v + 1);
    }

    const filterOption = (inputValue:any, option:any) => {
        return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
      };

    return <Row className='statistics' >
        <Col span={24} style={{ width: '100%' }}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <Title level={4} >
                                Household Roster
                            </Title>
                        </Col>
                        <Col span={5}>
                            <Select
                                placeholder={'Filter by Cluster'}
                                defaultActiveFirstOption={false}
                                value={selectedClusterId}
                                onChange={(value: any) => setSelectedClusterId(value)}
                                loading={loadingGetProjectLocations}
                                style={{ 'minWidth': '100%' }}
                            >
                                {Array.isArray(clusters) && clusters.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                            </Select>
                        </Col>
                        <Col span={5}>
                            <Select
                                showSearch 
                                placeholder={'Filter by Participant'}
                                defaultActiveFirstOption={false}
                                value={selectedParticipantId}
                                onChange={(value: any) => setSelectedParticipantId(value)}
                                loading={loadingGetParticipants}
                                filterOption={filterOption}
                                style={{ 'minWidth': '100%', 'marginLeft': '10px' }}
                            >
                                {Array.isArray(participants) && participants.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}

                            </Select>
                        </Col>
                        <Col span={2}>
                            <Tooltip title="legend">
                                <Button
                                    onClick={showModal}
                                    type="primary" shape="circle" icon={<BarsOutlined />} style={{ 'marginLeft': '20px' }} />
                            </Tooltip>
                            <Tooltip title="refresh">
                                <Button
                                    onClick={() => clearFiltersAndRefreshTable()}
                                    type="primary" shape="circle" icon={<RedoOutlined />} style={{ 'marginLeft': '10px' }} />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Spin spinning={loadingGetOverallData}>
                        <Table style={{ whiteSpace: 'nowrap', width: 'auto'}} bordered pagination={{ defaultPageSize: 100 }} scroll={{ x: 'auto' }} dataSource={overallData} size="small">
                            <ColumnGroup title="Participant">
                                <Column fixed="left" dataIndex="humanly_intuitive_id" key="humanly_intuitive_id" title="Id"/>
                            </ColumnGroup>
                            <Column dataIndex="last_name" key="last_name" title="Name"/>
                            <Column dataIndex="sex" key="sex" title="Sex"/>
                            <Column dataIndex="age" key="age" title="Age"/>
                            <Column dataIndex="contacts" key="contacts" title="Contact"/>
                            <Column align='center' render={getParticipantStatusIcon} dataIndex="status" key="status" title="Availability"/>
                            <ColumnGroup align='center' title="General Q">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="general_status" key="general_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Mini Vision">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="mini_vision_status" key="mini_vision_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Vision">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="vision_status" key="vision_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Hearing">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="hearing_status" key="hearing_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Mobility">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="mobility_status" key="mobility_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Comm.">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="communication_status" key="communication_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Cognition">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="cognition_status" key="cognition_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Self-care">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="self_care_status" key="self_care_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Mental H">
                                <Column align='center' render={getModuleStatusIcon} dataIndex="mental_health_status" key="mental_health_status" title="Status"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Vision">
                                <Column align='center' render={getServiceApIcon} dataIndex="vision_services" key="vision_services" title="Serv."/>
                                <Column align='center' render={getServiceApIcon} dataIndex="vision_ap" key="vision_ap" title="AP"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Hearing">
                                <Column align='center' render={getServiceApIcon} dataIndex="hearing_services" key="hearing_services" title="Serv."/>
                                <Column align='center' render={getServiceApIcon} dataIndex="hearing_ap" key="hearing_ap" title="AP"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Mobility">
                                <Column align='center' render={getServiceApIcon} dataIndex="mobility_services" key="mobility_services" title="Serv."/>
                                <Column align='center' render={getServiceApIcon} dataIndex="mobility_ap" key="mobility_ap" title="AP"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Comm.">
                                <Column align='center' render={getServiceApIcon} dataIndex="communication_services" key="communication_services" title="Serv."/>
                                <Column align='center' render={getServiceApIcon} dataIndex="communication_ap" key="communication_ap" title="AP"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Cognition">
                                <Column align='center' render={getServiceApIcon} dataIndex="cognition_services" key="cognition_services" title="Serv."/>
                                <Column align='center' render={getServiceApIcon} dataIndex="cognition_ap" key="cognition_ap" title="AP"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Self-care">
                                <Column align='center' render={getServiceApIcon} dataIndex="self_care_services" key="self_care_services" title="Serv."/>
                                <Column align='center' render={getServiceApIcon} dataIndex="self_care_ap" key="self_care_ap" title="AP"/>
                            </ColumnGroup>
                            <ColumnGroup align='center' title="Mental H">
                                <Column align='center' render={getServiceApIcon} dataIndex="mental_health_services" key="mental_health_services" title="Serv."/>
                                <Column align='center' render={getServiceApIcon} dataIndex="mental_health_ap" key="mental_health_ap" title="AP"/>
                            </ColumnGroup>
                        </Table>
                    </Spin>
                </Col>
            </Row>
        </Col>

        <Modal footer={null} title="Legend" visible={isModalOpen} onCancel={handleOk}>
            <Row>
                <p style={{ fontWeight: 500 }}>Module Status</p>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2, width: 14, height: 14, borderRadius: 8, backgroundColor: 'black' }} />
                </Col>
                <Col span={20}>
                    <div>Not Applicable</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2,  width: 14, height: 14, borderRadius: 8, backgroundColor: 'green' }} />
                </Col>
                <Col span={20}>
                    <div>Completed</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2,  width: 14, height: 14, borderRadius: 8, backgroundColor: 'orange' }} />
                </Col>
                <Col span={20}>
                    <div>Pending (Eligible)</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2,  width: 14, height: 14, borderRadius: 8, backgroundColor: 'blue' }} />
                </Col>
                <Col span={20}>
                    <div>Partially Incomplete</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2,  width: 14, height: 14, borderRadius: 8, backgroundColor: 'blue' }} />
                </Col>
                <Col span={20}>
                    <div>Partially Incomplete, Revisit</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2,  width: 14, height: 14, borderRadius: 8, backgroundColor: 'red' }} />
                </Col>
                <Col span={20}>
                    <div>Partially Incomplete, Refused</div>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <p style={{ fontWeight: 500 }}>Participant Status</p>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2, width: 14, height: 14, borderRadius: 8, backgroundColor: 'green' }} />
                </Col>
                <Col span={20}>
                    <div>Available</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{ margin:'0 auto', marginTop: 2, width: 14, height: 14, borderRadius: 8, backgroundColor: 'black' }} />
                </Col>
                <Col span={20}>
                    <div>Unavailable</div>
                </Col>
            </Row> 
            <Row style={{marginTop: 20}}>
                <p style={{ fontWeight: 500 }}>Service/AP Status</p>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{textAlign: 'center', marginTop: 2}}><CheckCircleFilled style={{ color: 'green', fontSize: '16px' }} /></div>
                </Col>
                <Col span={20}>
                    <div>True</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{textAlign: 'center', marginTop: 2}}><CloseCircleFilled style={{ color: 'black', fontSize: '16px' }} /></div>
                </Col>
                <Col span={20}>
                    <div>False</div>
                </Col>
            </Row>
            <Row>
                <Col span={2}>
                    <div style={{textAlign: 'center', marginTop: 2}}><CloseCircleFilled style={{ color: 'red', fontSize: '16px' }} /></div>
                </Col>
                <Col span={20}>
                    <div>Refused</div>
                </Col>
            </Row>
        </Modal>
    </Row>
}

Layout.propTypes = {

};

type LayoutProps = {
    surveyId: string | undefined
}