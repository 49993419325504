import controlAutocomplete from './autocomplete'
import controlCustom from './custom'
import controlParagraph from './paragraph'
import controlSelect from './select'
import controlText from './text'
import controlTextarea from './textarea'
import controlCarousel from './carousel'
import controlMappings from './mappings'
import controlAggregate from './aggregate'

export default {
  controlAutocomplete,
  controlCustom,
  controlParagraph,
  controlSelect,
  controlText,
  controlTextarea,
  controlCarousel,
  controlMappings,
  controlAggregate
}
