import React from "react";
import PropTypes from 'prop-types';
import { Table, TableProps } from "antd";
import { calculateColumnsWidth } from "providers/DynamicColumnsProvider";


type LayoutProps = TableProps<any> & {
    columns : any[], 
    rowKey : String, 
    source : any[], 
    height? : number, 
    maxWidthPerCell : number,
};

const Layout = (props: LayoutProps) => {
    const { columns, rowKey, source, height, maxWidthPerCell, ...rest } = props;

    const { 
        columns : formattedColumns,
        source : formattedSource,
        tableWidth : width,
    } = calculateColumnsWidth(columns, source, maxWidthPerCell) as any;

    return (
        <Table
            columns={formattedColumns}
            dataSource={formattedSource}
            rowKey={rowKey}
            scroll={{ x: width, y: height }}
            {...rest}
        />
    );
};

Layout.propTypes = {
    columns : PropTypes.array.isRequired,
    rowKey : PropTypes.string.isRequired,
    source : PropTypes.array.isRequired,
    height : PropTypes.number.isRequired,
    maxWidthPerCell : PropTypes.number.isRequired,
};

export default Layout;


