import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Input, Spin, Form, FormItemProps, Button, message, Typography, Tooltip, Popconfirm } from 'antd';

import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi, useTranslate } from 'hooks';
import { cloneSurveyModuleSectionRequest, createSurveyModuleRequest, deleteSurveyModuleSectionRequest, getSurveyModuleRequest, getSurveyModuleSectionsRequest, updateSurveyModuleRequest, updateSurveyModuleSectionsReOrderRequest } from 'api/surveys.api';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import SortableTable from 'components/table/sortable-table';
import { WithPermissions } from 'utils/with-permissions';
import RoleModuleAccessGrantModel, { RoleModuleAccessGrantModelHandle } from './survey-module-roles.model';
import { SketchPicker } from 'react-color';

import './edit-survey-module.scss';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';

const { useForm } = Form;
const { TextArea } = Input;
const { Title } = Typography;

export default function Layout(props: LayoutProps) {
    const messageKey = 'survey-module-command-key';

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const currentUserProjectId = useSelector(getUserDefaultProjectId) as string;

    const { surveyId, moduleId } = useParams();
    const [module, setModule] = useState<any>({});

    const [form] = useForm();

    const [sections, setSections] = useState<any[]>([]);
    const [isCreation, setIsCreation] = useState<boolean>(true);

    const [confirmDeleteSectionId, setConfirmDeleteSectionId] = useState<number>(-1);
    const [confirmCloneSurveyModuleSectionId, setConfirmCloneSurveyModuleSectionId] = useState<number>(-1);

    const roleModuleAccessGrantModelModelRef = useRef<RoleModuleAccessGrantModelHandle>(null);

    const [{
        error: errorCommand,
        loading: loadingCommand,
        data: dataCommand,
        isSuccess: isSuccessCommand
    }, executeCommandApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetModule,
        data: dataGetModule,
        isSuccess: isSuccessGetModule
    }, executeGetModuleApi] = useApi<any, any>();

    const [{
        loading: loadingGetSections,
        data: dataGetSections,
        isSuccess: isSuccessGetSections
    }, executeGetSectionsApi] = useApi<any, any>();

    const [{
        loading: loadingUpdateSectionsOrdered,
        isSuccess: isSuccessUpdateSectionsOrdered
    }, executeUpdateModuleSectionsOrderedApi] = useApi<any, any>();

    const [{
        loading: loadingDeleteSection,
        isSuccess: isSuccessDeleteSection
    }, executeDeleteSectionApi] = useApi<any, any>();
    
    const [{
        loading: loadingCloneSurveyModuleSection,
        isSuccess: isSuccessCloneSurveyModuleSection
    }, executeCloneSurveyModuleSectionApi] = useApi<any, any>();

    useEffect(() => {
        if (moduleId) {
            setIsCreation(false);
        }
    }, [moduleId]);

    useEffect(() => {
        if (currentUserProjectId && surveyId && moduleId) {
            executeGetModuleApi(getSurveyModuleRequest(currentUserProjectId, surveyId, moduleId));
            fetchSections();
        }
    }, [currentUserProjectId, surveyId, moduleId]);

    useEffect(() => {
        if (isCreation) {
            dispatch(setSectionHeadTitle(translate('survey-management.create.survey.module.header', "Create Survey Module")));
        }
        else {
            dispatch(setSectionHeadTitle(translate('survey-management.edit.survey.module.header', "Edit Survey Module")));
        }
    }, [isCreation]);

    useEffect(() => {
        if (isSuccessGetSections && Array.isArray(dataGetSections)) {
            setSections(dataGetSections);
        }
    }, [isSuccessGetSections, dataGetSections]);
    
    useEffect(() => {
        if (isSuccessUpdateSectionsOrdered === true) {
            fetchSections();
        }
    }, [isSuccessUpdateSectionsOrdered]);

    useEffect(() => {
        if (isSuccessGetModule && dataGetModule && dataGetModule.id) {
            setModule(dataGetModule);

            form.setFieldsValue(dataGetModule);
        }
    }, [isSuccessGetModule, dataGetModule]);

    useEffect(() => {
        if (isSuccessCommand === true && dataCommand.id) {
            if (isCreation === true) {
                let { id } = dataCommand;
                navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${id}`, { replace: true });
                message.success({ content: (isCreation ? 'Created successfully' : 'Updated successfully'), key: messageKey });
            }
            else {
                navigate(`/app/surveys-management/survey/edit/${surveyId}`, { replace: true });
            }
            message.success({ content: `${(isCreation ? 'Created successfully' : 'Updated successfully')}`, key: messageKey });
        }
        else if (isSuccessCommand === false) {
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [dataCommand, isSuccessCommand]);

    useEffect(() => {
        if (isSuccessDeleteSection === true) {
            setConfirmDeleteSectionId(-1);
            fetchSections();
        }
    }, [isSuccessDeleteSection]);

    useEffect(() => {
        if (isSuccessCloneSurveyModuleSection === true) {
            setConfirmCloneSurveyModuleSectionId(-1);
            fetchSections();
        }
    }, [isSuccessCloneSurveyModuleSection]);

    function fetchSections() {
        currentUserProjectId && surveyId && moduleId && executeGetSectionsApi(getSurveyModuleSectionsRequest(currentUserProjectId, surveyId, moduleId));
    }

    function onFinish(value: any) {
        message.loading({ content: (isCreation ? 'Creating...' : 'Updating...'), key: messageKey });
        if (currentUserProjectId) {
            if (isCreation) {
                executeCommandApi(createSurveyModuleRequest(currentUserProjectId, surveyId as string, value));
            }
            else {
                let data = {
                    ...module,
                    ...value
                }
                executeCommandApi(updateSurveyModuleRequest(currentUserProjectId, surveyId as string, data));
            }
        }
    }

    function hadleAddSection(value: any) {
        if (surveyId && moduleId) {
            navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/create`);
        }
    }

    function hadleEditSection(id: string) {
        navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${id}`);
    }

    function hadleDeleteSection(id: string) {
        currentUserProjectId && surveyId && moduleId && executeDeleteSectionApi(deleteSurveyModuleSectionRequest(currentUserProjectId, surveyId, moduleId, id));
    }

    function handleOnDisplayLogic() {
        navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/display-logic`);
    }

    function onReOrdered(items : any[]){
        if(currentUserProjectId && surveyId && moduleId){
            let payload = {
                items
            };
            let data = updateSurveyModuleSectionsReOrderRequest(currentUserProjectId, surveyId, moduleId, payload);
            executeUpdateModuleSectionsOrderedApi(data);
        }
    }

    function handleClone(id: string) {
        currentUserProjectId && surveyId && moduleId && id && executeCloneSurveyModuleSectionApi(cloneSurveyModuleSectionRequest(currentUserProjectId, surveyId, moduleId, id));
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 24 }, lg: { span: 24 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    const columns = [
        {
            title: "Name",
            key: 'name',
            dataIndex: "name",
            overridenWidth: 50,
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            overridenWidth: 30,
            render: (text: any, record: any) => (
                <div>
                    <WithPermissions permissions={['permission-project:survey:edit']} >
                        <Tooltip title="Edit section" placement="bottom" className='action' >
                            <Button
                                onClick={() => hadleEditSection(record.id)}
                                icon={<EditOutlined />}
                                style={{ 'marginRight': '12px', }}
                            >
                            </Button>
                        </Tooltip>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:clone']} >
                        <Popconfirm
                            title="Clone section?"
                            visible={confirmCloneSurveyModuleSectionId === record.id}
                            onConfirm={() => handleClone(record.id)}
                            okButtonProps={{ loading: loadingCloneSurveyModuleSection }}
                            onCancel={() => setConfirmCloneSurveyModuleSectionId(-1)}
                        >
                            <Tooltip title="Clone section" placement="bottom">
                                <Button
                                    onClick={() => setConfirmCloneSurveyModuleSectionId(record.id)}
                                    icon={<CopyOutlined />}
                                    style={{ 'marginRight': '3%', }}>

                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:edit']} >
                        <Popconfirm
                            title="Delete section?"
                            visible={confirmDeleteSectionId === record.id}
                            onConfirm={() => hadleDeleteSection(record.id)}
                            okButtonProps={{ loading: loadingDeleteSection }}
                            onCancel={() => setConfirmDeleteSectionId(-1)}
                        >
                            <Tooltip title="Delete section" placement="bottom">
                                <Button
                                    onClick={() => setConfirmDeleteSectionId(record.id)}
                                    icon={<DeleteOutlined />}>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                </ div>
            ),
        },
    ];

    const ColorPickerInput: React.FC<any> = ({ value, onChange }) => {
        const [selectedColor, setSelectedColor] = useState<string>(value || 'white');
        const [isPickerVisible, setIsPickerVisible] = useState<boolean>(false);
        return (<div className='picker' >
            <div className='picked-color' >
                <span className='picked-color-hex'>{selectedColor}</span>
                <div className='picked-color-wrap'>
                    <span style={{ backgroundColor: selectedColor }} ></span>
                </div>
                <div className='picked-color-pick-button'>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setIsPickerVisible(i => !i);
                        }}
                    >
                    </Button>
                </div>
            </div>
            {isPickerVisible && <>
                <div className='overlay' onClick={() => {
                    setIsPickerVisible(i => !i);
                }} />
                <SketchPicker
                    color={selectedColor}
                    onChangeComplete={(color: any) => {
                        let value = color?.hex;
                        if (value) {
                            setSelectedColor(value);
                            onChange(value);
                        }
                    }}
                />
            </>}
        </ div>);
    };

    return (
        <div className='edit-survey-module'>
            <Spin spinning={loadingCommand || loadingGetModule}>
                <Row>
                    <Col span={22}>
                        <div style={{ display: 'inline-block', 'float': 'right' }} >
                            <Button disabled={isCreation}
                                onClick={() => {
                                    roleModuleAccessGrantModelModelRef.current?.open()
                                }}
                            >
                                Roles
                            </Button>
                            <Button
                                onClick={handleOnDisplayLogic}
                                style={{ marginLeft: '10px' }}
                                disabled={isCreation}
                            >
                                Display Logic
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className={'edit-survey-module-form'} style={{ 'marginTop': '10px' }} >
                    <Col span={20} offset={2}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                            initialValues={{
                                color: 'white'
                            }}
                        >

                            <Row>
                                <Col span={16} >
                                    <Form.Item
                                        label="Module Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={7} offset={1} >
                                    <Form.Item
                                        label="Module Color"
                                        name="color"
                                        {...formItemProps}
                                    >
                                        <ColorPickerInput />
                                    </Form.Item>
                                </Col>


                                <Col span={24} >
                                    <Form.Item
                                        label="Module Description"
                                        name="description"
                                        {...formItemProps}
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>

                                </Col>
                            </Row>

                            {(!isCreation) && <Row style={{ 'marginTop': '10px' }}>
                                <Col span={24}>
                                    <Row>
                                        <Col span={20} >
                                            <Title level={5} style={{ 'fontWeight': 500, marginBottom: '2%' }} >
                                                Sections incorporated with this modules
                                            </Title>
                                        </Col>
                                        {(!isCreation) && <Col span={4} >
                                            <Button onClick={hadleAddSection} style={{ 'float': 'right' }} >
                                                Add Section
                                            </Button>
                                        </Col>}
                                    </Row>
                                </Col>
                                <Col span={24} >
                                    <Spin spinning={loadingGetSections || loadingUpdateSectionsOrdered} >
                                        <SortableTable
                                            columns={columns}
                                            source={sections}
                                            style={{ width: 'auto', 'overflowY': 'hidden' }}
                                            size={'small'}
                                            rowKey="id"
                                            maxWidthPerCell={600}
                                            height={400}
                                            pagination={false}
                                            showHeader={false}
                                            className='locations-table'
                                            onFinish={onReOrdered}
                                        />
                                    </Spin>
                                </Col>
                            </Row>}

                            <Form.Item wrapperCol={{ offset: 20, span: 4 }} style={{ 'marginTop': '2%' }}>
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                    {isCreation ? 'Create' : 'Update'}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>

                </Row>
            </Spin>
            <RoleModuleAccessGrantModel
                ref={roleModuleAccessGrantModelModelRef}
                onClose={() => {

                }} />
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}
