import React, { ReactNode, useState, useEffect } from "react";
import { useSelector } from "react-redux";

export function WithPermissions({ permissions, children }: LayoutProps) {
    const { permissions : scopes } = useSelector((state : any) => state.auth);
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        let items = [] as string[];
        if(typeof permissions === 'string' || permissions instanceof String){
            items.push(permissions as string)
        }
        else if(Array.isArray(permissions)) {
            items = permissions
        }
        for (var index in items) {
            let property = items[index];
            if (scopes.hasOwnProperty(property)) {
                setHasPermission(true);
                break;
            }
        }
    }, [permissions]);

    if (hasPermission) {
        return <>{children}</>;
    }
    return <></>;
}
type LayoutProps = {
    permissions: string[] | string;
    children: ReactNode;
};
