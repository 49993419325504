import { useSelector } from 'react-redux';

export function useWithPermissions(
    items: any[]
) {
    const { permissions : userPermissions } = useSelector((state : any) => state.auth);
    
    return [
        items.filter((i : any) => {
            let { permission, permissions } = i;
            let candidates = [] as string[];
            if(!userPermissions){
                return true;
            }
            
            if(typeof permission === 'string' || permission instanceof String){
                candidates.push(permission as string)
            }
            else if(Array.isArray(permissions)) {
                candidates = [...candidates, ...permissions]
            }
            else{
                return true;
            }
            return candidates.some((v : string) => userPermissions.hasOwnProperty(v))
        }) 
    ] as any;
};

export default useWithPermissions;