import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Spin, Button, Input, Tooltip, Popconfirm } from 'antd';
import debounce from 'lodash.debounce';

import { useAppDispatch } from 'store';
import Table from 'components/table';

import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi, usePermissions } from 'hooks';
import { deleteProjectRequest, getProjectsRequest } from 'api/projects.api';
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import EditProjectModel, { EditProjectModelHandle } from './edit-project.model';
import { WithPermissions } from 'utils/with-permissions';
import { useNavigate } from 'react-router-dom';

import './index.css';

const { Search } = Input;

export default function Layout(props: LayoutProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [hasProjectCreatePermission] = usePermissions(['permission-global:project:create']);

    const [projects, setProjects] = useState<any[]>([]);

    const [confirmDeleteProjectId, setConfirmDeleteProjectId] = useState<number>(-1);

    const editProjectModelRef = useRef<EditProjectModelHandle>(null);

    const debouncedSearch = useCallback(
        debounce(nextValue => {
            if (nextValue && nextValue.length > 2) {
                let params = { 'filter-key': nextValue }
                executeGetProjectsApi(getProjectsRequest(params));
            }
            else {
                executeGetProjectsApi(getProjectsRequest());
            }
        }, 500),
        [],
    );
    
    const [{
        loading: loadingGetProjects,
        data: dataGetProjects,
        isSuccess: isSuccessGetProjects
    }, executeGetProjectsApi] = useApi<any, any>([]);

    const [{
        loading: loadingDeleteProject,
        isSuccess: isSuccessDeleteProject
    }, executeDeleteProjectApi] = useApi<any, any>();

    useEffect(() => {
        dispatch(setSectionHeadTitle("Projects Management"));
        executeGetProjectsApi(getProjectsRequest());
    }, []);

    useEffect(() => {
        if (isSuccessGetProjects && Array.isArray(dataGetProjects)) {
            setProjects(dataGetProjects)
        }
    }, [isSuccessGetProjects, dataGetProjects]);

    useEffect(() => {
        if (isSuccessDeleteProject === true) {
            setConfirmDeleteProjectId(-1);
            executeGetProjectsApi(getProjectsRequest());
        }
    }, [isSuccessDeleteProject]);

    function onProjectDeleteHandle(id: string) {
        id && executeDeleteProjectApi(deleteProjectRequest(id))
    }

    function onHandleCreate(){
        navigate('/app/projects-management/project/create');
    }

    function onHandleEdit(id : string){
        id && navigate(`/app/projects-management/project/edit/${id}`);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            overridenWidth: 200,
        },
        {
            title: 'Project Id',
            dataIndex: 'projectId',
            key: 'projectId',
            overridenWidth: 100,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            overridenWidth: 100,
        },
        {
            title: 'No of Clusters',
            dataIndex: 'clustersCount',
            key: 'clustersCount',
            overridenWidth: 100,
        },
        {
            title: 'No of Users',
            dataIndex: 'usersCount',
            key: 'usersCount',
            overridenWidth: 100,
        },
        {
            title: 'Default Password',
            dataIndex: 'defaultPassword',
            key: 'defaultPassword',
            overridenWidth: 200,
            render: (text: any, record: any) => {
                return (<Input.Password value={text} readOnly={true} className={'table-default-password'} />);
            } 
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            render: (text: any, record: any) => (
                <div>
                    <WithPermissions permissions={['permission-global:project:edit']}>
                        <Tooltip title="Edit project" placement="bottom" className='action' >
                            <Button
                                onClick={() => onHandleEdit(record.id)}
                                icon={<EditOutlined />}
                                style={{ 'marginRight': '3%', }}
                            >
                            </Button>
                        </Tooltip>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-global:project:delete']}>
                        <Popconfirm
                            title="Delete project?"
                            visible={confirmDeleteProjectId === record.id}
                            onConfirm={() => onProjectDeleteHandle(record.id)}
                            okButtonProps={{ loading: loadingDeleteProject }}
                            onCancel={() => setConfirmDeleteProjectId(-1)}
                        >
                            <Tooltip title="Delete project" placement="bottom">
                                <Button
                                    onClick={() => setConfirmDeleteProjectId(record.id)}
                                    icon={<DeleteOutlined />}
                                    style={{ 'marginRight': '3%', }}>

                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                </ div>
            ),
        },
    ];

    return (
        <div>
            <Row justify="center" align="middle" >
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Row>
                        <Col span={24} >
                            <Search
                                placeholder="search projects"
                                style={{ width: `calc(100% ${hasProjectCreatePermission ? '- 36px' : null})` }}
                                onSearch={(value) => debouncedSearch(value)}
                                allowClear={true}
                            />
                            {hasProjectCreatePermission && <div style={{ display: 'inline-block', width: '36px' }}>
                                <Button
                                    icon={<AppstoreAddOutlined />}
                                    style={{ marginLeft: '5px' }}
                                    onClick={() => onHandleCreate()}
                                >
                                </Button>
                            </div>}
                        </Col>
                    </Row>
                </Col>
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Spin spinning={loadingGetProjects}>
                        <div className="table-container" >
                            <Table
                                columns={columns as any}
                                source={projects}
                                style={{ width: 'auto', 'overflowY': 'hidden' }}
                                rowKey="id"
                                size={'small'}
                                maxWidthPerCell={600}
                                height={400}
                                pagination={false}
                                className='project-table'
                            />
                        </div>
                    </Spin>
                </Col>
            </Row>
            <EditProjectModel ref={editProjectModelRef} onClose={() => {
                executeGetProjectsApi(getProjectsRequest());
            }} />
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}