import { ApiRequest } from "app-api";
import { createQueryParams } from "utils/create-query-params";

export function getRolesRequest(data: any | void = null) : ApiRequest<any>{
    let url = '/v1/roles';
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `/v1/roles${params}`;
    }
    return {
        method: 'GET',
        url
    };
}

export function getRoleRequest(data : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/roles/${data}`
    };
}

export function getRolePermissionsRequest(data : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/roles/${data}/permissions`
    };
}

export function createRoleRequest(data : any) : ApiRequest<any>{
    return {
        method : 'POST',
        url: '/v1/roles',
        data : data
    };
}

export function updateRoleRequest(data : any) : ApiRequest<any>{
    return {
        method : 'PUT',
        url: '/v1/roles',
        data : data
    };
}

export function deleteRoleRequest(data : any) : ApiRequest<any>{
    return {
        method : 'DELETE',
        url: `/v1/roles/${data}`
    };
}