import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin, message, } from 'antd';
import WrappedFormBuilder from 'components/form-builder';
import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi } from 'hooks';
import { getSurveyRequest, updateSurveyHouseholdQuestionnaireRequest } from 'api/surveys.api';
import { useSelector } from 'react-redux';

export default function Layout(props: LayoutProps) {
    const messageKey = 'survey-household-questionnaire-command-key';

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { surveyId } = useParams();
    const [questions, setQuestions] = useState<any>([]);

    const { projects: currentUserProjects } = useSelector((state : any) => state.auth);

    const [currentProjectId, setCurrentProjectId] = useState<string>();

    const [{
        loading: loadingGetSurvey,
        data: dataGetSurvey,
        isSuccess: isSuccessGetSurvey
    }, executeGetSurveyApi] = useApi<any, any>();

    const [{
        error: errorCommand,
        loading: loadingCommand,
        data: dataCommand,
        isSuccess: isSuccessCommand
    }, executeCommandApi] = useApi<any, any>([]);

    useEffect(() => {
        dispatch(setSectionHeadTitle("Edit Household Questionnaire"));
    }, []);
    useEffect(() => {
        let [firstProject] = currentUserProjects;
        if (firstProject?.id) {
            setCurrentProjectId(firstProject?.id);
        }
    }, [currentUserProjects]);

    useEffect(() => {
        if (currentProjectId && surveyId) {
            executeGetSurveyApi(getSurveyRequest(currentProjectId, surveyId));
        }
    }, [currentProjectId, surveyId]);

    useEffect(() => {
        if (isSuccessGetSurvey && dataGetSurvey && dataGetSurvey.id) {
            let { householdQuestions } = dataGetSurvey;
            if (Array.isArray(householdQuestions)) {
                setQuestions(householdQuestions);
            }
        }
    }, [isSuccessGetSurvey, dataGetSurvey]);

    useEffect(() => {
        if (isSuccessCommand === true && dataCommand.id) {
            message.success({ content: 'Updated successfully', key: messageKey });
            navigate(`/app/surveys-management/survey/edit/${surveyId}`);
        }
        else if (errorCommand) {
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [dataCommand, isSuccessCommand, errorCommand]);

    function onSaveHandle(values: any) {
        if (currentProjectId && surveyId) {
            message.loading({ content: 'Updating...', key: messageKey });
            let data = {
                'householdQuestions': values
            }
            executeCommandApi(updateSurveyHouseholdQuestionnaireRequest(currentProjectId, surveyId, data))
        }
    }

    return (
        <Spin spinning={loadingGetSurvey || loadingCommand}>
            <WrappedFormBuilder
                questions={questions}
                surveyId={surveyId as any}
                onSave={onSaveHandle}
            />
        </Spin>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}
