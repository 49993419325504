import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

export default function View() {

    return (
        <Footer className="site-layout-background">
            
        </Footer>
    );
}