import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth.reducer';
import systemReducer from './system.reducer';
import localeReducer from './locale.reducer';

const rootReducer = combineReducers({
    auth : authReducer,
    system : systemReducer,
    locale : localeReducer
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export default rootReducer;