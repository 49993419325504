import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Spin, Form, FormItemProps, Button, message, Tooltip, Popconfirm, Typography } from 'antd';

import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi, useTranslate } from 'hooks';
import { cloneSurveyModuleSectionQuestionGroupRequest, createSurveyModuleSectionRequest, deleteSurveyModuleSectionQuestionGroupsRequest, getSurveyModuleSectionQuestionGroupsRequest, getSurveyModuleSectionRequest, updateSurveyModuleSectionQuestionGroupsReOrderRequest, updateSurveyModuleSectionRequest } from 'api/surveys.api';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import SortableTable from 'components/table/sortable-table';
import { WithPermissions } from 'utils/with-permissions';

import './edit-survey-module-section.css';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';

const { useForm } = Form;
const { TextArea } = Input;
const { Title } = Typography;

export default function Layout(props: LayoutProps) {
    const messageKey = 'survey-module-command-key';

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const currentUserProjectId = useSelector(getUserDefaultProjectId) as string;

    const { surveyId, moduleId, sectionId } = useParams();

    const [form] = useForm();
    const [section, setSection] = useState<any>({});

    const [groups, setGroups] = useState<any[]>([]);
    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [confirmDeleteGroupId, setConfirmDeleteGroupId] = useState<number>(-1);
    const [confirmCloneSurveyModuleSectionQuestionGroupId, setConfirmCloneSurveyModuleSectionQuestionGroupId] = useState<number>(-1);

    const [{
        loading: loadingGetSection,
        data: dataGetSection,
        isSuccess: isSuccessGetSection
    }, executeGetSectionApi] = useApi<any, any>();

    const [{
        error: errorCommand,
        loading: loadingCommand,
        data: dataCommand,
        isSuccess: isSuccessCommand
    }, executeCommandApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetQuestionGroups,
        data: dataGetQuestionGroups,
        isSuccess: isSuccessGetQuestionGroups
    }, executeGetSectionsApi] = useApi<any, any>();

    const [{
        loading: loadingUpdateQuestionGroupsOrdered,
        isSuccess: isSuccessUpdateQuestionGroupsOrdered
    }, executeUpdateQuestionGroupsOrderedApi] = useApi<any, any>();

    const [{
        loading: loadingDeleteGroup,
        isSuccess: isSuccessDeleteGroup
    }, executeDeleteGroupApi] = useApi<any, any>();

    const [{
        loading: loadingCloneSurveyModuleSectionQuestionGroups,
        isSuccess: isSuccessCloneSurveyModuleSectionQuestionGroups
    }, executeCloneSurveyModuleSectionQuestionGroupApi] = useApi<any, any>();

    useEffect(() => {
        if (isCreation) {
            dispatch(setSectionHeadTitle(translate('survey-management.create.survey.module.section.header', "Create Survey Section")));
        }
        else {
            dispatch(setSectionHeadTitle(translate('survey-management.edit.survey.module.section.header', "Edit Survey Section")));
        }
    }, [isCreation]);

    useEffect(() => {
        if (sectionId) {
            setIsCreation(false);
        }
    }, [sectionId]);

    useEffect(() => {
        if (currentUserProjectId && surveyId && moduleId && sectionId) {
            executeGetSectionApi(getSurveyModuleSectionRequest(currentUserProjectId, surveyId, moduleId, sectionId));
            fetchQuestionGroups();
        }
    }, [currentUserProjectId, surveyId, moduleId]);

    useEffect(() => {
        if (isSuccessGetSection && dataGetSection && dataGetSection.id) {
            setSection(dataGetSection);

            form.setFieldsValue(dataGetSection);
        }
    }, [isSuccessGetSection, dataGetSection]);

    useEffect(() => {
        if (isSuccessUpdateQuestionGroupsOrdered === true) {
            fetchQuestionGroups();
        }
    }, [isSuccessUpdateQuestionGroupsOrdered]);

    useEffect(() => {
        if (isSuccessCommand && dataCommand.id) {
            let { id } = dataCommand;
            navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${id}`, { replace: true });
            message.success({ content: (isCreation ? 'Created successfully' : 'Updated successfully'), key: messageKey });
        }
        else if (errorCommand) {
            navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}`, { replace: true });
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [dataCommand, isSuccessCommand, errorCommand]);

    useEffect(() => {
        if (isSuccessGetQuestionGroups && Array.isArray(dataGetQuestionGroups)) {
            setGroups(dataGetQuestionGroups);
        }
    }, [isSuccessGetQuestionGroups, dataGetQuestionGroups]);

    useEffect(() => {
        if (isSuccessDeleteGroup === true) {
            setConfirmDeleteGroupId(-1);
            fetchQuestionGroups();
        }
    }, [isSuccessDeleteGroup]);

    useEffect(() => {
        if (isSuccessCloneSurveyModuleSectionQuestionGroups === true) {
            setConfirmCloneSurveyModuleSectionQuestionGroupId(-1);
            fetchQuestionGroups();
        }
    }, [isSuccessCloneSurveyModuleSectionQuestionGroups]);

    function fetchQuestionGroups() {
        currentUserProjectId && surveyId && moduleId && sectionId && executeGetSectionsApi(getSurveyModuleSectionQuestionGroupsRequest(currentUserProjectId, surveyId, moduleId, sectionId));
    }

    function onFinish(value: any) {
        message.loading({ content: (isCreation ? 'Creating...' : 'Updating...'), key: messageKey });
        if (currentUserProjectId) {
            if (isCreation) {
                executeCommandApi(createSurveyModuleSectionRequest(currentUserProjectId, surveyId as string, moduleId as string, value));
            }
            else {
                let data = {
                    ...section,
                    ...value
                }
                executeCommandApi(updateSurveyModuleSectionRequest(currentUserProjectId, surveyId as string, moduleId as string, data));
            }
        }
    }

    function onAddQuestionGroupHandle(value: any) {
        if (surveyId && moduleId && sectionId) {
            navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${sectionId}/question-group/create`);
        }
    }

    function hadleEditGroup(id: string) {
        if (surveyId && moduleId && sectionId) {
            navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${sectionId}/question-group/edit/${id}`);
        }
    }

    function hadleDeleteGroup(id: string) {
        currentUserProjectId && surveyId && moduleId && sectionId && executeDeleteGroupApi(deleteSurveyModuleSectionQuestionGroupsRequest(currentUserProjectId, surveyId, moduleId, sectionId, id));
    }

    function handleOnDisplayLogic() {
        navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${sectionId}/display-logic`);
    }

    function onReOrdered(items : any[]){
        if(currentUserProjectId && surveyId && moduleId && sectionId){
            let payload = {
                items
            };
            let data = updateSurveyModuleSectionQuestionGroupsReOrderRequest(currentUserProjectId, surveyId, moduleId, sectionId, payload);
            executeUpdateQuestionGroupsOrderedApi(data);
        }
    }

    function handleClone(id: string) {
        currentUserProjectId && surveyId && moduleId && sectionId && id && executeCloneSurveyModuleSectionQuestionGroupApi(cloneSurveyModuleSectionQuestionGroupRequest(currentUserProjectId, surveyId, moduleId, sectionId, id));
    }
    
    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 24 }, lg: { span: 24 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    const columns = [
        {
            title: "Name",
            key: 'name',
            dataIndex: "name",
            overridenWidth: 50,
        },
        {
            title: "Questions Count",
            key: 'questionCount',
            dataIndex: "questionCount",
            render: (text: any, record: any) => {
                let { questionCount } = record;
                if (!questionCount || questionCount === 0) {
                    return <>No Questions</>
                }
                else if (questionCount === 1) {
                    return <>One Question</>
                }
                else {
                    return <>{questionCount} Questions</>
                }
            },
            overridenWidth: 30,
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            overridenWidth: 30,
            render: (text: any, record: any) => (
                <div>
                    <WithPermissions permissions={['permission-project:survey:edit']} >
                        <Tooltip title="Edit section" placement="bottom" className='action' >
                            <Button
                                onClick={() => hadleEditGroup(record.id)}
                                icon={<EditOutlined />}
                                style={{ 'marginRight': '12px', }}
                            >
                            </Button>
                        </Tooltip>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:clone']} >
                        <Popconfirm
                            title="Clone question group?"
                            visible={confirmCloneSurveyModuleSectionQuestionGroupId === record.id}
                            onConfirm={() => handleClone(record.id)}
                            okButtonProps={{ loading: loadingCloneSurveyModuleSectionQuestionGroups }}
                            onCancel={() => setConfirmCloneSurveyModuleSectionQuestionGroupId(-1)}
                        >
                            <Tooltip title="Clone question group" placement="bottom">
                                <Button
                                    onClick={() => setConfirmCloneSurveyModuleSectionQuestionGroupId(record.id)}
                                    icon={<CopyOutlined />}
                                    style={{ 'marginRight': '3%', }}>

                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:edit']} >
                        <Popconfirm
                            title="Delete group?"
                            visible={confirmDeleteGroupId === record.id}
                            onConfirm={() => hadleDeleteGroup(record.id)}
                            okButtonProps={{ loading: loadingDeleteGroup }}
                            onCancel={() => setConfirmDeleteGroupId(-1)}
                        >
                            <Tooltip title="Delete section" placement="bottom">
                                <Button
                                    onClick={() => setConfirmDeleteGroupId(record.id)}
                                    icon={<DeleteOutlined />}>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                </ div>
            ),
        },
    ];

    return (
        <div className='edit-survey-module-section'>
            <Spin spinning={loadingCommand || loadingGetSection}>
                <Row>
                    <Col span={22}>
                        <div style={{ display: 'inline-block', 'float': 'right' }} >
                            <Button
                                onClick={handleOnDisplayLogic}
                                style={{ marginLeft: '10px' }}
                                disabled={isCreation}
                            >
                                Display Logic
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className={'edit-survey-module-section-form'}>
                    <Col span={20} offset={2}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >

                            <Row>
                                <Col span={24} >
                                    <Form.Item
                                        label="Section Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input />
                                    </Form.Item>

                                </Col>

                                <Col span={24} >
                                    <Form.Item
                                        label="Section Description"
                                        name="description"
                                        {...formItemProps}
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>

                                </Col>
                            </Row>

                            {(!isCreation) && <>
                                <Row>
                                    <Col span={18}>
                                        <Title level={5} style={{ fontWeight: 'normal' }} >
                                            Question Groups incorporated with this section
                                        </Title>
                                    </Col>
                                    <Col span={6}>
                                        <Button
                                            icon={<PlusOutlined />}
                                            style={{ width: '100%' }}
                                            onClick={onAddQuestionGroupHandle}
                                        >
                                            Add Question Group
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24} style={{ 'marginTop': '2%' }}>
                                        <Spin spinning={loadingGetQuestionGroups || loadingUpdateQuestionGroupsOrdered} >
                                            <SortableTable
                                                columns={columns}
                                                source={groups}
                                                style={{ width: 'auto', 'overflowY': 'hidden' }}
                                                size={'small'}
                                                rowKey="id"
                                                maxWidthPerCell={600}
                                                height={400}
                                                pagination={false}
                                                showHeader={false}
                                                className='question-groups-table'
                                                onFinish={onReOrdered}
                                            />
                                        </Spin>
                                    </Col>
                                </Row>
                            </>}

                            <Form.Item wrapperCol={{ offset: 20, span: 4 }} style={{ 'marginTop': '2%' }}>
                                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                    {isCreation ? 'Create' : 'Update'}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>

                </Row>
            </Spin>
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}
