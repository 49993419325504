/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UseApiResponse } from 'axios-types';
import { ApiRequest, WrappedResponse } from 'app-api';

import { HttpConfig } from 'configs';
import { configure, isInstanceOfWrappedResponse } from 'providers/ApiClient';
import { useAppDispatch } from 'store';
import { signOutWithDelay } from 'reducers/thunks/auth.thunk';
import { notification } from 'antd';

export function useApi<RequestType, ResponseType>(
    defaultResponseData?: ResponseType,
    isBlob : Boolean = false
) {
    const dispatch = useAppDispatch();
    const { accessToken } = useSelector((state : any) => state.auth);

    const [isMounted, setIsMounted] = useState<boolean>(false)

    const [responseData, setResponseData] = useState<ResponseType>();

    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [isSuccess, setIsSuccess] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState();
    const [status, setStatus] = useState<number>();
    const [fileName, setFileName] = useState<string>();

    const sendRequest = (req: ApiRequest<RequestType>) => {
        return new Promise((resolve, reject) => {
            (async () => {
                setLoading(true);
                setIsSuccess(undefined);
                if (error) {
                    setError(undefined);
                }
                try {
                    let headers = {
                        ...HttpConfig.headers,
                        ...req.headers
                    }
                    let extras = { } as any;

                    if(isBlob){
                        extras.responseType = 'arraybuffer';
                    }
                    const config = Object.assign({}, HttpConfig, req, { headers }, extras);
                    const instance = configure<RequestType, ResponseType>(config, accessToken as string);
                    
                    const response = await instance.request(config);

                    const contentType = response.headers['content-type'];
                    const isJson = contentType === "application/json";

                    let successStatuses = [200, 201, 202];
                    if (successStatuses.includes(response.status)) {
                        if(isJson){
                            if(isInstanceOfWrappedResponse(response.data)){
                                let schemedData = response.data as unknown as WrappedResponse<ResponseType>;
                                
                                setStatus((_ : any) => response.status);
                                setResponseData((_ : any) => schemedData.data);
                                resolve(schemedData);
                                setIsSuccess(() => true);
                                setLoading((_ : any) => false);
                            }
                            else {
                                let messages = response?.data?.messages
                                let message = Array.isArray(messages) ? messages.join(", ") : messages as string
        
                                throw new Error(message)
                            }
                        }
                        else {
                            if(isBlob === true){
                                let contentDisposition = response.headers["content-disposition"] || response.headers['Content-Disposition'];
                                
                                if(contentDisposition){
                                    let match = contentDisposition.match(/filename\s*=\s*"(.+)"/i) || [''];
                                    if(match && match[1]){
                                        let filename = match[1];
                                        if(filename){
                                            setFileName(filename);
                                        }
                                    }
                                }

                                let blob = new Blob([response.data]) as any;
                                setStatus((_ : any) => response.status);
                                setResponseData((_ : any) => blob);
                                resolve(blob);
                                setIsSuccess(() => true);
                                setLoading((_ : any) => false);

                                
                            }
                        }
                    }
                    else {
                        let messages = response?.data?.messages
                        let message = Array.isArray(messages) ? messages.join(", ") : messages as string

                        throw new Error(message)
                    }
                } catch (err: any) {
                    let code = err?.response?.status;
                    if (accessToken && code === 401) {
                        dispatch(signOutWithDelay(undefined) as any);
                    }
                    else {
                        let messages = err?.response?.data?.messages || err.message
                        let message = Array.isArray(messages) ? messages.join(", ") : messages || err

                        if (!(typeof message === 'string' || message instanceof String)) {
                            message = 'Network Error';
                        }
                        setError(message as any);
                        reject(message);

                        setIsSuccess(false);
                        setLoading(false);
                        
                        if(code === 500){
                            let description = message || 'Contact your administrator for more information';
                            notification.open({
                                type: 'error',
                                message: 'Something has been gone wrong!',
                                description,
                                duration : 3
                            });
                        }
                    }
                }
                finally {
                    if (isMounted) {
                        setLoading(false);
                        if (initialLoad) {
                            setInitialLoad(false);
                        }
                    }
                }
            })();
        });
    };

    useEffect(() => {
        setIsMounted(true)
        return () => {
            setIsMounted(false)
        }
    }, [])

    return [
        {
            loading,
            isSuccess,
            error : (loading === true)? '' : error,
            data: responseData || defaultResponseData,
            status,
            initialLoad,
            pendingOrLoading: initialLoad || loading,
            fileName,
        },
        sendRequest,
    ] as UseApiResponse<ResponseType>;
};

export default useApi;