import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { changeLocale, fetchLocale, fetchLocaleTypes } from './thunks/locale.thunk';

interface State {
    types: any,
    type: string,
    keys: any
    fallback: string,
}
let fallback = 'en';

const initialState = {
    types: {
        [fallback] : undefined
    },
    type: fallback,
    keys: {

    },
    fallback
} as State;

const counterSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLocaleType(state, action: PayloadAction<string>) {
            state.type = action.payload;
        },
    },
    extraReducers: {
        [fetchLocale.fulfilled.type]: (state: any, action: PayloadAction<any>) => {
            let { type, keys } = action.payload;
            if (type) {
                state.type = type;
                state.keys = keys;
            }
        },
        [fetchLocaleTypes.fulfilled.type]: (state: any, action: PayloadAction<any>) => {
            let types = action.payload;
            if (Array.isArray(types) && types.length > 0) {
                state.types = types.reduce((a, v) => ({ ...a, [v.code]: {
                    name : v.name,
                    keys : []
                }}), {});
            }
        },
        [changeLocale.fulfilled.type]: (state: any, action: PayloadAction<any>) => {
            let { type, keys } = action.payload;
            if(type){
                state.type = type;
                state.keys = keys;
                if(!state.types[type]){
                    state.types[type].keys = keys;
                }
            }
        },
    },
})

export const { setLocaleType } = counterSlice.actions
export default counterSlice.reducer