import React, { useEffect, useState, useImperativeHandle, useCallback } from 'react';
import { Button, Col, Form, FormItemProps, Input, Modal, Row, Select, Spin, AutoComplete, message } from 'antd';
import { useApi } from 'hooks';
import { getLocaleTypesRequest } from 'api/locales.api';
import { DeleteOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

import './add-key.model.css';
import { getLocaleInterfacesRequest, getLocalizationsKeyRequest, postLocalizationsKeyRequest } from 'api/localization.api';

const { useForm } = Form;
const { Option } = Select;

const Layout = React.forwardRef<AddKeyModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const messageKey = 'post-key-key';

    const [form] = useForm();

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [source, setSource] = useState<string>();
    const [candidateLocaleTypes, setCandidateLocaleTypes] = useState<any[]>([]);
    const [locales, setLocales] = useState<any[]>([]);
    const [candidateLocaleInterfaces, setCandidateLocaleInterfaces] = useState<any[]>([]);

    const [{
        loading: loadingGetLocaleTypes,
        data: dataGetLocaleTypes,
        isSuccess: isSuccessGetLocaleTypes
    }, executeGetLocaleTypesApi] = useApi<any, any>([]);

    const [{
        data: dataGetLocaleInterfaces,
        isSuccess: isSuccessGetLocaleInterfaces
    }, executeGetLocaleInterfacesApi] = useApi<any, any>([]);

    const debouncedInterfaceSearch = useCallback(
        debounce(nextValue => {
            if(nextValue && nextValue.length){
                // console.log(nextValue)
                // onSearch(nextValue);
            }
        }, 500),
        [], // will be created only once initially
    );

    const [{
        loading: loadingPostKey,
        data: dataPostKey,
        isSuccess: isSuccessPostKey,
        error: errorPostKey
    }, executePostKeyApi] = useApi<any, any>([]);

    const [{
        data: dataGetKey,
        isSuccess: isSuccessGetKey
    }, executeGetKeyApi] = useApi<any, any>([]);

    useImperativeHandle(ref, () => ({
        openForAdd() {
            setIsVisible(true);
            form.resetFields();
            setLocales(i => []);
        },
        openForEdit(_key : string){
            setIsVisible(true);
            form.resetFields();
            setLocales(i => []);
            executeGetKeyApi(getLocalizationsKeyRequest(_key));
        }
    }));

    useEffect(() => {
        executeGetLocaleTypesApi(getLocaleTypesRequest());
    }, []);

    useEffect(() => {
        if(source){
            executeGetLocaleInterfacesApi(getLocaleInterfacesRequest(source));
        }
    }, [source]);

    useEffect(() => {
        if (isSuccessGetLocaleTypes && Array.isArray(dataGetLocaleTypes)) {
            setCandidateLocaleTypes(dataGetLocaleTypes);
        }
    }, [dataGetLocaleTypes, isSuccessGetLocaleTypes]);
    
    useEffect(() => {
        if (isSuccessGetLocaleInterfaces && Array.isArray(dataGetLocaleInterfaces)) {
            setCandidateLocaleInterfaces(dataGetLocaleInterfaces);
        }
    }, [dataGetLocaleInterfaces, isSuccessGetLocaleInterfaces]);
    
    useEffect(() => {
        if (isSuccessGetLocaleTypes && Array.isArray(dataGetLocaleTypes)) {
            setCandidateLocaleTypes(dataGetLocaleTypes);
        }
    }, [dataGetLocaleTypes, isSuccessGetLocaleTypes]);

    useEffect(() => {
        if (isSuccessGetKey && dataGetKey) {
            let { _id, _source, _interface, _key, ...keys } = dataGetKey;

            if(keys){
                let mappedKeys = Object.keys(keys).map((key) => ({ code : key, value : keys[key]}));

                setLocales(mappedKeys);
            }

            setSource(_source);
            form.setFields([
                {
                    name: 'source',
                    value: _source,
                },
                {
                    name: 'interface',
                    value: _interface,
                },
                {
                    name: 'key',
                    value: _key,
                },
            ]);
        }
    }, [dataGetKey, isSuccessGetKey]);

    useEffect(() => {
        if (isSuccessPostKey === true) {
            setIsVisible(false);
            onCloseCallback();
        }
        else if (isSuccessPostKey === false) {
            message.error({ content: errorPostKey, key: messageKey });
        }
    }, [dataPostKey, isSuccessPostKey]);

    useEffect(() => {
        if (!isVisible) {
            typeof onCloseCallback === 'function' && onCloseCallback()
        }
    }, [isVisible]);

    useEffect(() => {
        if (Array.isArray(locales)) {
            let selectedLocaes = locales.map(i => i.name);
            setCandidateLocaleTypes(i => i.map(j => {
                return {
                    ...j,
                    isSelected: selectedLocaes.includes(j.name)
                };
            }));
        }
    }, [locales]);

    function onFinish(values: any) {
        if (Array.isArray(locales)) {
            let data = {
                ...values,
                locales: locales.reduce((pv: any, cv: any) => ({ ...pv, [cv.code]: cv.value }), {})
            }
            executePostKeyApi(postLocalizationsKeyRequest(data));
        }
    }

    function onAddLocale(code: string) {
        let item = candidateLocaleTypes.find(i => i.code === code);
        if (item) {
            let updated = {
                ...item,
                locales
            };
            setLocales(items => [...items, updated]);
        }
    }

    function onChangeLocale(code: string, index: number) {
        let item = candidateLocaleTypes.find(i => i.code === code);
        if (item) {
            setLocales(items => items.map((_item: any, _index: number) => {
                return _index === index ? item : _item
            }));
        }
    }

    function onChangeLocaleValue(code: string, value: string, index: number) {
        let item = candidateLocaleTypes.find(i => i.code === code);
        if (item) {
            let updated = {
                ...item,
                value
            };
            setLocales(items => items.map((_item: any, _index: number) => {
                return _index === index ? updated : _item
            }));
        }
    }

    function onDeleteLocale(value: string, index: number) {
        setLocales(array => {
            array.splice(index, 1);
            return [...array];
        });
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    let selectWidth = '160px'

    return (
        <Modal
            title={`Add Key`}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={800}
        >
            <Spin spinning={loadingGetLocaleTypes || loadingPostKey}>
                {isVisible && <Row>
                    <Col span={20} offset={3}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Source"
                                        name="source"
                                        rules={[{ required: true, message: 'Please select source!' }]}
                                        {...formItemProps}
                                    >
                                        <Select
                                            placeholder={'select source'}
                                            defaultActiveFirstOption={false}
                                            value={source}
                                            onChange={(value : any) => setSource(value)}
                                        >
                                            <Option key={'w'} value={'w'}>Web</Option>
                                            <Option key={'m'} value={'m'}>Mobile</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    <Form.Item
                                        label="Interface"
                                        name="interface"
                                        rules={[{ required: true, message: 'Please select interface!' }]}
                                        {...formItemProps}
                                    >
                                        <AutoComplete
                                            options={candidateLocaleInterfaces.map(i => ({ key : i.id , value : i.name }))}
                                            // onSelect={onSelect}
                                            onSearch={(value) => debouncedInterfaceSearch(value)}
                                            placeholder="interface"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} >
                                    <Form.Item
                                        label="Key"
                                        name="key"
                                        rules={[{ required: true, message: 'Please input key!' }]}
                                        {...{
                                            ...formItemProps,
                                            wrapperCol: { span: 22 }
                                        }}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <br />
                            {renderLocales()}

                            <Form.Item wrapperCol={{ offset: 19, span: 4 }} style={{ 'marginTop': '5%' }}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>}
            </Spin>
        </Modal>
    );

    function renderLocales() {
        let items = locales.map((item: any, index: number) => {
            let { code, value } = item;
            return (
                <Row key={`${code}-${index}`}>
                    <Col span={23}>
                        <div className={'added-locale'}>
                            <Select
                                placeholder={'select locale'}
                                style={{ width: selectWidth }}
                                value={code}
                                onChange={(value) => onChangeLocale(value, index)}
                                showSearch
                            >
                                {renderOptions()}
                            </Select>
                            <Input
                                value={value}
                                onChange={e => e.target.value && onChangeLocaleValue(code, e.target.value, index)}
                                style={{ width: `calc(90% - ${selectWidth})` }}
                            />
                            <Button
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => onDeleteLocale(code, index)}
                            />
                        </div>
                    </Col>
                </Row>
            )
        });

        let addNewItem = <Row key={'key'}>
            <Col span={23} >
                <Select
                    style={{ minWidth: selectWidth }}
                    placeholder={'add locale'}
                    onChange={onAddLocale}
                    value={'click here to add locale'}
                    showSearch
                >
                    {renderOptions()}
                </Select>
            </Col>
        </Row>

        return [...items, addNewItem];
    }

    function renderOptions() {
        return Array.isArray(candidateLocaleTypes) && candidateLocaleTypes.map((lt: any, index: number) => {
            let { code, name, isSelected } = lt;
            return <Option key={index} value={code} disabled={isSelected}>{code}-{name}</Option>
        })
    }

})

Layout.propTypes = {

};

type LayoutProps = {
    onClose: () => void
}

export type AddKeyModelHandle = {
    openForAdd: () => void,
    openForEdit: (_id : string) => void,
}


export default Layout;