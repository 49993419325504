import React, { StrictMode } from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react'
import { ConfigProvider, Typography } from 'antd';

import App from 'app';
import { persistor, store } from "store";

const { Title } = Typography;

const rootElement = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(rootElement);

root.render(
    <StrictMode>
        <Provider store={store}>
            <PersistGate loading={<Title>Loading...</Title>} persistor={persistor} >
                <ConfigProvider>
                    <App />
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </StrictMode>
);