import control from '../control'
export default class controlMappingComponent extends control {

  static get definition() {
    return {
      mi18n: 'mappings',
      type : 'mappings',
      icon : '🎞️',
      multiple: true,
    }
  }

  configure(){
    
  }

  build() {
    return this.dom 
  }

  onRender() {
  }
  
  
}

control.register(['mappings'], controlMappingComponent)
