/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export function usePermissions(
    permissions: string[] | string
) {
    const { permissions : scopes } = useSelector((state : any) => state.auth);
    const [hasPermissions, setHasPermissions] = useState<boolean>();
    
    useEffect(() => {
        let items = [] as string[];
        if(typeof permissions === 'string' || permissions instanceof String){
            items.push(permissions as string)
        }
        else if(Array.isArray(permissions)) {
            items = permissions
        }
        let status = false;
        for (var index in items) {
            let property = items[index];
            if (scopes?.hasOwnProperty(property)) {
                status = true;
                break;
            }
        }
        setHasPermissions(status);
    }, [permissions, scopes]);
    
    return [
        hasPermissions
    ] as any;
};

export default usePermissions;