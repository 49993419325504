import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { NavigateApp } from 'constants/paths';
import { getIsAuthenticated } from 'selectors/auth.selectors';

export default function Layout() {

  const isAuthenticated = useSelector(getIsAuthenticated);

  if (isAuthenticated) {
    return <Navigate to={NavigateApp} />;
  }
  
  return <Outlet />;
}
