import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Button, Col, Form, FormItemProps, Input, Modal, Row, Select, Spin } from 'antd';
import { useApi } from 'hooks';
import { getLocaleTypesRequest } from 'api/locales.api';
import { DeleteOutlined } from '@ant-design/icons';

import './add-language.model.css';
import { getLocaleKeysRequest, postLocalizationsRequest } from 'api/localization.api';

const { useForm } = Form;
const { Option } = Select;

const Layout = React.forwardRef<AddLanguageModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const [form] = useForm();

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [source, setSource] = useState<string>();
    const [localeKeys, setLocaleKeys] = useState<any[]>([]);
    const [candidateLocaleKeys, setLandidateLocaleKeys] = useState<any[]>([]);
    const [candidateLocaleTypes, setCandidateLocaleTypes] = useState<any[]>([]);

    const [{
        loading: loadingGetLocaleTypes,
        data: dataGetLocaleTypes,
        isSuccess: isSuccessGetLocaleTypes
    }, executeGetLocaleTypesApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetLocaleKeys,
        data: dataGetLocaleKeys,
        isSuccess: isSuccessGetLocaleKeys
    }, executeGetLocaleKeysApi] = useApi<any, any>([]);

    const [{
        loading: loadingPostLocalizations,
        data: dataPostLocalizations,
        isSuccess: isSuccessPostLocalizations
    }, executePostLocalizationsApi] = useApi<any, any>([]);
    
    useEffect(() => {
        if (isSuccessGetLocaleKeys && Array.isArray(dataGetLocaleKeys)) {
            setLandidateLocaleKeys(dataGetLocaleKeys);
        }
    }, [dataGetLocaleKeys, isSuccessGetLocaleKeys]);

    useEffect(() => {
        if (isSuccessPostLocalizations) {
            setIsVisible(false);
            onCloseCallback();
            setLocaleKeys(_ => []);
        }
    }, [dataPostLocalizations, isSuccessPostLocalizations]);

    useEffect(() => {
        executeGetLocaleTypesApi(getLocaleTypesRequest());
    }, []);

    useEffect(() => {
        if (source) {
            executeGetLocaleKeysApi(getLocaleKeysRequest(source));
        }
    }, [source]);

    useEffect(() => {
        if (isSuccessGetLocaleTypes && Array.isArray(dataGetLocaleTypes)) {
            let sorted = dataGetLocaleTypes.sort((a, b) => a.code.localeCompare(b.code));
            setCandidateLocaleTypes(sorted);
        }
    }, [dataGetLocaleTypes, isSuccessGetLocaleTypes]);

    useEffect(() => {
        if (Array.isArray(localeKeys)) {
            let selectedLocaes = localeKeys.map(i => i.value);
            setLandidateLocaleKeys(i => i.map(j => {
                return {
                    ...j,
                    isSelected: selectedLocaes.includes(j.value)
                };
            }));
        }
    }, [localeKeys]);

    useImperativeHandle(ref, () => ({
        openForAdd() {
            setIsVisible(true);
            form.resetFields();
        }
    }));

    function onSourceChange(values: string) {
        setSource(values)
    }

    function onFinish(values: any) {
        if (Array.isArray(localeKeys)) {
            let data = {
                ...values,
                locales: localeKeys.reduce((pv: any, cv: any) => ({ ...pv, [cv.value]: cv.code  }), {})
            }
            executePostLocalizationsApi(postLocalizationsRequest(data));
        }
    }

    function onAddKey(key : string) {
        let item = candidateLocaleKeys.find(i => i.value === key);
        if (item) {
            let updated = {
                ...item,
            };
            setLocaleKeys(items => [...items, updated]);
        }
    }

    function onChangeKey(key : string, index : number) {
        let item = candidateLocaleKeys.find(i => i.value === key);
        if (item) {
            setLocaleKeys(items => items.map((_item: any, _index: number) => {
                return _index === index ? item : _item
            }));
        }
    }

    function onChangeKeyValue(key : string, value : string, index : number) {
        let item = candidateLocaleKeys.find(i => i.value === key);
        if (item) {
            let updated = {
                ...item,
                code : value
            };
            setLocaleKeys(items => items.map((_item: any, _index: number) => {
                return _index === index ? updated : _item
            }));
        }
    }

    function onLocaleKeyDeleted(code : string, index : number){
        setLocaleKeys(array => {
            array.splice(index, 1);
            return [...array];
        });
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    let selectWidth = '300px'

    return (
        <Modal
            title={`Add Language`}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={1200}
        >
            <Spin spinning={loadingGetLocaleKeys || loadingGetLocaleTypes || loadingPostLocalizations}>

                {isVisible && <Row>
                    <Col span={20} offset={3}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Locale type"
                                        name="type"
                                        rules={[{ required: true, message: 'Please input locale type!' }]}
                                        {...formItemProps}
                                    >
                                        <Select
                                            placeholder={'select locale'}
                                            showSearch
                                        >
                                            {renderLocaleOptions()}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    <Form.Item
                                        label="Source"
                                        name="source"
                                        rules={[{ required: true, message: 'Please select source!' }]}
                                        {...formItemProps}
                                    >
                                        <Select
                                            placeholder={'select source'}
                                            defaultActiveFirstOption={false}
                                            onChange={onSourceChange}
                                        >
                                            <Option key={'w'} value={'w'}>Web</Option>
                                            <Option key={'m'} value={'m'}>Mobile</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <br />
                            {source && renderKeys()}

                            <Form.Item wrapperCol={{ offset: 21, span: 2 }} style={{ 'marginTop': '5%' }}>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>}
            </Spin>
        </Modal>
    );

    function renderKeys() {
        let items = localeKeys.map((item: any, index: number) => {
            let { value : key } = item;
            return (
                <Row key={index}>
                    <Col span={23}>
                        <div className={'added-locale'}>
                            <Select
                                placeholder={'select locale'}
                                style={{ width: selectWidth }}
                                value={key}
                                onChange={(value) => onChangeKey(value, index)}
                                showSearch
                            >
                                {renderLocaleKeys()}
                            </Select>
                            <Input 
                            onChange={e => e.target.value && onChangeKeyValue(key, e.target.value, index)}
                            style={{ width : `calc(90% - ${selectWidth})` }}
                             />
                            <Button
                                shape="circle"
                                icon={<DeleteOutlined />}
                                onClick={() => onLocaleKeyDeleted(key, index)}
                            />
                        </div>
                    </Col>
                </Row>
            )
        });

        let addNewItem = <Row key={'key'}>
            <Col span={23} >
                <Select
                    style={{ minWidth: selectWidth }}
                    placeholder={'add key'}
                    onChange={onAddKey}
                    showSearch
                    value={'click here to add key'}
                >
                    {renderLocaleKeys()}
                </Select>
            </Col>
        </Row>

        return [...items, addNewItem];
    }

    function renderLocaleKeys(){
        return Array.isArray(candidateLocaleKeys) && candidateLocaleKeys.map(lt => {
            let { value, isSelected } = lt;
            return <Option key={value} value={value} disabled={isSelected}>{value}</Option>
        })
    }

    function renderLocaleOptions(){
        return Array.isArray(candidateLocaleTypes) && candidateLocaleTypes.map(lt => {
            let { code, isActive, name } = lt;
            return <Option key={code} value={code} disabled={isActive}>{code}-{name}</Option>
        })
    }

})

Layout.propTypes = {

};

type LayoutProps = {
    onClose: () => void
}

export type AddLanguageModelHandle = {
    openForAdd: () => void,
}


export default Layout;