import React from 'react';
import { Navigate, Outlet, useMatch } from 'react-router-dom';
import { Layout } from 'antd';

import AppHeader from 'layouts/header';
import SectionHeader from 'layouts/section-header';
import AppFooter from 'layouts/footer';

const { Content } = Layout;

export default function View(props: LayoutProps) {
    const match = useMatch('app');
    
    if (match) {
        return <Navigate to="/app/dashboard" replace />
    }

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <AppHeader />
            <SectionHeader />
            <Content style={{ padding: "1% 5%" }} >
                <Outlet />
            </Content>
            <AppFooter />
        </Layout>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}