import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, Spin, Alert } from 'antd';

import { useAppDispatch } from 'store';
import { signIn } from 'reducers/auth.reducer';
import useApi from 'hooks/useApi';
import { createSignInRequest } from 'api/auth.api';
import { SignInRequest, SignInResponse } from 'app-auth';

import './index.css'
import { useTranslate } from 'hooks';
import { isDevelopment } from 'configs';

const { Title } = Typography;
const { useForm } = Form;

export default function Layout(props: LayoutProps) {
    const dispatch = useAppDispatch();
    const [form] = useForm();
    const translate = useTranslate();
    const [message, setMessage] = useState<string>();

    const [{
        loading: signInLoading,
        data: signInData,
        isSuccess: isSuccessSignIn,
        error: errorSignIn
    }, executeSignInApi] = useApi<SignInRequest, SignInResponse>();

    useEffect(() => {
        if (isSuccessSignIn === true) {
            let { access_token } = signInData as any;
            dispatch(signIn(access_token))
        }
        else {
            setMessage(errorSignIn)
        }
    }, [signInData, isSuccessSignIn, errorSignIn])

    function handleSignIn(values: any) {
        executeSignInApi(createSignInRequest(values));
    }

    function onFinish(values: any) {
        handleSignIn(values)
    };

    let initialConfig = isDevelopment? {
        username : 'q.q',
        password : 'q', 
    } : {  };

    return (
        <Spin spinning={signInLoading}>
            <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                <Col xs={20} sm={16} md={12} lg={8} xl={6} xxl={6}>
                    <Form
                        name="basic"
                        form={form}
                        initialValues={initialConfig}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Title level={4}>{translate('sign-in.username', "Username")}</Title>
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: translate('sign-in.username.required-message', 'Please input password!') }]}
                        >
                            <Input placeholder={translate('sign-in.username.placeholder', "username")} />
                        </Form.Item>

                        <Title level={4}>{translate('sign-in.password', "Password")}</Title>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: translate('sign-in.password.required-message', 'Please input password!') }]}
                        >
                            <Input placeholder={translate('sign-in.password.placeholder', "password")} type={'password'} />
                        </Form.Item>

                        {renderMessage()}

                        <Form.Item style={{ "display": "grid", "justifyContent": "center" }} >
                            <Button style={{ minWidth: '160px', minHeight: '40px' }} type="primary" htmlType="submit">
                                {translate('sign-in.button', 'Sign in')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Spin>
    );


    function renderMessage() {
        if (!message) {
            return <></>
        }
        return <div><Alert message={message} type="error" showIcon style={{ marginBottom: '20px' }} /></div>
    }
}

Layout.propTypes = {

};

type LayoutProps = {
}
