import React, { useEffect, useState } from 'react';

import { useAppDispatch } from 'store';

import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi, useTranslate } from 'hooks';
import { Button,  Col, Form, Input, message, Row, Select, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { getUserRequest } from 'api/users.api';
import { CopyOutlined, RedoOutlined } from '@ant-design/icons';

import './index.css';
import { updateUserProfileRequest } from 'api/profile.api';
import { NavigateChangePassword } from 'constants/paths';
import { NavigateOptions, useNavigate } from 'react-router-dom';

const { useForm } = Form;
const { Option } = Select;

export default function Layout(props: LayoutProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    const [form] = useForm();

    const messageKey = 'profile-update-command-key';

    const { types } = useSelector((state : any) => state.locale);
    const { user: currentUser } = useSelector((state : any) => state.auth);

    const [isOnEditMode, setIsOnEditMode] = useState<boolean>(false);
    const [locales, setLocales] = useState<any[]>([]);

    const [{
        loading: loadingGetUser,
        data: dataGetUser,
        isSuccess: isUserGetSuccess
    }, executeGetUserApi] = useApi<any, any>();

    const [{
        error: errorUpdateProfile,
        loading: loadingUpdateProfile,
        isSuccess: isUpdateProfileSuccess
    }, executeUpdateProfileApi] = useApi<any, any>();

    useEffect(() => {
        dispatch(setSectionHeadTitle(translate('profile.header', "Profile")));
    }, []);

    useEffect(() => {
        if (isUserGetSuccess) {
            form.setFieldsValue(dataGetUser);
        }
    }, [dataGetUser, isUserGetSuccess]);

    useEffect(() => {
        if (currentUser && currentUser.id) {
            executeGetUserApi(getUserRequest(currentUser.id as string));
        }
    }, [currentUser]);

    useEffect(() => {
        if (types) {
            let mappedKeys = Object.keys(types).map((key) => ({ key, value: types[key]?.name }));
            setLocales(mappedKeys);
        }
    }, [types]);

    useEffect(() => {
        if (isUpdateProfileSuccess === true) {
            setIsOnEditMode(i => false);
            message.success({ content: 'Profile updated!', key: messageKey });
        }
        else {
            errorUpdateProfile && message.error({ content: errorUpdateProfile, key: messageKey });
        }
    }, [isUpdateProfileSuccess, errorUpdateProfile]);

    function onChangePassword() {
        let options = { state: { allowCancel: true } } as NavigateOptions;
        navigate(NavigateChangePassword, options);
    }

    function onFinish(values: any) {
        if (isOnEditMode) {
            executeUpdateProfileApi(updateUserProfileRequest(values));
            message.loading({ content: 'Updating...', key: messageKey });
        }
        else {
            setIsOnEditMode(i => true);
        }
    }

    return (
        <div className='profile' >
            <Row justify="center" align="middle" >
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Spin spinning={loadingGetUser || loadingUpdateProfile} >
                        <Form
                            form={form}
                            labelCol={{ span: 8 }}
                            onFinish={onFinish}
                            wrapperCol={{ span: 8 }}
                            initialValues={{ preferredLocale: 'en' }}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                            >
                                <Input readOnly={true} prefix={<CopyOutlined onClick={() => {
                                    let value = form.getFieldValue('username');
                                    navigator.clipboard.writeText(value)
                                }} />} className='username' />
                            </Form.Item>

                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: 'Please input your first name!' }]}
                            >
                                <Input readOnly={!isOnEditMode} className={(isOnEditMode ? '' : 'no-style-input')} />
                            </Form.Item>

                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: 'Please input your last name!' }]}
                            >
                                <Input readOnly={!isOnEditMode} className={(isOnEditMode ? '' : 'no-style-input')} />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{
                                    type: 'email',
                                    message: "value is not valid email",
                                }]}
                                validateTrigger="onBlur"
                            >
                                <Input readOnly={!isOnEditMode} className={(isOnEditMode ? '' : 'no-style-input')} />
                            </Form.Item>

                            <Form.Item
                                label="Contact Number"
                                name="contactNumber"
                                rules={[
                                    {
                                        pattern: /^(?:\d*)$/,
                                        message: "value should be in phone number format",
                                    },
                                    {
                                        max: 16,
                                        message: "value should be less than 16 digits",
                                    },
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Input readOnly={!isOnEditMode} className={(isOnEditMode ? '' : 'no-style-input')} />
                            </Form.Item>

                            <Form.Item
                                label="Preferred locale"
                                name="preferredLocale"
                            >
                                {(() => {
                                    if (isOnEditMode) {
                                        return (
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder={'select cluster'}
                                                defaultActiveFirstOption={false}
                                            >
                                                {locales.map((d: any) => <Option key={d.key} value={d.key}>{d.value}</Option>)}
                                            </Select>
                                        );
                                    }
                                    else {
                                        return (<Input readOnly={true} className={(isOnEditMode ? '' : 'no-style-input')} />);
                                    }
                                })()}
                            </Form.Item>

                            <Form.Item
                                label="Passowrd"
                            >
                                <Button
                                    onClick={onChangePassword}
                                    icon={<RedoOutlined />}
                                >
                                    Change
                                </Button>
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                                <Button
                                    type="primary" htmlType="submit"
                                    style={{ 'float': 'right' }}>
                                    {(isOnEditMode ? "Save" : "Edit")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </Col>
            </Row>
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}