import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";

import "antd/dist/antd.min.css"
import "./assets/less/index.less";

import Routes from 'app.router';
import LocaleHeader from 'layouts/locale-header';
import { TranslateProvider } from 'hooks/useTranslate';
export default class App extends Component {
  render() {
    return (
      <>
        <LocaleHeader />
        <TranslateProvider >
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </TranslateProvider>
      </>
    );
  }
}
