import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Input, Button, Tooltip, Spin, Popconfirm } from 'antd';
import debounce from 'lodash.debounce';

import { useAppDispatch } from 'store';

import { DeleteOutlined, FormOutlined, UserOutlined } from '@ant-design/icons';
import useApi from 'hooks/useApi';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import Table from 'components/table';

import CreateRoleModel, { CreateRoleModelHandle } from './create-role.model';
import EditRoleModel, { EditRoleModelHandle } from './edit-role.model';

import './index.css';
import { deleteRoleRequest, getRolesRequest } from 'api/roles.api';
import { WithPermissions } from 'utils/with-permissions';
import usePermissions from 'hooks/usePermissions';
import { useParams } from 'react-router-dom';

const { Search } = Input;

export default function Layout(props: LayoutProps) {
    const dispatch = useAppDispatch();
    
    const { projectId } = useParams();
    
    const [roles, setRoles] = useState<any[]>([]);

    const [hasRoleCreatePermission] = usePermissions('permission-global:role:create');

    const [confirmDeleteRoleId, setConfirmDeleteRoleId] = useState<number>(-1);

    const createRoleModelRef = useRef<CreateRoleModelHandle>(null);
    const editRoleModelRef = useRef<EditRoleModelHandle>(null);

    const [{
        loading: loadingGetRoles,
        data: dataGetRoles,
        isSuccess: isSuccessGetRoles
    }, executeGetRolesApi] = useApi<any, any>([]);

    const [{
        loading: loadingDeleteRole,
        isSuccess: isSuccessDeleteRole,
    }, executeDeleteRoleApi] = useApi<any, any>();

    const debouncedSearch = useCallback(
        debounce(nextValue => {
            if (nextValue && nextValue.length > 2) {
                let params = { 'filter-key': nextValue }
                fetchRoles(params);
            }
            else {
                fetchRoles();
            }
        }, 500),
        [],
    );

    useEffect(() => {
        dispatch(setSectionHeadTitle("Roles Management"))
    }, [])

    useEffect(() => {
        fetchRoles();
    }, []);

    useEffect(() => {
        if (isSuccessGetRoles && Array.isArray(dataGetRoles)) {
            setRoles(dataGetRoles)
        }
    }, [isSuccessGetRoles, dataGetRoles]);

    useEffect(() => {
        if (isSuccessDeleteRole) {
            setConfirmDeleteRoleId(-1);
            fetchRoles();
        }
    }, [isSuccessDeleteRole]);

    function fetchRoles(params : any | void = undefined){
        let data = {
            ...params,
            'project-id' : projectId
        };
        executeGetRolesApi(getRolesRequest(data));
    }

    function onEditHandle(id: string) {
        editRoleModelRef.current?.openForEdit(id);
    }

    function onRoleDeleteHandle(id: string) {
        executeDeleteRoleApi(deleteRoleRequest(id))
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (text: any, record: any) => {
                let { permissions_count } = record;
                if (permissions_count && !isNaN(permissions_count)) {
                    if (permissions_count > 1) {
                        return <span>{permissions_count} permissions are attached</span>
                    }
                    else if (permissions_count === 1) {
                        return <span>{permissions_count} permission is attached</span>
                    }
                }
                return <span>no permissions are attached</span>
            },
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            render: (text: any, record: any) => (
                <div>
                    <WithPermissions permissions={'permission-global:role:edit'}>
                        <Tooltip title="Edit role" placement="bottom">
                            <Button
                                onClick={() => onEditHandle(record.id)}
                                icon={<FormOutlined />}
                                style={{ 'marginRight': '3%', }}
                            >
                            </Button>
                        </Tooltip>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-global:role:delete']}>
                            <Popconfirm
                                title="Delete role?"
                                visible={confirmDeleteRoleId === record.id}
                                onConfirm={() => onRoleDeleteHandle(record.id)}
                                okButtonProps={{ loading: loadingDeleteRole }}
                                onCancel={() => setConfirmDeleteRoleId(-1)}
                            >
                                <Tooltip title="Delete role" placement="bottom">
                                    <Button
                                        onClick={() => setConfirmDeleteRoleId(record.id)}
                                        icon={<DeleteOutlined />}
                                        style={{ 'marginRight': '3%', }}>

                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </WithPermissions>
                </ div>
            ),
        },
    ];

    return (
        <div>
            <Row justify="center" align="middle" >
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Row>
                        <Col span={24} >
                            <Search
                                placeholder="search roles"
                                onSearch={(value) => debouncedSearch(value)}
                                style={{ width: `calc(100% ${hasRoleCreatePermission ? ' - 36px' : null})` }}
                                allowClear={true}
                            />
                            {hasRoleCreatePermission && <div style={{ display: 'inline-block', width: '36px' }}>
                                <Button
                                    icon={<UserOutlined />}
                                    style={{ marginLeft: '5px' }}
                                    onClick={() => createRoleModelRef.current?.setVisible(true)}
                                >
                                </Button>
                            </div>}
                        </Col>
                    </Row>
                </Col>
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <div className="role-table-container" >
                        <Spin spinning={loadingGetRoles} >
                            <Table
                                columns={columns as any}
                                source={roles}
                                style={{ width: 'auto', 'overflowY': 'hidden' }}
                                rowKey="id"
                                size={'small'}
                                maxWidthPerCell={600}
                                height={400}
                                pagination={false}
                                className='roles-table'
                            />
                        </Spin>

                    </div>
                </Col>
            </Row>
            <CreateRoleModel
                ref={createRoleModelRef}
                onClose={() => {
                    fetchRoles();
                }}
            />
            <EditRoleModel
                ref={editRoleModelRef}
                onClose={() => {
                    fetchRoles();
                }}
            />
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}