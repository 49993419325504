import control from '../control'
export default class controlAggregateComponent extends control {

  static get definition() {
    return {
      mi18n: 'aggregate',
      type : 'aggregate',
      icon : '♌️',
      multiple: false,
    }
  }

  configure(){
    
  }

  build() {
    if(Array.isArray(this.config.values)){
      const head = this.markup('th', this.config.name.replace('-preview', ''))
      const eq = this.markup('td', '=')
      const items = []
      this.config.values.forEach((item, index) => {
        if(item.sign === '-'){
          if(index === 0){
            items.push(this.markup('td', `(${item.sign}`))
            items.push(this.markup('td', `${item.name})`))
          }
          else {
            items.push(this.markup('td', item.sign))
            items.push(this.markup('td', `(${item.name})`))
          }
        }
        else{
          if(index > 0) {
            items.push(this.markup('td', item.sign))
          }
          items.push(this.markup('td', item.name))
        }
      })

      const tbody = this.markup('tbody', [head, eq, ...items])
      return this.markup('table', [tbody], { className : 'variables'  })
    }

    return this.dom
  }

  onRender() { 
  }
  
  
}

control.register(['aggregate'], controlAggregateComponent)
