import { ApiContextState } from "axios-types"

export const publicUri = process.env.PUBLIC_URL as string;

export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = process.env.NODE_ENV === 'development';

export const isMockEnabled = (process.env.REACT_APP_API_IS_MOCK_ENABLED || 'false').toLowerCase() === 'true';

export const AuthToken = process.env.REACT_APP_AUTH_TOKEN

export const apiBaseUri = process.env.REACT_APP_API_BASE_URI

export const HttpConfig = { 
    baseURL : apiBaseUri,
    timeout : 999999,
    headers : {
        // 'Access-Control-Allow-Origin' : '*',
        // 'Access-Control-Allow-Credentials' : 'true',
        // 'Access-Control-Expose-Headers' : 'Access-Control-*',
        // 'Access-Control-Allow-Headers' : 'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Methods' : 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
        'Content-Type' : 'application/json'
    },
    isMockEnabled
} as ApiContextState



