import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Typography, Modal, Row, Col, Form, Input, Button, Spin, Result, Select, FormItemProps, Tooltip, Popconfirm, message } from 'antd';
import { useParams } from 'react-router-dom';
import SortableTable from 'components/table/sortable-table';
import { DeleteOutlined } from '@ant-design/icons';
import { useApi } from 'hooks';
import { createLocationRequest, deleteLocationRequest, getLocationRequest, getLocationsRequest, updateLocationRequest } from 'api/locations.api';

import './edit-project-location.model.css';

const { useForm } = Form;
const { Title } = Typography;
const { Option } = Select;

const Layout = React.forwardRef<EditProjectLocationModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const messageKey = 'location-command-key';

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isResultVisible, setIsResultVisible] = useState(false);

    const [form] = useForm();
    const { projectId } = useParams();

    const [location, setLocation] = useState<any>({});

    const [parentId, setParentId] = useState<number>(0);
    const [parentType, setParentType] = useState<string>();
    const [parents, setParents] = useState<any[]>([]);

    const [type, setType] = useState<string>();
    const [nestedType, setNestedType] = useState<string>();
    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [children, setChildren] = useState<any[]>([]);

    const [confirmDeleteLocationId, setConfirmDeleteLocationId] = useState<number>(-1);

    const [{
        loading: loadingCommand,
        isSuccess: isSuccessCommand,
        data: dataCommand,
        error: errorCommand
    }, executeCommandApi] = useApi<any, any>();

    const [{
        loading: loadingGetParentLocations,
        data: dataGetParentLocations,
        isSuccess: isSuccessGetParentLocations
    }, executeGetParentLocationsApi] = useApi<any, any>();

    const [{
        loading: loadingGetChildLocations,
        data: dataGetChildLocations,
        isSuccess: isSuccessGetChildLocations
    }, executeGetChildLocationsApi] = useApi<any, any>();

    const [{
        loading: loadingGetLocation,
        data: dataGetLocation,
        isSuccess: isSuccessGetLocation
    }, executeGetLocationApi] = useApi<any, any>();

    const [{
        loading: loadingDeleteLocation,
        isSuccess: isSuccessDeleteLocation
    }, executeDeleteLocationApi] = useApi<any, any>();

    useImperativeHandle(ref, () => ({
        openForCreate(_type: string) {
            setType(_type);
            setIsVisible(true);
            setIsCreation(true);
            setIsResultVisible(false);
            setParentId(0);
            form.resetFields();
        },
        openForCreateChild(_id: number, _type: string) {
            setParentId(_id);
            setParentType(_type);
            setIsVisible(true);
            setIsResultVisible(false);
            setIsCreation(true);
            setChildren([]);
            form.resetFields();

            form.setFields([
                {
                    name: 'parentId',
                    value: _id,
                },
            ]);
        },
        openForEdit(_id: any) {
            projectId && executeGetLocationApi(getLocationRequest(projectId, _id));
            setIsVisible(true);
            setIsCreation(false);
            setIsResultVisible(false);
            setParentId(0);
            form.resetFields();
        }
    }));

    useEffect(() => {
        if (isVisible === false) {
            onCloseCallback();
        }
    }, [isVisible]);

    useEffect(() => {
        switch (parentType) {
            case 'country': setType('region'); break;
            case 'region': setType('cluster'); break;
        }
    }, [parentType]);

    useEffect(() => {
        if (parentId > 0) {
            let params = { 'type-of-id': parentId };
            projectId && executeGetParentLocationsApi(getLocationsRequest(projectId, params));
        }
    }, [parentId]);

    useEffect(() => {
        switch (type) {
            case 'country': setNestedType('region'); break;
            case 'region': setNestedType('cluster'); break;
        }
    }, [type]);

    useEffect(() => {
        if (isSuccessGetLocation && dataGetLocation && dataGetLocation.id) {
            let { parentId, type } = dataGetLocation;
            setLocation(dataGetLocation);
            setType(type);

            if (parentId && parentId > 0) {
                setParentId(parentId);
            }

            form.setFieldsValue(dataGetLocation);
        }
    }, [isSuccessGetLocation, dataGetLocation]);

    useEffect(() => {
        if (isSuccessGetParentLocations && Array.isArray(dataGetParentLocations)) {
            setParents(dataGetParentLocations);
        }
    }, [isSuccessGetParentLocations, dataGetParentLocations]);

    useEffect(() => {
        if (isSuccessGetChildLocations && Array.isArray(dataGetChildLocations)) {
            setChildren(dataGetChildLocations);
        }
    }, [isSuccessGetChildLocations, dataGetChildLocations]);

    useEffect(() => {
        if (isSuccessCommand === true && dataCommand.id) {
            setIsResultVisible(true);
            message.success({ content: `${(isCreation ? 'Created successfully' : 'Updated successfully')}`, key: messageKey });
        }
        else if (isSuccessCommand === false) {
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [dataCommand, isSuccessCommand]);

    useEffect(() => {
        if (isSuccessDeleteLocation === true) {
            setConfirmDeleteLocationId(-1);
        }
    }, [isSuccessDeleteLocation]);

    useEffect(() => {
        if (location.id) {
            let params = { 'children-of-id': location.id };
            projectId && executeGetChildLocationsApi(getLocationsRequest(projectId, params));
        }
    }, [location]);

    function onDeleteHandle(id: string) {
        projectId && executeDeleteLocationApi(deleteLocationRequest(projectId, id));
    }

    function onFinish(values: any) {
        message.loading({ content: (isCreation ? 'Creating...' : 'Updating...'), key: messageKey });
        if (isCreation) {
            let data = {
                type,
                ...values,
            };
            projectId && executeCommandApi(createLocationRequest(projectId, data));
        }
        else {
            let data = {
                type,
                ...location,
                ...values,
            };
            projectId && executeCommandApi(updateLocationRequest(projectId, data));
        }
    }

    function onReOrdered(items : any[]){

    }
    
    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    const columns = [
        {
            title: "Name",
            key: 'name',
            dataIndex: "name",
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            overridenWidth: 12,
            render: (text: any, record: any) => (
                <div>
                    <Popconfirm
                        title="Delete location?"
                        visible={confirmDeleteLocationId === record.id}
                        onConfirm={() => onDeleteHandle(record.id)}
                        okButtonProps={{ loading: loadingDeleteLocation }}
                        onCancel={() => setConfirmDeleteLocationId(-1)}
                    >
                        <Tooltip title="Delete project" placement="bottom">
                            <Button
                                onClick={() => setConfirmDeleteLocationId(record.id)}
                                icon={<DeleteOutlined />}
                                style={{ 'marginRight': '3%', }}>
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </ div>
            ),
        },
    ];


    return (
        <Modal
            title={(isCreation ? `Create new ${type}` : `Update ${type}`)}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={1200}
        >
            {isResultVisible ? renderResult() : renderForm()}
        </Modal>
    );



    function renderForm() {
        let isSpinning = loadingCommand || loadingGetLocation;
        let prettyType = type && (type.charAt(0).toUpperCase() + type.slice(1));
        let prettyNestedType = nestedType && (nestedType.charAt(0).toUpperCase() + nestedType.slice(1));
        return (
            <Spin spinning={isSpinning} className={'location-edit'} >
                <Row className={'create-location-form'}>
                    <Col span={22} offset={2}>
                        <Form
                            name="basic"
                            form={form}
                            initialValues={{}}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            {(parentId > 0) && <Row >
                                <Col span={12} >
                                    <Form.Item
                                        label="Parent"
                                        name="parentId"
                                        {...formItemProps}
                                    >
                                        <Select
                                            showSearch
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            notFoundContent={null}
                                            loading={loadingGetParentLocations}
                                        >
                                            {parents.map((d: any) => (<Option
                                                key={d.id}
                                                value={d.id}
                                            >
                                                {d.name}
                                            </Option>))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>}
                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label={`${prettyType} Name`}
                                        name="name"
                                        rules={[{ required: true, message: 'Please input name!' }]}
                                        {...formItemProps}
                                        className={'capitalize-first'}
                                    >
                                        <Input placeholder='name' />
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    <Form.Item
                                        label={`${prettyType} Id`}
                                        name="locationId"
                                        rules={[{ required: true, message: 'Please input location id!' }]}
                                        {...formItemProps}
                                        className={'capitalize-first'}
                                    >
                                        <Input placeholder='location id' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {(!isCreation && (type ===  'country' || type ===  'region')) && <Row>
                                <Col span={22} >
                                    <Title level={5} style={{ 'fontWeight': 500, marginBottom: '2%' }} >
                                        {`${prettyNestedType}s incorporated with this location`}
                                    </Title>
                                    <Spin spinning={loadingGetChildLocations} >
                                        <SortableTable
                                            columns={columns}
                                            source={children}
                                            style={{ width: 'auto', 'overflowY': 'hidden' }}
                                            size={'small'}
                                            maxWidthPerCell={600}
                                            height={400}
                                            pagination={false}
                                            showHeader={false}
                                            className='locations-table'
                                            onFinish={onReOrdered}
                                        />
                                    </Spin>
                                </Col>
                            </Row>}

                            <Row>
                                <Col span={22} >
                                    <Form.Item style={{ 'marginTop': '3%', float: 'right' }}>
                                        <Button type="primary" htmlType="submit">
                                            {isCreation ? 'Create' : 'Update'}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        )
    }

    function renderResult() {
        return (
            <Result
                status="success"
                title={
                    <>
                        <h4>{`Location ${isCreation ? 'Created' : 'Updated'} Successfully`}</h4>
                    </>
                }
                extra={[
                    <div key={1}>
                        <Button key="back" onClick={() => setIsVisible(false)}>Close</Button>
                    </ div>,
                ]}
            />
        );
    }

})


export type EditProjectLocationModelHandle = {
    openForCreate: (type: string) => void,
    openForCreateChild: (id: number, type: string) => void,
    openForEdit: (id: number) => void,
}

Layout.propTypes = {
};

type LayoutProps = {
    onClose: () => void
}

export default Layout;