import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Input, Button, Popconfirm, Tooltip, Spin } from 'antd';
import { AppstoreAddOutlined, CopyOutlined, DeleteOutlined, EditOutlined, ExportOutlined, EyeOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import ExcelJS from "exceljs";

import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi, usePermissions, useTranslate } from 'hooks';
import Table from 'components/table';
import { cloneSurveyRequest, deleteSurveyRequest, exportKeysSurveyRequest, getSurveysRequest } from 'api/surveys.api';
import { WithPermissions } from 'utils/with-permissions';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';

const { Search } = Input;
export default function Layout(props: LayoutProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const currentUserProjectId = useSelector(getUserDefaultProjectId);

    const [hasSurveyCreatePermission] = usePermissions(['permission-global:survey:create', 'permission-project:survey:create']);

    const [surveys, setSurveys] = useState<any[]>([]);
    const [confirmDeleteSurveyId, setConfirmDeleteSurveyId] = useState<number>(-1);
    const [confirmCloneSurveyId, setConfirmCloneSurveyId] = useState<number>(-1);

    const debouncedSearch = useCallback(
        debounce(nextValue => {
            if (currentUserProjectId) {
                if (nextValue && nextValue.length > 2) {
                    let params = { 'filter-key': nextValue }
                    executeGetSurveysApi(getSurveysRequest(currentUserProjectId, params));
                }
                else {
                    executeGetSurveysApi(getSurveysRequest(currentUserProjectId));
                }
            }
        }, 500),
        [],
    );

    const [{
        loading: loadingGetSurveys,
        data: dataGetSurveys,
        isSuccess: isSuccessGetSurveys
    }, executeGetSurveysApi] = useApi<any, any>([]);

    const [{
        loading: loadingDeleteSurvey,
        isSuccess: isSuccessDeleteSurvey
    }, executeDeleteSurveyApi] = useApi<any, any>();

    const [{
        loading: loadingCloneSurvey,
        isSuccess: isSuccessCloneSurvey
    }, executeCloneSurveyApi] = useApi<any, any>();

    const [{
        loading: loadingExportKeysSurvey,
        isSuccess: isSuccessExportKeysSurvey,
        data: dataExportKeysSurvey
    }, executeExportKeysSurveyApi] = useApi<any, any>(null, true);

    useEffect(() => {
        dispatch(setSectionHeadTitle(translate('survey-management.header', "Surveys Management")));

        currentUserProjectId && executeGetSurveysApi(getSurveysRequest(currentUserProjectId));
    }, []);

    useEffect(() => {
        if (isSuccessGetSurveys && Array.isArray(dataGetSurveys)) {
            setSurveys(dataGetSurveys);
        }
    }, [isSuccessGetSurveys, dataGetSurveys]);

    useEffect(() => {
        if (isSuccessDeleteSurvey === true) {
            setConfirmDeleteSurveyId(-1);
            currentUserProjectId && executeGetSurveysApi(getSurveysRequest(currentUserProjectId));
        }
    }, [isSuccessDeleteSurvey]);

    useEffect(() => {
        if (isSuccessCloneSurvey === true) {
            setConfirmCloneSurveyId(-1);
            currentUserProjectId && executeGetSurveysApi(getSurveysRequest(currentUserProjectId));
        }
    }, [isSuccessCloneSurvey]);

    useEffect(() => {
        if (isSuccessExportKeysSurvey === true && dataExportKeysSurvey) {
            const url = window.URL.createObjectURL(dataExportKeysSurvey);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.xlsx'); 
            document.body.appendChild(link);
            link.click();
        }
    }, [isSuccessExportKeysSurvey, dataExportKeysSurvey]);

    function handleCreate() {
        navigate('/app/surveys-management/survey/create');
    }

    function handleView(surveyId: string) {
        surveyId && navigate(`/app/surveys-management/survey/view/${surveyId}`);
    }

    function handleEdit(surveyId: string) {
        surveyId && navigate(`/app/surveys-management/survey/edit/${surveyId}`);
    }

    function handleDelete(surveyId: string) {
        surveyId && currentUserProjectId && executeDeleteSurveyApi(deleteSurveyRequest(currentUserProjectId, surveyId));
    }

    function handleClone(surveyId: string) {
        surveyId && currentUserProjectId && executeCloneSurveyApi(cloneSurveyRequest(currentUserProjectId, surveyId));
    }

    function exportKeys(surveyId: string){
        surveyId && currentUserProjectId && executeExportKeysSurveyApi(exportKeysSurveyRequest(currentUserProjectId, surveyId));
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            overridenWidth: 200
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            overridenWidth: 100
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            overridenWidth: 70,
            render: (text: any, record: any) => (
                <div>
                    <WithPermissions permissions={['permission-project:survey:view']} >
                        <Tooltip title="View survey" placement="bottom" className='action' >
                            <Button
                                onClick={() => handleView(record.id)}
                                icon={<EyeOutlined />}
                                style={{ 'marginRight': '3%', }}
                            >
                            </Button>
                        </Tooltip>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:edit']} >
                        <Tooltip title="Edit survey" placement="bottom" className='action' >
                            <Button
                                onClick={() => handleEdit(record.id)}
                                icon={<EditOutlined />}
                                style={{ 'marginRight': '3%', }}
                            >
                            </Button>
                        </Tooltip>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:delete']} >
                        <Popconfirm
                            title="Delete survey?"
                            visible={confirmDeleteSurveyId === record.id}
                            onConfirm={() => handleDelete(record.id)}
                            okButtonProps={{ loading: loadingDeleteSurvey }}
                            onCancel={() => setConfirmDeleteSurveyId(-1)}
                        >
                            <Tooltip title="Delete survey" placement="bottom">
                                <Button
                                    onClick={() => setConfirmDeleteSurveyId(record.id)}
                                    icon={<DeleteOutlined />}
                                    style={{ 'marginRight': '3%', }}>

                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>

                    <WithPermissions permissions={['permission-project:survey:clone']} >
                        <Popconfirm
                            title="Clone survey?"
                            visible={confirmCloneSurveyId === record.id}
                            onConfirm={() => handleClone(record.id)}
                            okButtonProps={{ loading: loadingCloneSurvey }}
                            onCancel={() => setConfirmCloneSurveyId(-1)}
                        >
                            <Tooltip title="Clone survey" placement="bottom">
                                <Button
                                    onClick={() => setConfirmCloneSurveyId(record.id)}
                                    icon={<CopyOutlined />}
                                    style={{ 'marginRight': '3%', 'display': 'none'}}>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                    <Tooltip title="Export Keys" placement="bottom">
                        <Button
                            onClick={() => exportKeys(record.id)}
                            icon={<ExportOutlined />}
                            style={{ 'marginRight': '3%', }}>
                        </Button>
                    </Tooltip>
                </ div>
            ),
        },
    ];

    return (
        <div>
            <Row justify="center" align="middle" >
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Row>
                        <Col span={24} >
                            <Search
                                placeholder="search projects"
                                style={{ width: `calc(100% ${hasSurveyCreatePermission ? '- 36px' : null})` }}
                                onSearch={(value) => debouncedSearch(value)}
                                allowClear={true}
                            />
                            {hasSurveyCreatePermission && <div style={{ display: 'inline-block', width: '36px' }}>
                                <Button
                                    icon={<AppstoreAddOutlined />}
                                    style={{ marginLeft: '5px' }}
                                    onClick={handleCreate}
                                >
                                </Button>
                            </div>}
                        </Col>
                    </Row>
                </Col>
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Spin spinning={loadingGetSurveys || loadingExportKeysSurvey}>
                        <div className="table-container" >
                            <Table
                                columns={columns as any}
                                source={surveys}
                                style={{ width: 'auto', 'overflowY': 'hidden' }}
                                rowKey="id"
                                size={'small'}
                                maxWidthPerCell={600}
                                height={400}
                                pagination={{ pageSize: 7 }}
                                className='project-table'
                            />
                        </div>
                    </Spin>
                </Col>
            </Row>
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}
