import { ApiRequest } from "app-api";

export function getLocaleRequest(type : string) : ApiRequest<any>{
    let _type = 'en';
    if(type){
        _type = type
    }
    return {
        method : 'GET',
        url: `/v1/locale?source=w&type=${_type}`
    };
}

export function getLocaleTypesRequest() : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/locale/types`
    };
}

