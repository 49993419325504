/**
 * This function calculate the width of a string based on its length
 * @param {String} text
 * @param {String} font
 */
const getTextWidth = (text: any, font = "14px -apple-system") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    context.font = font;
    const metrics = context.measureText(text);
    return Math.round(metrics.width + 80);
};

function mapColumnsWidth(items : any[]){
    return items.map((column: any) => {
        let { title, width, children } = column;
        let calculatedWidth = width;
        if (typeof title === 'function') {
            calculatedWidth = width;
        }
        else {
            calculatedWidth = getTextWidth(title)
        }
        let updated = Object.assign(column, {
            width: calculatedWidth
        });
        if(children && Array.isArray(children)){
            updated.children = mapColumnsWidth(children);
        }
        return updated;
    });
};

function overrideColumnsWidth(items : any[], maxWidthPerCell : number, entry : any){
    return items.map((column: any, indexColumn: any) => {
        let { width: columnWidth, overridenWidth } = column;
        const cellValue = Object.values(entry)[indexColumn];

        if (overridenWidth) {
            items[indexColumn].width = overridenWidth;
        }
        else {
            // Get the string width based on chars length
            let cellWidth = getTextWidth(cellValue);
            // Verify if the cell value is smaller than column's width
            if (cellWidth < columnWidth) {
                cellWidth = columnWidth;
            }

            // Verify if the cell value width is bigger than our max width flag
            if (cellWidth > maxWidthPerCell) {
                cellWidth = maxWidthPerCell;
            }

            // Update the column width
            items[indexColumn].width = cellWidth;
        }

        return items;
    });
};

/**
 * This function calculates the width of each column based in all CELL VALUES
 * @param {Array} columns
 * @param {Array} source
 * @param {Number} maxWidthPerCell
 */
export const calculateColumnsWidth = (
    columns: any[],
    source: any,
    maxWidthPerCell = 500
) => {
    // const columnsParsed = JSON.parse(JSON.stringify(columns));

    // First we calculate the width for each column
    // The column width is based on its string length

    const columnsWithWidth = mapColumnsWidth(columns);

    // Since we have a minimum width (column's width already calculated),
    // now we are going to verify if the cell value is bigger
    // than the column width which is already set

    source.map((entry: any) => overrideColumnsWidth(columnsWithWidth, maxWidthPerCell, entry));

    // Sum of all columns width to determine the table max width
    const tableWidth = columnsWithWidth
        .map((column: any) => column.width)
        .reduce((a: number, b: number) => {
            return a + b;
        }, 0);

    return {
        columns: columnsWithWidth,
        source,
        tableWidth
    };
};