import React, { ReactNode, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { fetchIdentity } from 'reducers/thunks/permissions.thunk';
import { NavigateApp, NavigateChangePassword } from 'constants/paths';

export default function Layout({ children }: LayoutProps) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const { isAuthenticated, isPasswordChangeRequested } = useSelector((state : any) => state.auth);
    
    useEffect(() => {
        dispatch(fetchIdentity(undefined) as any)
    }, [dispatch])
    
    useEffect(() => {
        if(isPasswordChangeRequested){
            navigate(NavigateChangePassword, { replace: true })
        }
        else {
            navigate(NavigateApp)
        }
    }, [isPasswordChangeRequested])

    if (!isAuthenticated) {
        return <Navigate to="/" />
    }

    return <div><Outlet /></div>;
}

Layout.propTypes = {
    
};

type LayoutProps = {
    children?: ReactNode;
}