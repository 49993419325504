import React, { useEffect, useState } from 'react';
import { useApi, useTranslate } from 'hooks';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { getSurveyDataSheetRequest } from 'api/surveys.api';
import { Button, Col, List, message, Modal, Row, Skeleton, Space, Spin, Tooltip } from 'antd';
import { getProjectDatasheetsRequest } from 'api/projects.api';
import { FileDoneOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { CSVDownload, CSVLink } from "react-csv";
import './index.scss';

export default function Layout(props: LayoutProps) {

    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const { projects: currentUserProjects } = useSelector((state : any) => state.auth);

    const [currentProjectId, setCurrentProjectId] = useState<string>();
    const [surveys, setSurveys] = useState<any[]>([]);
    const [responses, setResponses] = useState<any[]>([]);
    const [surveyIdToDownload, setSurveyIdToDownload] = useState<any>(0);

    const [{
        loading: loadingGetSurveys,
        isSuccess: isSuccessGetSurveys,
        data: dataGetSurveys
    }, executeGetSurveysApi] = useApi<any, any>();

    const [{
        loading: loadingGetDataSheet,
        isSuccess: isSuccessGetDataSheet,
        data: dataGetDataSheet
    }, executeGetDataSheetApi] = useApi<any, any>();

    useEffect(() => {
        dispatch(setSectionHeadTitle(translate('dowoload-dataset.header', "Download datasets")));
    }, [])

    useEffect(() => {
        let [firstProject] = currentUserProjects;
        if (firstProject?.id) {
            setCurrentProjectId(firstProject?.id);
            executeGetSurveysApi(getProjectDatasheetsRequest(firstProject?.id));
        }
    }, [currentUserProjects]);

    useEffect(() => {
        if (isSuccessGetSurveys && Array.isArray(dataGetSurveys)) {
            const mappedData = dataGetSurveys.map(p => {
                return {
                    ...p,
                    isDisclaimerAccepted: false,
                    isDisclaimerMessageVisible: false
                }
            });
            setSurveys(mappedData);
        }
    }, [isSuccessGetSurveys, dataGetSurveys]);

    useEffect(() => {
        if (isSuccessGetDataSheet && Array.isArray(dataGetDataSheet)) {
            setResponses(dataGetDataSheet);

            if (dataGetDataSheet.length === 0) {
                message.info({
                    content: 'No responses to download!',
                });
            }
        }
    }, [isSuccessGetDataSheet, dataGetDataSheet]);

    function handleDownload(item: any) {
        setResponses([]);
        let { id } = item;
        if (currentProjectId && id) {
            executeGetDataSheetApi(getSurveyDataSheetRequest(currentProjectId, id));
        }
    }

    function toggleDisclaimerPopup(surveyId : Number){
        const updateSurveys = surveys.map(s => {
            let { id, isDisclaimerMessageVisible, isDisclaimerAccepted } = s;
            return {
                ...s,
                isDisclaimerMessageVisible: id === surveyId? !isDisclaimerMessageVisible : isDisclaimerMessageVisible,
            }
        });
        setSurveys(updateSurveys)
        setSurveyIdToDownload(surveyId);

    }

    const IconText = ({ icon, text, tooltip }: any) => (
        <Tooltip title={tooltip}>
            <Space>
                {React.createElement(icon)}
                {text}
            </Space>
        </Tooltip>
    );

    return <div>
        <Spin spinning={loadingGetSurveys || loadingGetDataSheet}>
            <Row className='download-dataset' >
                <Col span={20} offset={2}>
                    <List
                        loading={loadingGetSurveys}
                        itemLayout="vertical"
                        dataSource={surveys}
                        renderItem={renderItem}
                    />
                </Col>
            </Row>
        </Spin>
    </div>



    function renderItem(item: any) {
        let {
            id,
            name,
            description,
            householdsCount,
            participantsCount,
            responsesCount,
            disclaimerMessage,
            isDisclaimerMessageVisible,
            isDisclaimerAccepted
        } = item;
        return (<div>
            
            <List.Item
            actions={[
                <IconText icon={HomeOutlined} text={householdsCount} tooltip="Submitted Households" key="list-vertical-star-o" />,
                <IconText icon={UserOutlined} text={participantsCount} tooltip="Submitted Participants" key="list-vertical-like-o" />,
                <IconText icon={FileDoneOutlined} text={responsesCount} tooltip="Submitted Responses" key="list-vertical-message" />,
            ]}
            className='list-item'
        >
            <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                    title={name}
                    description={description}
                />
                <div>
                    {surveyIdToDownload == id && isDisclaimerAccepted && Array.isArray(responses) && responses.length > 0 && <CSVLink
                        data={responses}
                        filename={id + "-" + new Date().getTime()}
                        className='ant-btn ant-btn-default'
                        target="_blank"
                    >Download</CSVLink>}
                    {(surveyIdToDownload != id || !isDisclaimerAccepted) && <Button
                        onClick={() => {
                            toggleDisclaimerPopup(id);
                        }}
                    >
                        Read disclaimer message and download
                    </Button>}
                </div>
            </Skeleton>
            {isDisclaimerMessageVisible && <Modal
                title="Please accept data disclaimer message"
                visible={!!isDisclaimerMessageVisible}
                onOk={() => {
                    const updateSurveys = surveys.map(s => {
                        let { id: sId, sIsDisclaimerAccepted } = s;
                        return {
                            ...s,
                            isDisclaimerMessageVisible: false,
                            isDisclaimerAccepted: (id === sId) ? true : false
                        }
                    });
                    setSurveys(updateSurveys);
                    handleDownload(item);
                }}
                onCancel={() => toggleDisclaimerPopup(id)}
                okText="Accept"
                width={1000}
            >
                <p>{disclaimerMessage}</p>
            </Modal>}
        </List.Item></div>)
    }

}

Layout.propTypes = {

};

type LayoutProps = {

}