import { ApiRequest } from "app-api";
import { createQueryParams } from "utils/create-query-params";

export function getSurveyAvailabilityStatisticsRequest(projectId : string, data: any | void = null) : ApiRequest<any>{
    let url = `/v1/statistics/projects/${projectId}/survey-availability`;
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `${url}${params}`;
    }
    return {
        method: 'POST',
        url
    };
}

export function getModuleAvailabilityStatisticsRequest(projectId : string, data: any | void = null) : ApiRequest<any>{
    let url = `/v1/statistics/projects/${projectId}/module-availability`;
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `${url}${params}`;
    }
    return {
        method: 'POST',
        url
    };
}

export function getHouseholdRosterRequest(projectId : string, data: any | void = null) : ApiRequest<any>{
    let url = `/v1/statistics/projects/${projectId}/household-roster`;
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `${url}${params}`;
    }
    return {
        method: 'POST',
        url
    };
}

export function getParticipantsForDashboardFilterRequest(projectId : string): ApiRequest<any> {
    return {
        method: 'GET',
        url: `/v1/statistics/projects/${projectId}/participants`,
    };
}

