import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Row, Col, Form, Input, Button, Spin, Select, Modal, Result, FormItemProps, Tooltip } from 'antd';

import { getUsersRequest, searchUsersRequest } from 'api/users.api';
import Table from 'components/table';

import useApi from 'hooks/useApi';

import './edit-project.model.css';
import { createProjectRequest, getProjectRequest, updateProjectRequest } from 'api/projects.api';
import { CloseOutlined, DeleteOutlined, UserAddOutlined } from '@ant-design/icons';

const { useForm } = Form;
const { Option } = Select;
const { TextArea } = Input;

const Layout = React.forwardRef<EditProjectModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const [form] = useForm();
    const [columns, setColumns] = useState<any[]>([]);
    
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isResultVisible, setIsResultVisible] = useState(false);
    const [isCreation, setIsCreation] = useState<boolean>(true);

    const [project, setProject] = useState<any>({});

    const [regions, setRegions] = useState<any[]>([]);
    const [clusters, setClusters] = useState<any[]>([]);
    const [users, setUsers] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [candidateUsers, setCandidateUsers] = useState<any[]>([]);

    const [userFilterKey, setUserFilterKey] = useState<string>();
    const [userFilterKeyReadyToSend, setUserFilterKeyReadyToSend] = useState<string>();

    const [{
        loading: loadingGetProject,
        data: dataGetProject,
        isSuccess: isSuccessGetProject
    }, executeGetProjectApi] = useApi<any, any>();

    const [{
        data: dataGetLocations,
        isSuccess: isSuccessGetLocations
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    }, executeGetLocationsApi] = useApi<any, any>();

    const [{
        loading: loadingGetUsers,
        data: dataGetUsers,
        isSuccess: isSuccessGetUsers
    }, executeGetUsersApi] = useApi<any, any>([]);

    const [{
        loading: loadingCommandUser,
        isSuccess: isCommandSuccess
    }, executeCommandApi] = useApi<any, any>();

    useEffect(() => {
        if(isVisible){
            executeGetUsersApi(getUsersRequest());
        }
    }, [isVisible]);

    useEffect(() => {
        if (isSuccessGetProject && dataGetProject && dataGetProject.id) {
            let { users, ...rest } = dataGetProject;
            let data = {
                ...rest
            }
            setProject(dataGetProject);

            if(Array.isArray(users)){
                let ids = users.map(i => i.id);
                var filterd = candidateUsers
                .filter(i => ids.includes(i.id))
                .map(i => ({
                    ...i,
                    isSelected : true
                }));
                setSelectedUsers(filterd);
            }

            form.setFieldsValue(data);
        }
    }, [isSuccessGetProject]);

    useEffect(() => {
        if (isCommandSuccess) {
            setIsResultVisible(true);
        }
    }, [isCommandSuccess]);

    useEffect(() => {
        if (isSuccessGetUsers && Array.isArray(dataGetUsers)) {
            setUsers(dataGetUsers);
        }
    }, [isSuccessGetUsers]);

    useEffect(() => {
        if (Array.isArray(users) && Array.isArray(dataGetUsers)) {
            let selectedUserIds = selectedUsers.map(i => i.id);
            let filtered = dataGetUsers.filter(u => !selectedUserIds.includes(u.id));
            let all = [...filtered, ...selectedUsers];
            
            setCandidateUsers(all);
        }
    }, [users, selectedUsers]);

    useEffect(() => {
        if (isSuccessGetLocations && Array.isArray(dataGetLocations)) {
            let cs = dataGetLocations.filter(i => i.type === 'cluster');
            let rs = dataGetLocations.filter(i => i.type === 'region');
            setRegions(rs);
            setClusters(cs);
        }
    }, [isSuccessGetLocations, dataGetLocations]);

    useEffect(() => {
        if (userFilterKey) {
            if (userFilterKey.length > 2) {
                setUserFilterKeyReadyToSend(userFilterKey);
            }
        }
        else {
            setUserFilterKeyReadyToSend('');
        }
    }, [userFilterKey, users]);

    useEffect(() => {
        if (userFilterKeyReadyToSend) {
            if (userFilterKeyReadyToSend.length > 2) {
                let params = { 'filter-key': userFilterKeyReadyToSend }
                executeGetUsersApi(searchUsersRequest(params));
            }
        }
    }, [userFilterKeyReadyToSend]);
    
    const _columns = [
        {
            title: (text: any, record: any) => {
                return (
                    <div style={{ display: 'inline-block', width: '100%' }}>
                        <Input
                            placeholder="search"
                            value={userFilterKey}
                            style={{ width: 'calc(100% - 37px)', borderColor: '#d9d9d9' }}
                            onChange={e => {
                                const value = e?.target?.value || '';
                                setUserFilterKey(value);
                            }}
                        />
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<CloseOutlined />}
                            style={{ marginLeft: '5px' }}
                            onClick={() => onResetSearch()}
                        />
                    </div>
                );
            },
            dataIndex: '_key',
            key: '_key',
            children: [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    fixed: 'left'
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    render: (text: any, record: any) => `${record.firstName} ${record.lastName}`,
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: 'Roles',
                    dataIndex: 'roles',
                    key: 'roles',
                },
                {
                    title: 'Actions',
                    dataIndex: 'actions',
                    key: 'actions',
                    overridenWidth: 50,
                    render: (text: any, record: any) => {
                        let { isSelected } = record;
                        return (<div>
                            {!isSelected && <Tooltip title="Add user to project" placement="bottom">
                                <Button
                                    onClick={() => onUserAddHandle(record)}
                                    icon={<UserAddOutlined />}
                                    style={{ backgroundColor: '#8ae77e', 'marginRight': '3%', }}
                                >
                                </Button>
                            </Tooltip>}
                            {isSelected && <Tooltip title="Delete user from project" placement="bottom">
                                <Button
                                    onClick={() => onUserDeleteHandle(record)}
                                    icon={<DeleteOutlined />}
                                    style={{ backgroundColor: '#ff6868', 'marginRight': '3%', }}>
                                </Button>
                            </Tooltip>}
                        </ div>);
                    },
                },
            ]
        },
    ];

    useEffect(() => {
        setColumns(_columns);
    }, [selectedUsers, users, userFilterKey]);

    useImperativeHandle(ref, () => ({
        setVisible(state: boolean) {
            if (state) {
                setUsers([]);
                setSelectedUsers([]);
                setCandidateUsers([]);
                setIsVisible(state);
                form.resetFields();
                setIsCreation(true);
                setIsResultVisible(false)
            }
        },
        openForEdit(id: string) {
            if (id) {
                setUsers([]);
                form.resetFields();
                setSelectedUsers([]);
                setCandidateUsers([]);
                setIsCreation(false);
                setIsVisible(true);
                setIsResultVisible(false);
                executeGetProjectApi(getProjectRequest(id));
            }
        }
    }));

    function onUserAddHandle(user: any) {
        setSelectedUsers((items: any[]) => {
            return [...items, {...user, isSelected : true}]
        })
    }


    function onUserDeleteHandle(user: any) {
        setSelectedUsers((items: any[]) => {
            return [...items.filter(i => i.id !== user.id)];
        })
    }

    function onResetSearch() {
        setUserFilterKey('');
        executeGetUsersApi(getUsersRequest());
    }

    function onFinish(values: any) {
        if(isCreation){
            let data = {
                ...values,
                users : selectedUsers
            };
            executeCommandApi(createProjectRequest(data));
        }
        else{
            let data = {
                ...project,
                ...values,
                users : selectedUsers
            };
            executeCommandApi(updateProjectRequest(data));
        }
    }

    function hideDialog(){
        setIsVisible(false);
        typeof onCloseCallback === 'function' && onCloseCallback();
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    return (
        <Modal
            title={(isCreation? "Create New Project" : "Update Project")}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={hideDialog}
            footer={null}
            forceRender={true}
            width={1200}
            style={{ top: '20px' }}
        >
           {isResultVisible ? renderResult() : renderForm()}
        </Modal>
    );

    function renderForm() {
        let isSpinning = loadingGetProject || loadingCommandUser;
        return (
            <Spin spinning={isSpinning}>
                <Row className={'create-user-form'}>
                    <Col span={22} offset={1}>
                        <Form
                            name="basic"
                            form={form}
                            initialValues={{}}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Row>
                                <Col span={8} >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input project name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input placeholder='project name' />
                                    </Form.Item>
                                </Col>
                                <Col span={8} >
                                    <Form.Item
                                        label="Cluster"
                                        name="clusterId"
                                        rules={[{ required: true, message: 'Please input cluster!' }]}
                                        {...formItemProps}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder={'select cluster'}
                                            defaultActiveFirstOption={false}
                                        >
                                            {clusters.map((d: any) => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} >
                                    <Form.Item
                                        label="Region"
                                        name="regionId"
                                        rules={[{ required: true, message: 'Please input region!' }]}
                                        {...formItemProps}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder={'select region'}
                                            defaultActiveFirstOption={false}
                                        >
                                            {regions.map((d: any) => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={16} >
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        {...formItemProps}
                                        {...{ wrapperCol: { span: 22 } }}
                                    >
                                        <TextArea rows={2} placeholder='description' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className="table-container" >
                                {(() => {
                                    let sorted = [] as any;
                                    if (Array.isArray(candidateUsers)) {
                                        sorted = candidateUsers.sort((a: any, b: any) => a.username.localeCompare(b.username))
                                    }
                                    return (
                                        <Spin spinning={loadingGetUsers}>
                                            <Table
                                                columns={columns as any}
                                                source={sorted}
                                                style={{ width: 'auto', 'overflowY': 'hidden' }}
                                                rowKey="id"
                                                maxWidthPerCell={600}
                                                height={400}
                                                className='projetc-table'
                                                size={'small'}
                                                pagination={false}
                                                scroll={{ y: 200 }}
                                            />
                                        </Spin>
                                    );
                                })()}
                            </div>

                            <Form.Item  style={{ 'marginTop': '3%', float : 'right' }}>
                                <Button type="primary" htmlType="submit">
                                    {isCreation ? 'Create' : 'Update'}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>
            </Spin>
        )
    }

    function renderResult() {
        return (
            <Result
                status="success"
                title={
                    <>
                        <h4>{`Project ${isCreation ? 'Created' : 'Updated'} Successfully`}</h4>
                    </>
                }
                extra={[
                    <>
                        <Button key="back" onClick={hideDialog}>Close</Button>
                    </>,
                ]}
            />
        );
    }

})

Layout.propTypes = {

};

type LayoutProps = {
    onClose: () => void
}

export type EditProjectModelHandle = {
    setVisible: (state: boolean) => void,
    openForEdit: (id: string) => void,
}


export default Layout;