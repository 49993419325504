import React, { useEffect, useState } from 'react';

import { useAppDispatch } from 'store';
import jQuery from 'jquery';

import './index.css';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi, useTranslate } from 'hooks';
import { createSurveyModuleSectionQuestionGroupRequest, getSurveyModuleSectionQuestionGroupRequest, updateSurveyModuleSectionQuestionGroupRequest } from 'api/surveys.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Form, FormItemProps, Input, message, Row, Spin, Modal } from 'antd';
import WrappedFormBuilder from 'components/form-builder';

(window as any).jQuery = jQuery;
(window as any).$ = jQuery;

// setting content editables to strip html when data is pasted
$(document).off("paste", "div[contenteditable]");
$(document).on("paste", "div[contenteditable]", function(e) {
    e.preventDefault();
    
    // @ts-ignore
    var text = e.originalEvent?.clipboardData.getData('text/plain');
    document.execCommand("insertHTML", false, text);
});

const { useForm } = Form;
export default function Layout(props: LayoutProps) {
    const messageKey = 'survey-module-section-question-group-command-key';

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const translate = useTranslate();

    const { projects: currentUserProjects } = useSelector((state : any) => state.auth);
    const { surveyId, moduleId, sectionId, questionGroupId } = useParams();

    const [form] = useForm();

    const [questionGroup, setQuestionGroup] = useState<any>();
    const [currentProjectId, setCurrentProjectId] = useState<string>();
    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [questions, setQuestions] = useState<any>([]);
    const [validationPass, setValidationPass] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const [{
        loading: loadingGetQuestionGroup,
        data: dataGetQuestionGroup,
        isSuccess: isSuccessGetQuestionGroup
    }, executeGetQuestionGroupApi] = useApi<any, any>();

    const [{
        loading: loadingCommand,
        data: dataCommand,
        isSuccess: isSuccessCommand,
        error: errorCommand
    }, executeCommandApi] = useApi<any, any>([]);

    useEffect(() => {
        dispatch(setSectionHeadTitle(translate('survey-management.create.survey.module.section.questionnaire.header', "Create Questionnaire")));
    }, []);

    useEffect(() => {
        let [firstProject] = currentUserProjects;
        if (firstProject?.id) {
            setCurrentProjectId(firstProject?.id);
        }
    }, [currentUserProjects]);

    useEffect(() => {
        if (questionGroupId) {
            setIsCreation(false);
        }
    }, [questionGroupId]);

    useEffect(() => {
        if (currentProjectId && surveyId && moduleId && sectionId && questionGroupId) {
            executeGetQuestionGroupApi(getSurveyModuleSectionQuestionGroupRequest(currentProjectId, surveyId, moduleId, sectionId, questionGroupId));
        }
    }, [currentProjectId, surveyId, moduleId, sectionId, questionGroupId]);

    useEffect(() => {
        if (dataGetQuestionGroup?.id) {
            setIsCreation(false);
            setQuestionGroup(dataGetQuestionGroup);
            form.setFieldsValue(dataGetQuestionGroup);
            if (dataGetQuestionGroup?.questions && Array.isArray(dataGetQuestionGroup?.questions)) {
                let mapped_questions = dataGetQuestionGroup?.questions.map((q : any) => {
                    let { id, data } = q;
                    return {
                        data : {
                            referenceId : id,
                            ...data
                        }
                    }
                });
                setQuestions(mapped_questions);
            }
        }
    }, [isSuccessGetQuestionGroup, dataGetQuestionGroup]);

    useEffect(() => {
        if (isCreation) {
            if (isSuccessCommand === true && dataCommand.id) {
                navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${sectionId}`, { replace: true });
                message.success({ content: 'Created successfully', key: messageKey });
            }
            else if (isSuccessCommand === false && errorCommand) {
                message.error({ content: errorCommand, key: messageKey });
            }
        }
        else {

            if (isSuccessCommand === true && dataCommand.status) {
                navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${sectionId}`, { replace: true });
                message.success({ content: 'Updated successfully', key: messageKey });
            }
            else if (isSuccessCommand === true && !dataCommand.status) {
                setErrorMessages(dataCommand.messages);
                showModal();
            }
            else if (isSuccessCommand === false && errorCommand) {
                message.error({ content: errorCommand, key: messageKey });
            }

        }
    }, [dataCommand, isSuccessCommand, errorCommand]);

    async function onSaveHandle(data: any) {
        try {
            await form.validateFields();

            

            let formValues = form.getFieldsValue();
            let fieldLookup:any = {};
           
            const mappedQuestions = data.map((question : any) => {

                if (fieldLookup[question.referenceId]) {
                    question.referenceId = null;
                }
                else {
                    fieldLookup[question.referenceId] = question;
                }

                const { referenceId, ...rest } = question;
                return {
                    ...rest,
                    id : parseInt(referenceId) || -1
                };
            })

            let payload = {
                ...formValues,
                questions: mappedQuestions,
                id: questionGroup?.id,
            } as any;
            
            postData(payload, isCreation);
        }
        catch (e: any) {

        }
    }

    function postData(data: any, _isCreation: boolean) {
        if (currentProjectId && surveyId && moduleId && sectionId) {
            if (_isCreation) {
                let payload = createSurveyModuleSectionQuestionGroupRequest(currentProjectId, surveyId, moduleId, sectionId, data);
                executeCommandApi(payload);
            }
            else {

                let payload = updateSurveyModuleSectionQuestionGroupRequest(currentProjectId, surveyId, moduleId, sectionId, data);
                executeCommandApi(payload);
             
            }
        }
    }

    function handleOnDisplayLogic() {
        navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${moduleId}/sections/edit/${sectionId}/question-group/edit/${questionGroupId}/display-logic`);
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 24 }, lg: { span: 24 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    return (
        <Spin spinning={loadingCommand || loadingGetQuestionGroup}>
            <Row className={'edit-question-group-form'}>
                <Col span={24}>
                    <Form
                        name="basic"
                        form={form}
                        autoComplete="off"
                    >
                        <Row>
                            <Col span={20} >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please input name!' }]}
                                    {...formItemProps}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={4} >
                                <Row>
                                    <Col span={24} style={{ height: '32px' }}>
                                    </Col>
                                    <Col span={23}>
                                        <Button
                                            style={{ marginLeft: '10px', width: '100%' }}
                                            disabled={isCreation}
                                            onClick={handleOnDisplayLogic}
                                        >
                                            Display Logic
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <WrappedFormBuilder
                key={questionGroup?.id || 'new-question-group'}
                questions={questions}
                surveyId={surveyId as any}
                onSave={onSaveHandle}
            />
            <Modal width={'60%'} title="Cannot delete the following questions" visible={isModalOpen} onOk={handleOk} cancelButtonProps={{style: {display:"none"}}}>
                <div>

                    <ul>
                        {errorMessages.map((errorMessage, index) => (
                            <li key={index}>{errorMessage}</li>
                        ))}
                    </ul>

                </div>
            </Modal>
        </Spin>
        
    );

}

Layout.propTypes = {

};

type LayoutProps = {

}