import { ApiRequest } from "app-api";
import { GetPermissionRequest, SignInRequest } from "app-auth";

export function createSignInRequest(data : SignInRequest) : ApiRequest<SignInRequest>{
    return {
        method : 'POST',
        url: '/v1/auth/signin',
        data : data
    };
}

export function createIdentityRequest() : ApiRequest<GetPermissionRequest>{
    return {
        method : 'GET',
        url: '/v1/auth/identity'
    };
}

export function createPasswordChangeRequest(data : any) : ApiRequest<any>{
    return {
        method : 'PUT',
        url: '/v1/auth/change-password',
        data : data
    };
}

export function createPasswordResetRequest(data : any) : ApiRequest<any>{
    return {
        method : 'PATCH',
        url: '/v1/auth/reset-password',
        data : data
    };
}

export function createRequestPasswordChangeRequest(data : any) : ApiRequest<any>{
    return {
        method : 'POST',
        url: '/v1/auth/request-password-change',
        data : data
    };
}