import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Input, Button, Popconfirm, Tooltip, Spin, Switch, message } from 'antd';
import { DeleteOutlined, FormOutlined, RedoOutlined, UserOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

import { useAppDispatch } from 'store';
import { deleteUserRequest, getUsersRequest, updateUserPermissionRequest } from 'api/users.api';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { WithPermissions } from 'utils/with-permissions';
import { createRequestPasswordChangeRequest } from 'api/auth.api';
import { useTranslate } from 'hooks';
import { getPermissionsRequest } from 'api/permissions.api';
import EditUserModel, { EditUserModelHandle } from './edit-user.model';
import Table from 'components/table';
import useApi from 'hooks/useApi';
import usePermissions from 'hooks/usePermissions';

import './index.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const { Search } = Input;

export default function Layout(props: LayoutProps) {
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const { projectId } = useParams();
    const { projects : userProjects } = useSelector((state : any) => state.auth);

    const [loadingKey, setLoadingKey] = useState<string>();

    const [users, setUsers] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [permissions, setPermissions] = useState<any>([]);

    const [usersWithPermissions, setUsersWithPermissions] = useState<any[]>([]);

    const [confirmResetPasswordId, setConfirmResetPasswordId] = useState<number>(-1);
    const [confirmDeleteUserId, setConfirmDeleteUserId] = useState<number>(-1);

    const [hasUserCreatePermission] = usePermissions(['permission-global:user:create', 'permission-project:user:create']);
    const [hasUserAssignPermission] = usePermissions('permission-global:user:assign');

    const editUserModelRef = useRef<EditUserModelHandle>(null);

    const [{
        loading: loadingGetUsers,
        data: dataGetUsers,
        isSuccess: isSuccessGetUsers
    }, executeGetUsersApi] = useApi<any, any>([]);

    const [{
        loading: loadingRequestPasswordChange,
        isSuccess: isSuccessRequestPasswordChange
    }, executeRequestPasswordChangeApi] = useApi<any, any>();

    const [{
        loading: loadingDeleteUser,
        isSuccess: isSuccessDeleteUser
    }, executeDeleteUserApi] = useApi<any, any>();

    const [{
        loading: loadingGetPermissions,
        data: dataGetPermissions,
        isSuccess: isGetPermissionsSuccess,
    }, executeGetPermissionsApi] = useApi<any, any>([]);

    const [{
        loading: loadingUpdateUserPermission,
        isSuccess: isUpdateUserPermissionSuccess,
    }, executeUpdateUserPermissionApi] = useApi<any, any>([]);

    const debouncedUserSearch = useCallback(
        debounce(nextValue => {
            if (nextValue && nextValue.length > 2) {
                let params = { 'filter-key': nextValue };
                fetchUsers(params);
            }
            else {
                fetchUsers();
            }
        }, 500),
        [],
    );

    const debouncedPermissionUpdate = useCallback(
        debounce(nextValue => {
            let { userId , permissionId, value } = nextValue;
            executeUpdateUserPermissionApi(updateUserPermissionRequest(userId, permissionId, value));
            let key = `${userId}-${permissionId}-${value}`;
            message.loading({ content: 'Updating...', key });
            setLoadingKey(key);
        }, 200),
        [],
    );

    useEffect(() => {
        if(projectId){
            dispatch(setSectionHeadTitle(translate('project-user-management.header', "Manage Project Users")));
        }
        else{
            dispatch(setSectionHeadTitle(translate('user-management.header', "Users Management")));
        }
    }, [projectId])

    useEffect(() => {
        fetchUsers();
        executeGetPermissionsApi(getPermissionsRequest());
    }, []);

    useEffect(() => {
        if (isSuccessGetUsers && Array.isArray(dataGetUsers)) {
            setUsers(dataGetUsers)
        }
    }, [isSuccessGetUsers]);

    useEffect(() => {
        if (isSuccessRequestPasswordChange) {
            setConfirmResetPasswordId(-1)
        }
    }, [isSuccessRequestPasswordChange]);

    useEffect(() => {
        if (isSuccessDeleteUser) {
            setConfirmDeleteUserId(-1);
            fetchUsers();
        }
    }, [isSuccessDeleteUser]);

    useEffect(() => {
        if (isGetPermissionsSuccess && Array.isArray(dataGetPermissions)) {
            setPermissions(dataGetPermissions);
        }
    }, [dataGetPermissions, isGetPermissionsSuccess]);
    
    useEffect(() => {
        if (isUpdateUserPermissionSuccess === true) {
            message.success({ content: 'User permission updated!', key : loadingKey });
        }
        else if(isUpdateUserPermissionSuccess === false) {
            message.error({ content: 'User permission update failed!', key : loadingKey });
        }
    }, [isUpdateUserPermissionSuccess, loadingKey]);

    useEffect(() => {
        if (Array.isArray(users) && Array.isArray(permissions)) {
            let updated = users.map((u: any) => {
                let userPermissions = u.permissions || [];
                let selectedIds = userPermissions.map((i: any) => i.id);
                let keys = permissions.filter(i => !selectedIds.includes(i.id));
                let enabledKeysObject = userPermissions.reduce((pv: any, cv: any) => ({ ...pv, [cv.code]: true }), {})
                let notEnabledKeysObject = keys.reduce((pv: any, cv: any) => ({ ...pv, [cv.code]: false }), {})
                return {
                    ...u,
                    ...enabledKeysObject,
                    ...notEnabledKeysObject
                }
            });
            setUsersWithPermissions(_ => updated);
        }
    }, [users, permissions]);

    useEffect(() => {
        let columnsPart1 = [
            {
                title: 'Username',
                dataIndex: 'username',
                key: 'username',
                fixed: 'left'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text: any, record: any) => `${record.firstName} ${record.lastName}`,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            }
        ] as any[];

        if (hasUserAssignPermission) {
            columnsPart1.push({
                title: 'Projects',
                dataIndex: 'projects',
                key: 'projects'
            });
        }

        columnsPart1.push({
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
        });

        let permissionChildren: any[] = permissions
        .filter((i : any) => {
            if(hasUserAssignPermission){
                return true;
            }
            else {
                let { code } = i;
                return code?.startsWith('permission-project');
            }
        })
        .map((permission: any) => {
            let { code, description } = permission;
            return {
                title: description,
                key: code,
                render: (text: any, record: any) => {
                    let isExists = record[code];
                    return <div style={{ textAlign: 'center' }}>
                        <Switch
                            defaultChecked={false}
                            checked={isExists}
                            onChange={value => onPermissionChanged(record, permission, value)}
                        ></Switch>
                    </div>;
                }
            }
        });

        permissionChildren.sort((a, b) => a.key.localeCompare(b.key))

        let permissionsColumn = [
            {
                title: 'Permissions',
                children: permissionChildren
            }
        ];

        let actionColumns = [
            {
                title: 'Actions',
                key: 'key',
                dataIndex: 'key',
                align: 'center' as 'center',
                fixed: 'right',
                overridenWidth: 120,
                render: (text: any, record: any) => (
                    <div>
                        <WithPermissions permissions={['permission-global:user:edit', 'permission-project:user:edit']}>
                            <Tooltip title="Edit user" placement="bottom">
                                <Button
                                    onClick={() => onUserEditHandle(record.id)}
                                    icon={<FormOutlined />}
                                    style={{ 'marginRight': '3%', }}
                                >
                                </Button>
                            </Tooltip>
                        </WithPermissions>
                        <WithPermissions permissions={['permission-global:user:delete', 'permission-project:user:delete']}>
                            <Popconfirm
                                title="Delete user?"
                                visible={confirmDeleteUserId === record.id}
                                onConfirm={() => onUserDeleteHandle(record.id)}
                                okButtonProps={{ loading: loadingDeleteUser }}
                                onCancel={() => setConfirmDeleteUserId(-1)}
                            >
                                <Tooltip title="Delete user" placement="bottom">
                                    <Button
                                        onClick={() => setConfirmDeleteUserId(record.id)}
                                        icon={<DeleteOutlined />}
                                        style={{ 'marginRight': '3%', }}>

                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </WithPermissions>
                        <WithPermissions permissions={'permission-global:user:reset-password'}>
                            <Popconfirm
                                title="Request password change?"
                                visible={confirmResetPasswordId === record.id}
                                onConfirm={() => requestPasswordChangeHandle(record.id, projectId)}
                                okButtonProps={{ loading: loadingRequestPasswordChange }}
                                onCancel={() => setConfirmResetPasswordId(-1)}
                            >
                                <Tooltip title="Request password change" placement="bottom">
                                    <Button
                                        onClick={() => setConfirmResetPasswordId(record.id)}
                                        icon={<RedoOutlined />}>
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </WithPermissions>
                    </ div>
                ),
            },
        ];

        let data = [...columnsPart1, ...permissionsColumn, ...actionColumns];
        setColumns(data);
    }, [users, permissions, confirmResetPasswordId, confirmDeleteUserId, hasUserAssignPermission]);

    function fetchUsers(params : any | void = undefined){
        let data = {
            ...params
        };
        if(projectId){
            data['project-id'] = projectId;
        }
        else {
            let [ firstProject ] = userProjects;
            data['project-id'] = firstProject?.id;
        }
        
        executeGetUsersApi(getUsersRequest(data));
    }

    function onUserEditHandle(id: string) {
        editUserModelRef.current?.openForEdit(id);
    }

    function onUserDeleteHandle(id: string) {
        executeDeleteUserApi(deleteUserRequest(id))
    }

    function requestPasswordChangeHandle(id: string, project_id: any) {
        executeRequestPasswordChangeApi(createRequestPasswordChangeRequest({ id, project_id }))
    }

    function onPermissionChanged(user :any, permission : any, value: boolean) {  
        let { id : userId } = user;
        let { id : permissionId, code } = permission;
        setUsersWithPermissions((items : any[]) => {
            let updated = items.map(item => {
                let { id } = item;
                if(id === userId){
                    return {
                        ...item,
                        [code] : value
                    }
                }
                return item;
            })
            return updated;
        });
          
        setTimeout(() => {
            debouncedPermissionUpdate({ userId , permissionId, value });
        }, 1)
    }

    return (
        <div>
            <Row justify="center" align="middle" >
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Row>
                        <Col span={24} >
                            <Search
                                placeholder="search users"
                                onSearch={(value) => debouncedUserSearch(value)}
                                style={{ width: `calc(100% ${hasUserCreatePermission ? '- 36px' : null})` }}
                                allowClear={true}
                            />
                            {hasUserCreatePermission && <div style={{ display: 'inline-block', width: '36px' }}>
                                <Button
                                    icon={<UserOutlined />}
                                    style={{ marginLeft: '5px' }}
                                    onClick={() => editUserModelRef.current?.setVisible(true)}
                                >
                                </Button>
                            </div>}
                        </Col>
                    </Row>
                </Col>
                <Col span={23} style={{ 'marginTop': '1%' }}>
                    <Spin spinning={loadingGetUsers || loadingGetPermissions || loadingUpdateUserPermission}>
                        <div className="user-table-container" >
                            <Table
                                columns={columns as any}
                                source={usersWithPermissions}
                                rowKey="id"
                                size={'small'}
                                maxWidthPerCell={600}
                                height={400}
                                pagination={false}
                                className='users-table'
                            />
                        </div>
                    </Spin>
                </Col>
            </Row>
            <EditUserModel ref={editUserModelRef} onClose={() => { fetchUsers() }} />
        </div>
    );
}

Layout.propTypes = {

};

type LayoutProps = {

}