import React, { useEffect, useState, useImperativeHandle } from 'react';
import { useAppDispatch } from 'store';
import { useApi, useTranslate } from 'hooks';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';
import { Button, Col, Input, List, Row, Select, Spin, DatePicker, Empty, message, Form, Switch, Tooltip } from 'antd';

import { getSurveyModulesForDisplayLogicRequest } from 'api/surveys.api';
import { setSectionHeadTitle } from 'reducers/system.reducer';

import './index.css';
import { BranchesOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

const { useForm } = Form;
const Layout = React.forwardRef<DisplayLogicHandle, LayoutProps>(({ onSave, existingRule, isLoading }, ref) => {
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const { surveyId, moduleId } = useParams();
    const currentUserProjectId = useSelector(getUserDefaultProjectId) as string;

    const [form] = useForm();
    const [rules, setRules] = useState<any[]>([]);
    const [candidateModules, setCandidateModules] = useState<any[]>([]);
    const [defaultAccessibility, setDefaultAccessibility] = useState<boolean>(true);

    const [{
        loading: loadingGetModules,
        data: dataGetModules,
        isSuccess: isSuccessGetModules
    }, executeGetModulesApi] = useApi<any, any>();

    useEffect(() => {
        dispatch(setSectionHeadTitle(translate('survey-management.edit.survey.module.display-logic.header', " ")));
    }, []);

    useEffect(() => {
        if (existingRule && candidateModules.length > 0) {
            let components = existingRule?.components;
            if (Array.isArray(components)) {
                let enrichedComponents = components
                    .map((c: any) => {
                        let { type } = c;
                        if (type === 'operator') {
                            return c;
                        }
                        let enriched = {
                            ...c,
                            modules: candidateModules,
                        };
                        let selectedModule = candidateModules.find(i => i.id === c.module?.id);
                        if (selectedModule) {
                            enriched.module = selectedModule;
                            let { sections } = selectedModule;
                            if (Array.isArray(sections)) {
                                let selectedSection = sections.find(i => i.id === c.section?.id);
                                if (selectedSection) {
                                    enriched.section = selectedSection;
                                    let { questions } = selectedSection;
                                    if (Array.isArray(questions)) {
                                        let selectedQuestion = questions.find(i => i.id === c.question?.id);
                                        if (selectedQuestion) {
                                            enriched.question = selectedQuestion;
                                        }
                                        else {
                                            enriched.question = undefined;
                                            form.setFields([
                                                {
                                                    name: `${c?.id}_m${selectedModule?.id}_s${selectedSection?.id}_q`,
                                                    errors: ['question has been changed!'],
                                                }
                                            ]);
                                        }
                                    }
                                }
                                else {
                                    form.setFields([
                                        {
                                            name: `${c?.id}_m${selectedModule?.id}_s${selectedSection?.id}`,
                                            errors: ['question has been changed!'],
                                        }
                                    ]);
                                }
                            }
                        }
                        else {
                            form.setFields([
                                {
                                    name: `${c?.id}_m${selectedModule?.id}`,
                                    errors: ['question has been changed!'],
                                }
                            ]);
                        }
                        return enriched;
                    })
                    .sort(function (a, b) {
                        return a.index - b.index;
                    });
                setRules(enrichedComponents);
            }
        }
    }, [candidateModules, existingRule]);

    useEffect(() => {
        if (isSuccessGetModules && Array.isArray(dataGetModules)) {
            setCandidateModules(dataGetModules);
        }
    }, [isSuccessGetModules, dataGetModules]);

    useEffect(() => {
        if (currentUserProjectId && surveyId && moduleId) {
            let params = { 'prior-to-module': moduleId } as any;
            let payload = getSurveyModulesForDisplayLogicRequest(currentUserProjectId, surveyId, params);
            executeGetModulesApi(payload);
        }
    }, [currentUserProjectId, surveyId, moduleId]);

    useImperativeHandle(ref, () => ({
        onOpen() {
        }
    }));

    function handleAddRule() {
        let indexes = rules.length > 0 ? rules.map((i: any) => i.index) : [1];
        let nextIndex = Math.max(...indexes) + 1;
        let ids = rules.length > 0 ? rules.map((i: any) => i.id) : [1];
        let nextId = Math.max(...ids) + 1;

        setRules((i: any) => {
            if (i.length === 0) {
                let rule = {
                    type: 'rule',
                    index: nextIndex,
                    id: nextId,
                    modules: [...candidateModules],
                    enabled: !defaultAccessibility
                };
                return [...i, rule];
            }

            let operator = {
                type: 'operator',
                index: nextIndex,
                id: nextId,
                operation: 'or'
            }

            let rule = {
                type: 'rule',
                index: nextIndex + 1,
                id: nextId + 1,
                modules: [...candidateModules],
                enabled: !defaultAccessibility
            };

            return [...i, operator, rule];
        });
    }

    function handleModuleSelect(ruleId: any, selectedModuleId: any) {
        if (Array.isArray(candidateModules)) {
            let selectedModule = candidateModules.find((i: any) => i.id === selectedModuleId);
            setRules((items: any) => {
                return items.map((item: any) => {
                    let { id } = item;
                    if (id !== ruleId) {
                        return item;
                    }
                    return {
                        ...item,
                        module: selectedModule,
                        section: undefined,
                        question: undefined,
                        value: undefined
                    };
                });
            });

            form.setFields([
                {
                    name: `${ruleId}_m`,
                    errors: [],
                }
            ]);
        }
    }

    function handleSectionSelect(rule: any, selectedSectionId: any) {
        let { id: ruleId, module, module: { sections } } = rule;
        if (Array.isArray(sections)) {
            let selectedSection = sections.find((i: any) => i.id === selectedSectionId);

            setRules((items: any) => {
                return items.map((item: any) => {
                    let { id } = item;
                    if (id !== ruleId) {
                        return item;
                    }
                    return {
                        ...item,
                        section: selectedSection,
                        question: undefined,
                        value: undefined
                    };
                });
            });

            form.setFields([
                {
                    name: `${ruleId}_m${module?.id}_s`,
                    errors: [],
                }
            ]);
        }
    }

    function handleQuestionSelect(rule: any, selectedQuestionId: any) {
        let { id: ruleId, module, section, section: { questions } } = rule;
        if (Array.isArray(questions)) {
            let selectedQuestion = questions.find((i: any) => i.id === selectedQuestionId);

            setRules((items: any) => {
                return items.map((item: any) => {
                    let { id } = item;
                    if (id !== ruleId) {
                        return item;
                    }
                    return {
                        ...item,
                        question: selectedQuestion,
                        value: undefined
                    };
                });
            });

            form.setFields([
                {
                    name: `${ruleId}_m${module?.id}_s${section?.id}_q`,
                    errors: [],
                }
            ]);
        }
    }

    function handleOperatorChange(value: any, operator: any, index: number) {
        setRules((items: any) => {
            return items.map((item: any, _index: number) => {
                // eslint-disable-next-line eqeqeq
                if (index != _index) {
                    return item;
                }
                return {
                    ...item,
                    operation: value
                }
            });
        });
    }

    function removeAdditionalOperators(items: any) {
        let elementsToRemove: number[] = [];
        // check and remove operators that are together
        for (var i = 0; i < items.length; i++) {
            if (i == 0)
                continue;

            if (items[i - 1].type == "operator" && items[i].type == "operator") {
                elementsToRemove.push(i);
            }
        }

        if (items.length > 0 && items[items.length - 1].type == "operator")
            elementsToRemove.push(items.length - 1)

        return items.filter((i: any, index: number) => !elementsToRemove.includes(index));
    }

    function handleOnDelete(rule: any, index: number) {

        setRules((items: any) => {

            let temp = [...items];
            temp.splice(index - 1, 2);
            temp = removeAdditionalOperators(temp); 

            return temp;
        });
    }

    function handleOnExtend(position: number) {
        let index = rules.reduce((prev: any, current: any) => (prev.index > current.index) ? prev.index : current.index, 0) + 1;
        let ids = rules.length > 0 ? rules.map((i: any) => i.id) : [1];
        let nextId = Math.max(...ids) + 1;

        let operator = {
            type: 'operator',
            index: index,
            id: nextId,
            operation: 'or'
        }

        let rule = {
            type: 'rule',
            index: index,
            id: nextId + 1,
            modules: [...candidateModules]
        };

        setRules((items: any) => {
            let temp = [...items];
            temp.splice(position + 1, 0, operator, rule);
            return temp.map((j: any, jIndex: number) => {
                return {
                    ...j,
                    index: jIndex
                };
            });
        });
    }

    function handleOnSave() {
        if (Array.isArray(rules)) {
            let hasInvalidRules = rules
                .filter((r => r.type === 'rule'))
                .some((rule: any) => {
                    let { id, module, section, question, value } = rule;
                    let exceptions = [];
                    if (!module) {
                        exceptions.push({
                            name: `${id}_m`,
                            errors: ['module not selected'],
                        });
                    }
                    else if (!section) {
                        exceptions.push({
                            name: `${id}_m${module?.id}_s`,
                            errors: ['section not selected'],
                        });
                    }
                    else if (!question) {
                        exceptions.push({
                            name: `${id}_m${module?.id}_s${section?.id}_q`,
                            errors: ['question not selected'],
                        });
                    }
                    else if (!value) {
                        exceptions.push({
                            name: `${id}_m${module?.id}_s${section?.id}_q${question?.id}_a`,
                            errors: ['invalid rule value'],
                        });
                    }
                    form.setFields(exceptions);
                    return exceptions.length > 0;
                });

            if (hasInvalidRules) {
                message.error({ content: 'One or more rules are invalid!' });
            }
            else {
                let data = {
                    defaultAccessibility,
                    components: rules.map((i: any, index: number) => {
                        const clearedComponent = _.omit(i, 'modules', 'module.sections', 'section.questions');
                        return {
                            ...clearedComponent,
                            index
                        }
                    })
                };
                onSave(data);
            }
        }
    }

    function handleOnValue(type: string, rule: any, value: any) {
        setRules((items: any) => {
            let temp = [...items];
            return temp.map((j: any, jIndex: number) => {
                if (rule.id === j.id) {
                    return {
                        ...j,
                        value
                    };
                }
                return j;
            });
        });
        form.setFields([
            {
                name: `${rule?.id}_m${rule?.module?.id}_s${rule?.module?.id}_q${rule?.question?.id}_a`,
                errors: [],
            }
        ]);
    }

    function handleOnValueAttribute(attribute: string, type: string, rule: any, value: any, meta: any = null) {
        setRules((items: any) => {
            let temp = [...items];
            return temp.map((j: AnyProperties) => {
                let { value: currentValue } = j;
                if (rule.id === j.id) {
                    return {
                        ...j,
                        value: {
                            ...currentValue,
                            [attribute]: value,
                        },
                        ...meta
                    };
                }
                return j;
            });
        });
    }

    let hasRules = (Array.isArray(rules) && rules.length > 0);

    return (
        <div className='display-logic'>
            <Spin spinning={loadingGetModules || isLoading}>
                <Row>
                    <Col span={22} offset={1}>
                        <div style={{ display: 'inline-block', 'float': 'right', marginBottom: '10px' }} >
                            <Tooltip title={`Default accessibility rule for module - ${(defaultAccessibility ? 'enabled' : 'disabled')}`}>
                                <label>
                                    Default Accessibility
                                    <Switch
                                        checkedChildren="Enabled"
                                        unCheckedChildren="Disabled"
                                        onChange={(value: boolean) => {
                                            setDefaultAccessibility(value);
                                        }}
                                        defaultChecked
                                        style={{ margin: '0px 10px', minWidth: '85px' }}
                                    />
                                </label>
                            </Tooltip>
                            {(hasRules) && <Button
                                onClick={handleAddRule}
                                icon={<PlusOutlined />}
                            >
                                Add Rule
                            </Button>
                            }
                            <Button
                                style={{ marginLeft: '10px' }}
                                onClick={handleOnSave}
                                icon={<SaveOutlined />}
                                type="primary"
                            >
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={22} offset={1}>
                        <Row>
                            <Col span={24}>
                                {(hasRules) && <Form
                                    name="display_logic"
                                    form={form}
                                >
                                    <div className='rule-operator' style={{ padding: '20px 10px' }}>
                                        <hr className='rule-operator-separator' />
                                        <Select disabled value={'('} >
                                            <Select.Option value={'('}>&#40;</Select.Option>
                                        </Select>
                                    </div>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={rules}
                                        renderItem={(item: any, index: number) => renderItem(item, index)}
                                    />
                                    <div className='rule-operator' style={{ padding: '20px 10px' }}>
                                        <hr className='rule-operator-separator' />
                                        <Select disabled value={')'} >
                                            <Select.Option value={')'}>&#41;</Select.Option>
                                        </Select>
                                    </div>
                                </Form>}
                                {(!hasRules) && <Empty
                                    imageStyle={{
                                        height: 60,
                                    }}
                                    description={
                                        <>
                                            <h2>
                                                Empty display logic
                                            </h2>
                                        </>
                                    }
                                >
                                    <Button
                                        onClick={handleAddRule}
                                        icon={<PlusOutlined />}
                                        type='primary'
                                    >
                                        Create First Rule
                                    </Button>
                                </Empty>}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </div>
    );

    function renderItem(item: any, index: number) {
        let { type } = item;
        switch (type) {
            case 'rule': {
                return renderRule(item, index);
            }
            case 'operator': {
                return renderOperator(item, index);
            }
            default:
                return (<></>);
        }
    }

    function renderRule(rule: any, index: number) {
        return (
            <div className='display-logic-rule'>
                <Row>
                    <Col span={23}>
                        <span className='item'>In</span>
                        <div className='item'>{renderModuleSelect(rule)}</div>
                        <div className='item'>{renderSectionSelect(rule)}</div>
                        <span className='item'>If</span>
                        <div className='item'>{renderQuestionSelect(rule)}</div>
                        <span className='item'>Answer is</span>
                        <div className='item'>{renderAnswerLayout(rule)}</div>
                    </Col>
                    <Col span={1} className={'actions'}>
                        <Button
                            type="primary"
                            icon={<DeleteOutlined />}
                            onClick={() => handleOnDelete(rule, index)}
                        ></Button>
                        <Button
                            icon={<BranchesOutlined />}
                            onClick={() => handleOnExtend(index)}
                            style={{ marginTop: '10px' }}
                        ></Button>
                    </Col>
                </Row>
            </div>);
    }

    function renderOperator(operator: any, index: number) {
        let { operation } = operator;
        let isOr = operation === 'or' || operation === ') or (';
        return (<div className='rule-operator' style={{ padding: isOr ? '10px 10px' : '2px 10px' }} >
            {isOr && <hr className='rule-operator-separator' />}
            <Select
                value={operation}
                onChange={value => handleOperatorChange(value, operator, index)}
            >
                <Select.Option value={'or'}>OR</Select.Option>
                <Select.Option value={'and'}>AND</Select.Option>
                <Select.Option value={') and ('}> &#41; AND &#40; </Select.Option>
                <Select.Option value={') or ('}> &#41; OR &#40; </Select.Option>
                <Select.Option value={'and not'}>AND NOT</Select.Option>
                <Select.Option value={'or not'}>OR NOT</Select.Option>
                <Select.Option value={') and not ('}> &#41; AND NOT &#40; </Select.Option>
                <Select.Option value={') or not ('}> &#41; OR NOT &#40; </Select.Option>
            </Select>
        </div>);
    }

    function renderModuleSelect(rule: any) {
        let { id: ruleId, modules, module } = rule;
        let name = `${ruleId}_m`;
        let formProps = {
            name,
            label: null
        } as any;
        if (module?.id) {
            form.setFields([
                {
                    name,
                    value: module?.id
                }
            ]);
        }
        return (
            <Form.Item {...formProps}>
                <Select
                    placeholder={'Select Module'}
                    onSelect={(e: any) => handleModuleSelect(ruleId, e)}
                >
                    {modules.map((d: any, index: number) => {
                        let { id, name } = d;
                        return <Select.Option key={index} value={id}>{name}</Select.Option>;
                    })}
                </Select>
            </Form.Item>
        )
    }

    function renderSectionSelect(rule: any) {
        let { id: ruleId, module, section } = rule;
        let isModuleNotSelected = !module;
        let selectProps = {
            placeholder: 'Select Section',
            disabled: isModuleNotSelected,
            onSelect: (e: any) => handleSectionSelect(rule, e)
        } as any;
        let name = `${ruleId}_m${module?.id}_s`;
        let formProps = {
            name: name,
            label: null
        } as any;
        if (isModuleNotSelected) {
            return (<Form.Item {...formProps} >
                <Select {...selectProps}></Select>
            </Form.Item>);
        }
        if (section?.id) {
            form.setFields([
                {
                    name,
                    value: section?.id
                }
            ]);
        }
        let { sections } = module;
        if (sections.length === 0) {
            selectProps.value = null;
        }
        return (<Form.Item {...formProps}>
            <Select
                {...selectProps}
            >
                {sections.map((d: any, index: number) => {
                    let { id, name } = d;
                    return <Select.Option key={index} value={id}>{name}</Select.Option>;
                })}
            </Select>
        </Form.Item>
        )
    }

    function renderQuestionSelect(rule: any) {
        let { id: ruleId, module, section, question } = rule;
        let isSectionNotSelected = !section;
        let props = {
            placeholder: 'Select Question',
            disabled: isSectionNotSelected,
            onSelect: (e: any) => handleQuestionSelect(rule, e)
        } as any;
        let name = `${ruleId}_m${module?.id}_s${section?.id}_q`;
        let formProps = {
            name,
            label: null
        } as any;
        let questions = section?.questions;

        if (!Array.isArray(questions) || isSectionNotSelected) {
            return (<Form.Item {...formProps} >
                <Select {...props}>
                </Select>
            </Form.Item>);
        }
        if (question?.id) {
            form.setFields([
                {
                    name,
                    value: question?.id
                }
            ]);
        }
        if (questions.length === 0) {
            formProps.value = null;
        }
        return (<Form.Item {...formProps}>
            <Select
                {...props}
            >
                {questions.map((d: any, index: number) => {
                    let { id, data: { label } } = d;
                    return <Select.Option key={index} value={id}>{label}</Select.Option>;
                })}
            </Select>
        </Form.Item>)
    }

    function renderAnswerLayout(rule: any) {
        let { id: ruleId, module, section, question } = rule;
        let isQuestionNotSelected = !question;

        let name = `${ruleId}_m${module?.id}_s${section?.id}_q${question?.id}_a`;
        let formProps = {
            name,
            label: null
        } as any;

        if (isQuestionNotSelected) {
            return (<Form.Item {...formProps}>
                <Input disabled={true} />
            </Form.Item>);
        }

        let { data } = question;
        if (!data) {
            return <></>
        }
        let { type } = data;

        if (rule?.value) {
            switch (type) {
                case 'date':
                    form.setFields([
                        {
                            name,
                            value:moment(rule?.value)
                        }
                    ]);
                    break;
                default:
                    form.setFields([
                        {
                            name,
                            value:  rule?.value
                        }
                    ]);
                    break;
            }
        }

        switch (type) {
            case 'select': {
                let { values: options, multiple } = data;
                let mode = { } as any;
                if(multiple === true){
                    mode.mode = "multiple";
                }
                return (<Form.Item  {...formProps}>
                    <Select
                        onChange={(value) => handleOnValue(type, rule, value)}
                        {...mode}
                    >
                        {options.map((d: any, index: number) => {
                            let { label, value } = d;
                            return <Select.Option key={index} value={value}>{label}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>)
            }
            case 'radio-group': {
                let { values: options } = data;
                return (<Form.Item  {...formProps}>
                    <Select
                        onChange={(value) => handleOnValue(type, rule, value)}
                    >
                        {options.map((d: any, index: number) => {
                            let { label, value } = d;
                            return <Select.Option key={index} value={value}>{label}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>)
            }
            case 'checkbox-group': {
                let { values: options } = data;
                return (<Form.Item  {...formProps}>
                    <Select
                        mode="multiple"
                        onChange={(value) => handleOnValue(type, rule, value)}
                    >
                        {options.map((d: any, index: number) => {
                            let { label, value } = d;
                            return <Select.Option key={index} value={value}>{label}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>)
            }
            case 'number': {
                return renderNumericalAnswerLayout(type, rule, formProps);
            }
            case 'aggregate': {
                return renderNumericalAnswerLayout(type, rule, formProps);
            }
            case 'text':
            case 'textarea':
            case 'autocomplete': {
                return (<Form.Item  {...formProps}>
                    <Input
                        onChange={(e) => e?.target?.value && handleOnValue(type, rule, e.target.value)}
                    />
                </Form.Item>)
            }
            case 'date': {
                return (<Form.Item  {...formProps}>
                    <DatePicker
                        onChange={(value) => handleOnValue(type, rule, value)} />
                </Form.Item>)
            }
            default: {
                return (<Form.Item  {...formProps}>
                    <Input
                        onChange={(e) => e?.target?.value && handleOnValue(type, rule, e.target.value)}
                        disabled={true} />
                </Form.Item>)
            }
        }
    }

    function renderNumericalAnswerLayout(type: string, rule: any, baseFormProps: any) {
        let { id: ruleId, module, section, question, value: ruleValue } = rule;
        let defaultAttributeValue = "equal-to";
        let baseAttribute = 'comparison-operator';
        let attributeValue = defaultAttributeValue;
        let operatorType = rule['comparison-operator-type'] || 'unary';
        let ruleValueValueKey = 'value';
        let ruleValueValue = '';
        if (ruleValue) {
            ruleValueValue = ruleValue[ruleValueValueKey] || '';
            attributeValue = ruleValue[baseAttribute] || defaultAttributeValue;
        }

        let rangeOperators = ["between", "not-in-between"];
        let isRangeOperator = rangeOperators.includes(attributeValue);
        if (isRangeOperator) {
            operatorType = 'binary';
        }

        if (ruleValueValue) {
            let { name } = baseFormProps;
            form.setFields([
                {
                    name,
                    value: ruleValueValue
                }
            ]);
        }
        let classes = [`numeric-${operatorType}`];
        let operators = [
            { key: "equal-to", symbol: '=' },
            { key: "not-equal-to", symbol: '≠' },
            { key: "greater-than", symbol: '>' },
            { key: "less-than", symbol: '<' },
            { key: "greater-than-or-equal-to", symbol: '≥' },
            { key: "less-than-or-equal-to", symbol: '≤' },
            { key: "between", symbol: '∈' },
            { key: "not-in-between", symbol: '∉' },
        ];
        let rangeClosures = [
            { key: 'open-range', symbolStart: '(', symbolEnd: ')' },
            { key: 'close-range', symbolStart: '[', symbolEnd: ']' }
        ];
        return (<div style={{ display: 'flex' }}>
            <Form.Item  {...baseFormProps}>
                <Input
                    addonBefore={
                        <Select
                            style={{ 'minWidth': '220px' }}
                            onChange={(value) => {
                                let binaryOperators = ["between", "not-in-between"] as any;
                                let ternaryOperators = [] as any;
                                let quaternaryOperators = [] as any;
                                let operatorType = 'unary';

                                if (binaryOperators.includes(value)) {
                                    operatorType = 'binary';
                                }
                                else if (ternaryOperators.includes(value)) {
                                    operatorType = 'ternary';
                                }
                                else if (quaternaryOperators.includes(value)) {
                                    operatorType = 'quaternary';
                                }
                                else {
                                    operatorType = 'unary';
                                }

                                let meta = {
                                    'comparison-operator-type': operatorType
                                };

                                handleOnValueAttribute(baseAttribute, type, rule, value, meta);
                            }}
                            value={attributeValue}
                        >
                            {operators.map((operator: any) => {
                                let { key, symbol } = operator;
                                return <Select.Option key={key} value={key}>
                                    {key.replaceAll('-', ' ')}
                                    <span className='mathematical-symbol' >{symbol}</span>
                                </Select.Option>;
                            })}
                        </Select>}
                    type={"number"}
                    style={{ 'verticalAlign': 'middle' }}
                    className={classes.join(" ")}
                    onChange={(e) => {
                        let value = e?.target?.value;
                        if (value) {
                            handleOnValueAttribute(ruleValueValueKey, type, rule, value);
                        }
                    }}
                    value={ruleValueValue}
                />
            </Form.Item>
            {isRangeOperator && ((() => {
                let rangeStartName = `${ruleId}_m${module?.id}_s${section?.id}_q${question?.id}_a_attr_range_start`;
                let rangeEndName = `${ruleId}_m${module?.id}_s${section?.id}_q${question?.id}_a_attr_range_end`;

                let rangeStartClosureKey = 'comparison-operator-between-range-start-closure';
                let rangeEndClosureKey = 'comparison-operator-between-range-stop-closure';

                let rangeStartValueKey = 'comparison-operator-between-range-start-value';
                let rangeEndValueKey = 'comparison-operator-between-range-stop-value';

                let rangeStartClosure = "range-open";
                let rangeEndClosure = "range-open";

                let rangeStartValue = '';
                let rangeEndValue = '';

                if (ruleValue) {
                    rangeStartClosure = ruleValue[rangeStartClosureKey] || rangeStartClosure;
                    rangeEndClosure = ruleValue[rangeEndClosureKey] || rangeEndClosure;

                    rangeStartValue = ruleValue[rangeStartValueKey];
                    rangeEndValue = ruleValue[rangeEndValueKey];
                }

                if (rangeStartValue) {
                    form.setFields([
                        {
                            name: rangeStartName,
                            value: rangeStartValue
                        }
                    ]);
                }

                if (rangeEndValue) {
                    form.setFields([
                        {
                            name: rangeEndName,
                            value: rangeEndValue
                        }
                    ]);
                }

                return <>
                    <Form.Item
                        label={null}
                        name={rangeStartName} >
                        <Input
                            addonBefore={<span>Range Start</span>}
                            addonAfter={<Select
                                style={{ 'minWidth': '130px' }}
                                onChange={(value) => {
                                    handleOnValueAttribute(rangeStartClosureKey, type, rule, value);
                                }}
                                value={rangeStartClosure}
                            >
                                {rangeClosures.map((closure: any) => {
                                    let { key, symbolStart } = closure;
                                    return <Select.Option key={key} value={key}>
                                        {key.replaceAll('-', ' ')}
                                        <span className='mathematical-range-closure' >{symbolStart}</span>
                                    </Select.Option>;
                                })}
                            </Select>}
                            type={"number"}
                            onChange={(e) => {
                                let value = e?.target?.value;
                                if (value) {
                                    handleOnValueAttribute(rangeStartValueKey, type, rule, parseFloat(value));
                                }
                            }}
                            style={{ 'verticalAlign': 'middle', padding: '0px 10px' }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={null}
                        name={rangeEndName} >
                        <Input
                            addonBefore={<span>Range End</span>}
                            addonAfter={<Select
                                style={{ 'minWidth': '130px' }}
                                onChange={(value) => {
                                    handleOnValueAttribute(rangeEndClosureKey, type, rule, value);
                                }}
                                value={rangeEndClosure}
                            >
                                {rangeClosures.map((closure: any) => {
                                    let { key, symbolEnd } = closure;
                                    return <Select.Option key={key} value={key}>
                                        {key.replaceAll('-', ' ')}
                                        <span className='mathematical-range-closure' >{symbolEnd}</span>
                                    </Select.Option>;
                                })}
                            </Select>}
                            type={"number"}
                            onChange={(e) => {
                                let value = e?.target?.value;
                                if (value) {
                                    handleOnValueAttribute(rangeEndValueKey, type, rule, parseFloat(value));
                                }
                            }}
                            style={{ 'verticalAlign': 'middle' }}
                        />
                    </Form.Item>
                </>
            })())}
        </div>);
    }

});

Layout.propTypes = {

};

type LayoutProps = {
    onSave: (rules: any) => void,
    isLoading: boolean,
    existingRule: any
}

export type DisplayLogicHandle = {
    onOpen: () => void
}

export default Layout;
