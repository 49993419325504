import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

type TranslateProps = {
  children: React.ReactNode
}

const TranslateStateContext = React.createContext<any>(undefined)

function TranslateProvider({ children }: TranslateProps) {
  const { keys, fallback, types } = useSelector((state : any) => state.locale);

  const value = (key: string, fallbackValue? : string) => {
    if (keys[key]) {
      return keys[key];
    }
    else if (types[fallback] && types[fallback][keys]) {
      return types[fallback][keys];
    }
    return fallbackValue
  }
  return (
    <TranslateStateContext.Provider value={value}>
      {children}
    </TranslateStateContext.Provider>
  )
}

export default function useTranslate() {
  const context = useContext(TranslateStateContext)
  if (context === undefined) {
    throw new Error('useTranslate must be used within a TranslateProvider')
  }
  return context
}

export { TranslateProvider, useTranslate }
