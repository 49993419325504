import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Row, Col, Input, Spin, Form, FormItemProps, Button, message, Select, Typography, Popconfirm, Tooltip, Divider } from 'antd';

import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useApi, useTranslate } from 'hooks';
import { cloneSurveyModuleRequest, createSurveyRequest, deleteSurveyModuleRequest, getSurveyModulesRequest, getSurveyRequest, updateSurveyModulesReOrderRequest, updateSurveyRequest } from 'api/surveys.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SortableTable from 'components/table/sortable-table';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { WithPermissions } from 'utils/with-permissions';
import { useDropzone } from 'react-dropzone'
import { getUserDefaultProjectId } from 'selectors/auth.selectors';
import { getLocales } from 'selectors/locale.selectors';

import ImportSurveyModel, { ModelHandle as ImportSurveyModelHandle } from './import-survey.model';

import './edit-survey.less';

const { useForm } = Form;
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

export default function Layout(props: LayoutProps) {
    const messageKey = 'survey-command-key';

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const translate = useTranslate();

    const { mode, surveyId } = useParams();

    const [form] = useForm();

    const currentUserProjectId = useSelector(getUserDefaultProjectId) as string;
    const locales = useSelector(getLocales);

    const [survey, setSurvey] = useState<any>({});
    const [modules, setModules] = useState<any[]>([]);
    const [houseHoldQuestionsCount, setHouseHoldQuestionsCount] = useState<number>(0);
    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [isReadOnly] = useState<boolean>(mode === 'view');

    const [confirmDeleteModuleId, setConfirmDeleteModuleId] = useState<number>(-1);
    const [confirmCloneSurveyModuleId, setConfirmCloneSurveyModuleId] = useState<number>(-1);

    const importSurveyModelHandleRef = useRef<ImportSurveyModelHandle>(null);

    const [{
        error: errorCommand,
        loading: loadingCommand,
        data: dataCommand,
        isSuccess: isSuccessCommand
    }, executeCommandApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetSurvey,
        data: dataGetSurvey,
        isSuccess: isSuccessGetSurvey
    }, executeGetSurveyApi] = useApi<any, any>();

    const [{
        loading: loadingGetModules,
        data: dataGetModules,
        isSuccess: isSuccessGetModules
    }, executeGetModulesApi] = useApi<any, any>();

    const [{
        loading: loadingUpdateModulesOrdered,
        isSuccess: isSuccessUpdateModulesOrdered
    }, executePutModulesOrderedApi] = useApi<any, any>();

    const [{
        loading: loadingDeleteModule,
        isSuccess: isSuccessDeleteModule,
    }, executeDeleteModuleApi] = useApi<any, any>();

    const [{
        loading: loadingCloneSurveyModule,
        isSuccess: isSuccessCloneSurveyModule
    }, executeCloneSurveyModuleApi] = useApi<any, any>();

    const onDrop = useCallback((acceptedFiles: any) => {
        if (Array.isArray(acceptedFiles) && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            importSurveyModelHandleRef.current?.open(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        }
    })

    useEffect(() => {
        if (isCreation) {
            dispatch(setSectionHeadTitle(translate('survey-management.create.survey.header', "Create Survey")));
        }
        else if (mode === 'edit') {
            dispatch(setSectionHeadTitle(translate('survey-management.edit.survey.header', "Edit Survey")));
        }
        else {
            dispatch(setSectionHeadTitle(translate('survey-management.view.survey.header', "View Survey")));
        }
    }, [isCreation]);

    useEffect(() => {
        if (currentUserProjectId) {
            if (surveyId) {
                executeGetSurveyApi(getSurveyRequest(currentUserProjectId, surveyId));
                fetchModules();
                setIsCreation(false);
            }
            else {
                dispatch(setSectionHeadTitle("Create Survey"));
                setIsCreation(true);
            }
        }
    }, [surveyId, currentUserProjectId]);

    useEffect(() => {
        if (isSuccessGetModules && Array.isArray(dataGetModules)) {
            setModules(dataGetModules);
        }
    }, [isSuccessGetModules, dataGetModules]);

    useEffect(() => {
        if (isSuccessUpdateModulesOrdered === true) {
            fetchModules();
        }
    }, [isSuccessUpdateModulesOrdered]);

    useEffect(() => {
        if (isSuccessGetSurvey && dataGetSurvey && dataGetSurvey.id) {
            setSurvey(dataGetSurvey);
            let { householdQuestions: items } = dataGetSurvey;
            if (Array.isArray(items)) {
                setHouseHoldQuestionsCount(items.length);
            }

            form.setFieldsValue(dataGetSurvey);
        }
    }, [isSuccessGetSurvey, dataGetSurvey]);

    useEffect(() => {
        if (isSuccessCommand && dataCommand.id) {
            if (isCreation === true) {
                navigate(`/app/surveys-management/survey/edit/${dataCommand.id}`, { replace: true });
            }
            else {
                navigate(`/app/surveys-management`, { replace: true });
            }
            message.success({ content: (isCreation ? 'Created successfully' : 'Updated successfully'), key: messageKey });
        }
        else if (errorCommand) {
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [dataCommand, isSuccessCommand, errorCommand]);

    useEffect(() => {
        if (isSuccessDeleteModule === true) {
            setConfirmDeleteModuleId(-1);
            fetchModules();
        }
    }, [isSuccessDeleteModule]);

    useEffect(() => {
        if (isSuccessCloneSurveyModule === true) {
            setConfirmCloneSurveyModuleId(-1);
            fetchModules();
        }
    }, [isSuccessCloneSurveyModule]);

    function fetchModules() {
        currentUserProjectId && surveyId && executeGetModulesApi(getSurveyModulesRequest(currentUserProjectId, surveyId));
    }

    function onFinish(value: any) {
        message.loading({ content: (isCreation ? 'Creating...' : 'Updating...'), key: messageKey });
        if (currentUserProjectId) {
            if (isCreation) {
                executeCommandApi(createSurveyRequest(currentUserProjectId, value));
            }
            else {
                let data = {
                    ...survey,
                    ...value
                }
                executeCommandApi(updateSurveyRequest(currentUserProjectId, data));
            }
        }
    }

    function onAddModuleHandle() {
        navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/create`);
    }

    function onDeleteModuleHandle(id: string) {
        currentUserProjectId && surveyId && executeDeleteModuleApi(deleteSurveyModuleRequest(currentUserProjectId, surveyId, id));
    }

    function onEditModuleHandle(id: string) {
        navigate(`/app/surveys-management/survey/edit/${surveyId}/modules/edit/${id}`);
    }

    function onEditHouseholdQuestionnaireHandle() {
        navigate(`/app/surveys-management/survey/edit/${surveyId}/household-questionnaire`);
    }

    function onReOrdered(items: any[]) {
        if (currentUserProjectId && surveyId) {
            let payload = {
                items
            };
            executePutModulesOrderedApi(updateSurveyModulesReOrderRequest(currentUserProjectId, surveyId, payload));
        }
    }

    function handleClone(id: string) {
        surveyId && currentUserProjectId && id && executeCloneSurveyModuleApi(cloneSurveyModuleRequest(currentUserProjectId, surveyId, id));
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    const columns = [
        {
            title: "Name",
            key: 'name',
            dataIndex: "name",
            overridenWidth: 100
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            overridenWidth: 40,
            render: (text: any, record: any) => (
                <div>
                    <WithPermissions permissions={['permission-project:survey:edit']} >
                        <Tooltip title="Edit module" placement="bottom" className='action' >
                            <Button
                                onClick={() => onEditModuleHandle(record.id)}
                                icon={<EditOutlined />}
                                style={{ 'marginRight': '12px', }}
                            >
                            </Button>
                        </Tooltip>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:clone']} >
                        <Popconfirm
                            title="Clone module?"
                            visible={confirmCloneSurveyModuleId === record.id}
                            onConfirm={() => handleClone(record.id)}
                            okButtonProps={{ loading: loadingCloneSurveyModule }}
                            onCancel={() => setConfirmCloneSurveyModuleId(-1)}
                        >
                            <Tooltip title="Clone module" placement="bottom">
                                <Button
                                    onClick={() => setConfirmCloneSurveyModuleId(record.id)}
                                    icon={<CopyOutlined />}
                                    style={{ 'marginRight': '3%', }}>

                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                    <WithPermissions permissions={['permission-project:survey:edit']} >
                        <Popconfirm
                            title="Delete module?"
                            visible={confirmDeleteModuleId === record.id}
                            onConfirm={() => onDeleteModuleHandle(record.id)}
                            okButtonProps={{ loading: loadingDeleteModule }}
                            onCancel={() => setConfirmDeleteModuleId(-1)}
                        >
                            <Tooltip title="Delete module" placement="bottom">
                                <Button
                                    onClick={() => setConfirmDeleteModuleId(record.id)}
                                    icon={<DeleteOutlined />}>
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </WithPermissions>
                </ div>
            ),
        },
    ];

    return (
        <div className='edit-survey'>
            <Spin spinning={loadingCommand || loadingGetSurvey}>
                <Row className={'edit-survey-form'}>
                    <Col span={22} offset={2}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >

                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input readOnly={isReadOnly} />
                                    </Form.Item>

                                </Col>
                                <Col span={12} >
                                    <Form.Item
                                        label="Language"
                                        name="locale"
                                        rules={[{ required: true, message: 'Please select locale!' }]}
                                        {...formItemProps}
                                    >
                                        {(() => {
                                            if (isReadOnly) {
                                                return <Input readOnly={true} />
                                            }
                                            else {
                                                return <Select
                                                    allowClear
                                                    style={{ width: '100%' }}
                                                    placeholder={'select language'}
                                                    defaultActiveFirstOption={false}
                                                >
                                                    {locales.map((d: any) => <Option key={d.key} value={d.key}>{d.value}</Option>)}
                                                </Select>
                                            }
                                        })()}
                                    </Form.Item>
                                </Col>
                                <Col span={24} >
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        {...{ ...formItemProps, wrapperCol: { span: 22 }, }}
                                    >
                                        <TextArea rows={3} readOnly={isReadOnly} />
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={22} >
                                    <Divider plain style={{ margin: '16px 0' }} ></Divider>
                                </Col>
                            </Row>

                            {renderHouseholdQuestions()}

                            {isReadOnly && <>
                                <Row>
                                    <Col span={22} >
                                        <Divider plain style={{ margin: '16px 0' }} ></Divider>
                                    </Col>
                                </Row>
                                {renderImportAsNewLanguage()}
                            </>}

                            <Row>
                                <Col span={22} >
                                    <Divider plain style={{ margin: '16px 0' }} ></Divider>
                                </Col>
                            </Row>

                            {renderModules()}

                            {!isReadOnly && <Row>
                                <Col span={22} >
                                    <Form.Item wrapperCol={{ offset: 20 }} style={{ 'marginTop': '5%' }}>
                                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                            {isCreation ? 'Create' : 'Update'}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>}

                        </Form>
                    </Col>

                </Row>
            </Spin>
            <ImportSurveyModel
                ref={importSurveyModelHandleRef}
                onClose={() => {

                }} />
        </div>
    );

    function renderModules() {
        if (isCreation === true) {
            return <></>
        }
        return <Row style={{ 'marginTop': '10px' }}>
            <Col span={22}>
                <Row>
                    <Col span={20} >
                        <Title level={5} style={{ 'fontWeight': 500, marginBottom: '2%' }} >
                            Modules incorporated with this survey
                        </Title>
                    </Col>
                    {(!isCreation) && <Col span={4} >
                        <Button onClick={onAddModuleHandle} style={{ 'float': 'right' }} >
                            Add Module
                        </Button>
                    </Col>}
                </Row>
            </Col>
            <Col span={22} >
                <Spin spinning={loadingGetModules || loadingUpdateModulesOrdered} >
                    <SortableTable
                        columns={columns}
                        source={modules}
                        style={{ width: 'auto', 'overflowY': 'hidden' }}
                        size={'small'}
                        rowKey={"id"}
                        maxWidthPerCell={600}
                        height={400}
                        pagination={false}
                        showHeader={false}
                        className='locations-table'
                        onFinish={onReOrdered}
                    />
                </Spin>
            </Col>
        </Row>
    }

    function renderHouseholdQuestions() {
        if (isCreation) {
            return <></>
        }
        return <Row style={{ 'marginTop': '10px' }}>
            <Col span={22}>
                <Row>
                    <Col span={14}>
                        <Title level={5} style={{ 'fontWeight': 500, margin: '3px 0px' }} >
                            Household questionnaire
                        </Title>
                    </Col>
                    <Col span={6}>
                        <Title level={5} style={{ 'fontWeight': 300, margin: '3px 0px' }} >
                            Contain {((count: number) => {
                                switch (count) {
                                    case 0: return <>No Questions</>;
                                    case 1: return <>Single Question</>;
                                    default: return <>{count} Questions</>;
                                }
                            })(houseHoldQuestionsCount)}
                        </Title>
                    </Col>
                    <Col span={4}>
                        <Button
                            style={{ width: '100%' }}
                            onClick={onEditHouseholdQuestionnaireHandle}
                        >Edit</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    }

    function renderImportAsNewLanguage() {
        return <Col span={22}>
            <Row>
                <Col span={14}>
                    <Title level={5} style={{ 'fontWeight': 500, margin: '3px 0px' }} >
                        Import as new language
                    </Title>
                </Col>
                <Col span={10} className='upload-box' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the files here ...</p> :
                            <p>Drag 'n' drop xlsx, or click to select files</p>
                    }
                </Col>
            </Row>
        </Col>
    }
}

Layout.propTypes = {

};

type LayoutProps = {

}
