import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Col, Dropdown, Layout, Row, Menu, Breadcrumb } from 'antd';
import {
    ArrowLeftOutlined,
    HomeOutlined,
    UserOutlined
} from '@ant-design/icons';

import { useMatch, useNavigate, useLocation, matchRoutes } from 'react-router-dom';

import { useAppDispatch } from 'store';

import { signOut } from 'reducers/auth.reducer';
import { NavigateProfile } from 'constants/paths';

import routes from 'pages/app';

import './index.css'

const { Header } = Layout;

export default function View() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const isOnDashBoard = useMatch('app/dashboard');

    const { user } = useSelector((state : any) => state.auth);

    const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

    useEffect(() => {
        let { children } = routes;
        let matched = matchRoutes(children as any, location);
        if(Array.isArray(matched)){
            let ignored = ['/app'];
            let segments = matched.filter(i => !ignored.includes(i.pathname) && !i.pathname.endsWith('/'));

            let mapped = segments.map((match : any) => {
                let { pathname, route : { name } } = match
                return {
                    name,
                    path : pathname
                }
            });

            setBreadcrumbs(mapped);
        }
    }, [location]);

    function onBackHandle() {
        navigate(-1);
    }

    function handleSignOut() {
        dispatch(signOut());
    }

    function handleGotoProfile() {
        navigate(NavigateProfile);
    }

    const menu = (
        <Menu>
            <Menu.Item key={1}>
                <span onClick={() => handleGotoProfile()} >
                    Profile
                </span>
            </Menu.Item>
            <Menu.Item key={2}>
                <span onClick={() => handleSignOut()} >
                    Sign Off
                </span>
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="main-header" style={{ padding: 0 }}>
            <Row>
                <Col
                    xs={24} sm={12} md={16} lg={18} xl={18} xxl={18} >
                    {(!isOnDashBoard) && renderNavigationButtons()}
                </Col>
                <Col
                    xs={24} sm={12} md={8} lg={6} xl={6} xxl={6} >
                    <div style={{ display: 'inline-block', minWidth: '200px', textAlign: 'right', marginRight: '2%', 'float': 'right' }} >
                        <strong style={{ fontWeight: 400, display: 'inline-block', margin: '0% 16px' }}>{user?.name}</strong>
                        <Dropdown overlay={menu}>
                            <Avatar className={'avatar'} icon={<UserOutlined />} />
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </Header>
    );

    function renderNavigationButtons() {
        return (<div>
            <Button
                onClick={() => onBackHandle()}
                icon={<ArrowLeftOutlined />}
                style={{ marginLeft: '2%', backgroundColor: '#ffffff', borderColor: '#f0f2f5' }}
            >
            </Button>

            <Breadcrumb style={{ 'display': 'inline-block', 'marginLeft': '16px' }}>
                <Breadcrumb.Item onClick={() => {
                    navigate(`/app/dashboard`);
                }}>
                    <HomeOutlined />
                </Breadcrumb.Item>
                {breadcrumbs.map((breadcrumb: any, index : number) => {
                    let { name, path } = breadcrumb;
                    return (
                        <Breadcrumb.Item key={index} onClick={() => navigate(path)}>
                            {name}
                        </Breadcrumb.Item>
                    )
                })}
            </Breadcrumb>
        </div>);
    }

}