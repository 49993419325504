import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Col, Layout, Row } from 'antd';

import './index.css'
const { Header } = Layout;
const { Title } = Typography;

export default function View() {
    const { sectionHeadTitle } = useSelector((state : any) => state.system);

    if (!sectionHeadTitle) {
        return <></>;
    }

    return (
        <Header className="section-header" style={{ padding: 0 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                <Row justify="center" align="middle" style={{ minHeight: '10vh' }}>
                    <Col >
                        <Title level={2} style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '32px' }} >
                            {sectionHeadTitle}
                            &nbsp;
                        </Title>
                    </Col>
                </Row>
            </Col>
        </Header>
    );
}