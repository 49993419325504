import React, { useState, useImperativeHandle, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Input, Spin, Form, FormItemProps, Button, Modal, Select } from 'antd';
import readXlsxFile from 'read-excel-file';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';
import { getLocales } from 'selectors/locale.selectors';
import { useParams } from 'react-router-dom';
import { importKeysAsFileSurveyRequest } from 'api/surveys.api';
import { useApi } from 'hooks';

const { useForm } = Form;
const { TextArea } = Input;
const { Option } = Select;

const Layout = React.forwardRef<ModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const [form] = useForm();

    const locales = useSelector(getLocales);
    const { surveyId } = useParams();
    const currentUserProjectId = useSelector(getUserDefaultProjectId) as string;

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [importedFile, setImportedFile] = useState<any>();

    useImperativeHandle(ref, () => ({
        open(file: any) {
            setIsVisible(true);

            readXlsxFile(file, { sheet: 'survey' }).then((rows) => {
                const data = {
                    id: rows[0][1],
                    name: rows[1][1],
                    description: rows[2][1],
                    currentLanguage: rows[3][1],
                    destinationLanguage: rows[4][1],
                }

                setImportedFile(file);
                form.setFieldsValue(data);
                setIsVisible(true);
            });
        }
    }));

    const [{
        loading: loadingImportKeysAsFileSurvey,
        isSuccess: isSuccessImportKeysAsFileSurvey,
    }, executeImportKeysAsFileSurveyRequestApi] = useApi<any, any>();

    useEffect(() => {
        if (isSuccessImportKeysAsFileSurvey === true) {
            setIsCompleted(true);
        }
    }, [isSuccessImportKeysAsFileSurvey]);
    
    function onImport() {
        if (currentUserProjectId && surveyId && importedFile) {
            executeImportKeysAsFileSurveyRequestApi(importKeysAsFileSurveyRequest(currentUserProjectId, surveyId, importedFile));
        }
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    return (
        <Modal
            title={<div><span>Import Survey</span></div>}
            visible={isVisible}
            destroyOnClose={true}
            closeIcon={<></>}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={800}
            className={'import-survey'}
        >
            <Spin spinning={loadingImportKeysAsFileSurvey}>
                <Form
                    name="basic"
                    form={form}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Name"
                                name="name"
                                {...formItemProps}
                            >
                                <Input readOnly={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} >
                            <Form.Item
                                label="Description"
                                name="description"
                                {...formItemProps}
                            >
                                <TextArea rows={3} readOnly={true} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={11}>
                            <Form.Item
                                label="Current Language"
                                name="currentLanguage"
                                {...formItemProps}
                            >
                                <Input readOnly={true} />
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2}>
                            <Form.Item
                                label="Translation Language"
                                name="destinationLanguage"
                                {...formItemProps}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={'select language'}
                                    defaultActiveFirstOption={false}
                                >
                                    {locales.map((d: any) => <Option key={d.key} value={d.key}>{d.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ 'justifyContent': 'flex-end' }}>
                        <Col>
                            <Button type='primary' onClick={onImport} disabled={isCompleted} >
                                {isCompleted? 'Imported' :'Import'}
                            </Button>
                            <Button onClick={() => setIsVisible(false)}
                                style={{ 'marginLeft': '20px' }}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
});


Layout.propTypes = {

};

type LayoutProps = {
    onClose: () => void
}

export type ModelHandle = {
    open: (file: any) => void,
}

export default Layout;