import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Row, Col, Form, Input, Button, Switch, Spin, Modal, Result, FormItemProps, List, Typography } from 'antd';

import { createRoleRequest } from 'api/roles.api';

import useApi from 'hooks/useApi';

import './create-role.model.css';
import { getPermissionGroupsRequest } from 'api/permission-groups.api';
import { useParams } from 'react-router-dom';

const { useForm } = Form;
const { Title } = Typography;

const Layout = React.forwardRef<CreateRoleModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const { projectId } = useParams();
    
    const [form] = useForm();

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [isResultVisible, setIsResultVisible] = useState(false);

    const [permissionGroups, setPermissionGroups] = useState<any>([]);

    const [{
        data: dataGetPermissionGroups,
        isSuccess: isGetPermissionGroupsSuccess,
    }, executeGetPermissionGroupsApi] = useApi<any, any>([]);

    const [{
        loading: loadingCreateRole,
        data: dataCreateRole,
        isSuccess: isRoleCreationSuccess,
        error: errorCreateRole
    }, executeCreateRoleApi] = useApi<any, any>();

    useImperativeHandle(ref, () => ({
        setVisible(state: boolean) {
            setIsVisible(state)
            if (state) {
                setIsCreation(true)
                form.resetFields();
                setIsResultVisible(false)
            }
        }
    }));

    useEffect(() => {
        if (!isVisible) {
            typeof onCloseCallback === 'function' && onCloseCallback()
        }
    }, [isVisible]);

    useEffect(() => {
        executeGetPermissionGroupsApi(getPermissionGroupsRequest());
    }, [isVisible]);

    useEffect(() => {
        if (isGetPermissionGroupsSuccess && Array.isArray(dataGetPermissionGroups)) {
            setPermissionGroups(dataGetPermissionGroups);
        }
    }, [dataGetPermissionGroups, isGetPermissionGroupsSuccess]);

    useEffect(() => {
        if (isRoleCreationSuccess) {
            form.resetFields();
            setIsResultVisible(true);
        }
        else if (errorCreateRole) {
            form.setFields([
                {
                    name: 'name',
                    errors: [errorCreateRole],
                },
            ]);
        }
    }, [isRoleCreationSuccess, dataCreateRole, errorCreateRole]);

    function onSwitchChange(record: any, status: boolean) {
        setPermissionGroups((items: any[]) => items.map((item: any) => {
            if (item.id === record.id) {
                item.isEnabled = status
            }
            return item;
        }) as any)
    }

    function onFinish(values: any) {
        let selected = permissionGroups?.filter((i: any) => i.isEnabled);
        let data = {
            ...values,
            projectId : parseInt(projectId || '0'),
            permissions: selected
        }

        executeCreateRoleApi(createRoleRequest(data));
    };

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    return (
        <Modal
            title={`${isCreation ? 'Create New' : 'Update'} Role`}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={800}
            style={{ top: 10 }}
        >
            {isResultVisible ? renderResult() : renderForm()}
        </Modal>
    );

    function renderForm() {
        let isSpinning = loadingCreateRole
        return (
            <Spin spinning={isSpinning}>
                <Row>
                    <Col span={20} offset={3}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >

                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input your name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input />
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} >
                                    <Form.Item
                                        label="Permissions"
                                        name="permissions"
                                        rules={[{
                                            required: true,
                                            message: 'Please select permissions!',
                                            validator: async (rule, value) => {
                                                let selected = permissionGroups?.filter((i: any) => i.isEnabled);
                                                if (!(Array.isArray(selected) && selected.length > 0)) {
                                                    throw new Error('Please select permissions!');
                                                }
                                            }
                                        }]}
                                        {...formItemProps}
                                    >
                                        <br/>
                                        <List
                                            grid={{ gutter: 4, column: 2 }}
                                            dataSource={permissionGroups}
                                            renderItem={(item: any, index: number) => renderPermissionGroup(item, index)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item wrapperCol={{ offset: 19, span: 4 }} style={{ 'marginTop': '5%' }}>
                                <Button type="primary" htmlType="submit">
                                    {isCreation ? 'Create' : 'Update'}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>
            </Spin>
        )
    }

    function renderResult() {
        return (
            <Result
                status="success"
                title={
                    <>
                        <h4>{`Role Created Successfully`}</h4>
                        <h5>{isCreation && `name : ${dataCreateRole.name}`}</h5>
                    </>
                }
                extra={[
                    <Button key="back" onClick={() => setIsVisible(false)}>Close</Button>,
                ]}
            />
        );
    }

    function renderPermissionGroup(item: any, index: number) {
        let { isEnabled, description } = item;
        return (<div style={{ display: 'block' }}>
            <Title level={5} style={{ display: 'inline-block', minWidth: '200px', fontWeight: 500 }} >{description}</Title>
            <Switch checked={isEnabled} onChange={(status) => onSwitchChange(item, status)} />
        </ div>)
    }

})

Layout.propTypes = {

};

type LayoutProps = {
    onClose: () => void
}

export type CreateRoleModelHandle = {
    setVisible: (state: boolean) => void,
}


export default Layout;