import axios  from 'axios';
import MockAdapter from "axios-mock-adapter";
import { ApiContextState } from 'axios-types';

import { HttpConfig } from 'configs';
import mockedRoot from '__mocks__';
import { ApiRequest, WrappedResponse } from 'app-api';

export async function configureWith<RequestType, ResponseType>(request : ApiRequest<RequestType>) : Promise<ResponseType> {
    const config = Object.assign({}, HttpConfig, request);
    const { access_token } = request;
            
    const instance = configure<RequestType, ResponseType>(config, access_token);

    const response = await instance.request<RequestType>(config);

    if (isInstanceOfWrappedResponse(response.data)) {
        let schemedData = response.data as unknown as WrappedResponse<ResponseType>;
        return schemedData.data;
    }
    else {
        throw new Error('Response format is malformed. it must be schemed as <WrappedResponse>')
    }
}

// eslint-disable-next-line
export function configure<RequestType, ResponseType> (contextConfig: ApiContextState, accessToken? : string) {
    axios.defaults.headers.common['Authorization'] = accessToken? `Bearer ${accessToken}` : '';

    /* for debugging enable thease interceptors
    
    axios.interceptors.request.use(request => {
        console.log(request);
        // Edit request config
        return request;
    }, error => {
        console.log(error);
        return Promise.reject(error);
    });
    
    axios.interceptors.response.use(response => {
        console.log(response);
        // Edit response config
        return response;
    }, error => {
        console.log(error);
        return Promise.reject(error);
    });
    
    */

    const instance = axios.create(contextConfig);

    if (contextConfig.isMockEnabled) {
        var mock = new MockAdapter(instance, { onNoMatch: "passthrough" });
        mockedRoot(mock);
    }
    return instance
}

export function isInstanceOfWrappedResponse(arg: any): arg is WrappedResponse<ResponseType> {
    let value = (arg as WrappedResponse<ResponseType>);
    return value.status !== undefined;
}
