import { createSelector } from '@reduxjs/toolkit'
import { ApplicationState } from 'reducers'

export const getUserProjects = createSelector((state: ApplicationState) => state, (state : any) => {
    if(Array.isArray(state.auth.projects) && state.auth.projects.length > 0){
        return state.auth.projects as any[];
    }
    else return [];
})

export const getUserDefaultProject = createSelector(getUserProjects, (projects) => {
    if(projects.length > 0){
        return projects[0] as any;
    }
    else return null;
})

export const getUserDefaultProjectId = createSelector(getUserDefaultProject, (project) => project?.id)

export const getIsAuthenticated = createSelector((state: ApplicationState) => state, (state : any) => {
    return state.auth.isAuthenticated
})
