import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Modal, Row, Col, Form, Button, Spin, Select, Tooltip, message, List } from 'antd';
import { useParams } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { useApi } from 'hooks';
import { useSelector } from 'react-redux';
import { getRolesRequest } from 'api/roles.api';
import { getSurveyModuleRequest, getSurveyModuleRoleAccessLevelsRequest, updateSurveyModuleAccessLevelRequest } from 'api/surveys.api';

import './survey-module-roles.model.css';

const { useForm } = Form;
const { Option } = Select;

const Layout = React.forwardRef<RoleModuleAccessGrantModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {
    const messageKey = 'survey-module-access-level-command-key';

    const { projects: currentUserProjects } = useSelector((state : any) => state.auth);
    const [currentProjectId, setCurrentProjectId] = useState<string>();

    const { surveyId, moduleId } = useParams();

    const [form] = useForm();

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [roles, setRoles] = useState<any[]>([]);
    const [candidateRoles, setCandidateRoles] = useState<any[]>([]);
    const [rules, setRules] = useState<any[]>([]);
    const [accessLevels, setAccessLevels] = useState<any[]>([]);
    const [defaultAccessibilityCode, setDefaultAccessibilityCode] = useState<string>('deny');

    const [{
        data: dataGetRoles,
        isSuccess: isGetRolesSuccess,
        loading: loadingGetRoles,
    }, executeGetRolesApi] = useApi<any, any>([]);

    const [{
        data: dataGetAccessLevels,
        isSuccess: isGetAccessLevelsSuccess,
        loading: loadingGetAccessLevels,
    }, executeSurveyModuleRoleAccessLevelsApi] = useApi<any, any>([]);

    const [{
        loading: loadingGetModule,
        data: dataGetModule,
        isSuccess: isSuccessGetModule
    }, executeGetModuleApi] = useApi<any, any>();

    const [{
        error: errorCommand,
        loading: loadingCommand,
        data: dataCommand,
        isSuccess: isSuccessCommand
    }, executeCommandApi] = useApi<any, any>([]);

    useEffect(() => {
        let [firstProject] = currentUserProjects;
        if (firstProject?.id) {
            setCurrentProjectId(firstProject?.id);
        }
    }, [currentUserProjects]);

    useEffect(() => {
        if (Array.isArray(rules) && Array.isArray(roles)) {
            let selectedRoleIds = rules.map((r: any) => r.roleId);
            let filterdRoles = roles.filter((r: any) => !selectedRoleIds.includes(r.id));

            setCandidateRoles(filterdRoles);
        }
    }, [rules, roles]);

    useEffect(() => {
        if (isVisible === true) {
            if (currentProjectId) {
                let roleParams = {
                    'project-id': currentProjectId
                }
                executeGetRolesApi(getRolesRequest(roleParams));
            }
            executeSurveyModuleRoleAccessLevelsApi(getSurveyModuleRoleAccessLevelsRequest());
        }
    }, [isVisible, currentProjectId]);

    useEffect(() => {
        if (isVisible === true && currentProjectId && surveyId && moduleId) {
            executeGetModuleApi(getSurveyModuleRequest(currentProjectId, surveyId, moduleId));
        }
    }, [isVisible, currentProjectId, surveyId, moduleId]);

    useEffect(() => {
        if (isGetRolesSuccess && Array.isArray(dataGetRoles)) {
            setRoles(dataGetRoles);
            setCandidateRoles(dataGetRoles);
        }
    }, [dataGetRoles, isGetRolesSuccess]);

    useEffect(() => {
        if (isGetAccessLevelsSuccess && Array.isArray(dataGetAccessLevels)) {
            setAccessLevels(dataGetAccessLevels);
        }
    }, [dataGetAccessLevels, isGetAccessLevelsSuccess]);

    useEffect(() => {
        if (isSuccessGetModule === true && dataGetModule && dataGetModule.id) {
            let { roleAccess } = dataGetModule;

            if(roleAccess){
                let { defaultAccessibilityCode, levels } = roleAccess[0];
                setDefaultAccessibilityCode(defaultAccessibilityCode);
                if(Array.isArray(levels)){
                    let enrichedItems = levels.map((a : any) => {
                        let { roleId, accessCode } = a;
                        return {
                            ...a,
                            role: roles.find((i: any) => i.id === roleId),
                            accessLevel: accessLevels.find((i: any) => i.code === accessCode)
                        }
                    });
                    setRules(enrichedItems);
                }
            }
        }
    }, [isSuccessGetModule, dataGetModule, roles, accessLevels]);

    useEffect(() => {
        if (isSuccessCommand === true && dataCommand.id) {
            message.success({ content: 'Updated successfully', key: messageKey });
        }
        else if (isSuccessCommand === false) {
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [dataCommand, isSuccessCommand]);

    useImperativeHandle(ref, () => ({
        open() {
            setIsVisible(true);
        }
    }));

    function onAddItem(values: any) {
        let { roleId, accessCode } = values;
        setRules((r: any) => {
            form.resetFields();
            let item = {
                ...values,
                role: candidateRoles.find((i: any) => i.id === roleId),
                accessLevel: accessLevels.find((i: any) => i.code === accessCode)
            }
            return [...r, item];
        })
    }

    function onRemoveItem(id: any) {
        setRules((rs: any[]) => {
            return rs.filter((i: any) => i.roleId !== id);
        })
    }

    function onSave() {
        if (currentProjectId && surveyId && moduleId) {
            message.loading({ content: 'Updating...', key: messageKey });
            let data = {
                defaultAccessibilityCode,
                accessLevels: rules
            };
            executeCommandApi(updateSurveyModuleAccessLevelRequest(currentProjectId, surveyId as string, moduleId as string, data));
        }
    }

    return (
        <>
        {isVisible && <Modal
            title={<div>
                <span>Grant access on roles</span>
                <div style={{ display: 'inline', float: 'right' }}>
                    <Button
                        type='primary'
                        style={{ width: '100px' }}
                        onClick={onSave}
                    >Save</Button>
                    <Button
                        style={{ width: '100px', marginLeft: '10px' }}
                        onClick={() => setIsVisible(false)}
                    >Close</Button>
                </div>
            </div>}
            visible={isVisible}
            destroyOnClose={true}
            closeIcon={<></>}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={800}
            className={'access-roles-modal'}
        >
            <Spin spinning={loadingCommand || loadingGetAccessLevels || loadingGetRoles || loadingGetModule}>
                <Form
                    name="basic"
                    form={form}
                    onFinish={onAddItem}
                    autoComplete="off"
                >
                    <Row style={{ width: '100%', margin: '10px 0px 20px 0px' }}>
                        <Col span={24}>
                            <Tooltip title={`Default access level for module - ${defaultAccessibilityCode}`}>
                                <label style={{ margin: '0px 10px' }} >
                                    Default access level for module
                                    <Select
                                        showSearch
                                        placeholder="Select default access level"
                                        style={{ margin: '0px 10px', minWidth: '200px' }}
                                        onChange={(value: any) => setDefaultAccessibilityCode(value)}
                                        value={defaultAccessibilityCode}
                                        defaultValue={'deny'}
                                    >
                                        {accessLevels.map((role: any) => {
                                            let { code, name } = role;
                                            return <Option key={code} value={code}>{name}</Option>
                                        })}
                                    </Select>
                                </label>
                            </Tooltip>

                        </Col>
                    </Row>
                    <hr />
                    <Row style={{ width: '100%', marginTop: '20px' }}>
                        <Col span={12}>
                            <Form.Item
                                label={<></>}
                                name="roleId"
                                rules={[{ required: true, message: 'Please select role!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select role"
                                    style={{ width: '100%', padding: '0px 10px' }}
                                >
                                    {candidateRoles.map((role: any) => {
                                        let { id, name } = role;
                                        return <Option key={id} value={id}>{name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>

                            <Form.Item
                                label={<></>}
                                name="accessCode"
                                rules={[{ required: true, message: 'Please select access level!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select access level"
                                    style={{ width: '100%', padding: '0px 10px' }}
                                >
                                    {accessLevels.map((role: any) => {
                                        let { code, name } = role;
                                        return <Option key={code} value={code}>{name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button
                                type='primary'
                                style={{ width: '100%', padding: '0px 10px' }}
                                htmlType={'submit'}
                            >Add Item</Button>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ width: '100%' }}>
                    <Col span={20} offset={2}>
                        <List
                            itemLayout="horizontal"
                            dataSource={rules}
                            renderItem={renderItem}
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>}
        </>
    );

    function renderItem(item: any, index: number) {
        let { roleId, role, accessLevel } = item;
        return <List.Item>
            <List.Item.Meta
                title={role?.name}
                description={accessLevel?.name}
            />
            <Tooltip title="remove" style={{ float: 'right' }} >
                <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => onRemoveItem(roleId)}
                />
            </Tooltip>
        </List.Item>
    }


})


export type RoleModuleAccessGrantModelHandle = {
    open: () => void,
}

Layout.propTypes = {
};

type LayoutProps = {
    onClose: () => void
}

export default Layout;