import MockAdapter from "axios-mock-adapter/types";

// register mock definitions with __mock__ index file
export default function name(adapter: MockAdapter) {


  adapter
    .onGet("/users")
    .reply(function (config) {
      return [
        200,
        {
          status: 200,
          data: {

          }
        },
      ];
    });

    adapter
    .onPost("/user")
    .reply(function (config) {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve([200, {
            status: 200,
            data: {
              isSuccess: true
            }
          }]);
        }, 1000);
      });
    });
}
