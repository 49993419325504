import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NavigateSignin } from 'constants/paths';

export default function Layout(props : LayoutProps) {
    const navigate = useNavigate();

    function handleSignUp(){
         
    }

    function handleGotoSignIn(){
        navigate(NavigateSignin)
    }

    return (
        <div>
            <h1>this is Sign up page (sign up form goes here)</h1>
            <button onClick={() => handleSignUp()}>Sign up</button>
            <button onClick={() => handleGotoSignIn()}>Go to Sign In</button>
        </div>
    );
}

Layout.propTypes = { 
    
};

type LayoutProps = { 
}