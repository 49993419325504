

export var NavigateApp = "/app"
export var NavigateHome = "/home"
export var NavigateSignin = "/signin"
export var NavigateSignup = "/signup"
export var NavigateResetPassword = "/reset-password"
export var NavigateChangePassword = "/app/change-password"
export var NavigateNotFound = "/notfound"
export var NavigateProfile = "/app/profile"
export var NavigateStatistics = "/app/statistics"