import localforage from 'redux-persist/lib/storage'
import { PersistConfig, persistReducer } from "redux-persist";

const persistConfig: PersistConfig<any> = {
    key: "root",
    version: 1,
    storage: localforage,
    blacklist: ['auth'],
};

export default function create(reducers: any) {
    const persistedReducer = persistReducer(
        persistConfig,
        reducers
    );

    return persistedReducer;
};