import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Input, Button, Spin, Tooltip, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { deleteLocationRequest, getLocationsRequest } from 'api/locations.api';
import { useApi } from 'hooks';
import Table from 'components/table';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import EditProjectLocationModel, { EditProjectLocationModelHandle } from './edit-project-location.model';
import debounce from 'lodash.debounce';

const { Search } = Input;

export default function Layout(props: LayoutProps) {
    const dispatch = useAppDispatch();

    const { projectId } = useParams();

    const [locations, setLocations] = useState<any[]>([]);
    const [confirmDeleteLocationId, setConfirmDeleteLocationId] = useState<number>(-1);

    const refEditProjectLocationModel = useRef<EditProjectLocationModelHandle>(null);

    const [{
        loading: loadingGetLocations,
        data: dataGetLocations,
        isSuccess: isSuccessGetLocations
    }, executeGetLocationsApi] = useApi<any, any>();

    const [{
        loading: loadingDeleteLocation,
        isSuccess: isSuccessDeleteLocation
    }, executeDeleteLocationApi] = useApi<any, any>();

    useEffect(() => {
        dispatch(setSectionHeadTitle("Project Locations"));
    }, []);

    useEffect(() => {
        fetchLocations();
    }, [projectId]);

    useEffect(() => {
        if (isSuccessGetLocations && Array.isArray(dataGetLocations)) {
            setLocations(dataGetLocations);
        }
    }, [isSuccessGetLocations, dataGetLocations]);

    useEffect(() => {
        if (isSuccessDeleteLocation === true) {
            setConfirmDeleteLocationId(-1);
            fetchLocations();
        }
    }, [isSuccessDeleteLocation]);


    const debouncedLocationSearch = useCallback(
        debounce(nextValue => {
            if (nextValue && nextValue.length) {
                let params = { 'filter-key': nextValue };
                projectId && executeGetLocationsApi(getLocationsRequest(projectId, params));
            }
            else {
                projectId && executeGetLocationsApi(getLocationsRequest(projectId));
            }
        }, 500),
        [], // will be created only once initially
    );

    function onHandleCreate() {
        refEditProjectLocationModel.current?.openForCreate('country')
    }

    function onHandleChildCreate(location: any) {
        let { id, type } = location;
        refEditProjectLocationModel.current?.openForCreateChild(id, type);
    }

    function onHandleEdit(id: number) {
        refEditProjectLocationModel.current?.openForEdit(id);
    }

    function onHandleDelete(id: string) {
        projectId && executeDeleteLocationApi(deleteLocationRequest(projectId, id));
    }

    function fetchLocations() {
        projectId && executeGetLocationsApi(getLocationsRequest(projectId));
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Actions',
            key: 'key',
            dataIndex: 'key',
            align: 'center' as 'center',
            render: (text: any, record: any) => (
                <div>
                    <Tooltip title="Create child location" placement="bottom" className='action' >
                        <Button
                            onClick={() => onHandleChildCreate(record)}
                            icon={<PlusOutlined />}
                            disabled={!(record.type === 'country' || record.type === 'region')}
                        >
                        </Button>
                    </Tooltip>
                    <Tooltip title="Edit location" placement="bottom" className='action' >
                        <Button
                            onClick={() => onHandleEdit(record.id)}
                            icon={<EditOutlined />}
                            style={{ 'marginLeft': '3%', }}
                        >
                        </Button>
                    </Tooltip>
                    <Popconfirm
                        title="Delete location?"
                        visible={confirmDeleteLocationId === record.id}
                        onConfirm={() => onHandleDelete(record.id)}
                        okButtonProps={{ loading: loadingDeleteLocation }}
                        onCancel={() => setConfirmDeleteLocationId(-1)}
                    >
                        <Tooltip title="Delete location" placement="bottom">
                            <Button
                                onClick={() => setConfirmDeleteLocationId(record.id)}
                                icon={<DeleteOutlined />}
                                style={{ 'marginLeft': '3%', }}>

                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </ div>
            ),
        },
    ];

    return (
        <>
            <Spin spinning={loadingGetLocations}>
                <Row >
                    <Col span={22} offset={1}>
                        <Row>
                            <Col span={24} >
                                <Search
                                    placeholder="search locations"
                                    onSearch={(value) => debouncedLocationSearch(value)}
                                    style={{ width: `calc(100% - 36px` }}
                                    allowClear={true}
                                    loading={loadingGetLocations}
                                />
                                <div style={{ display: 'inline-block', width: '36px' }}>
                                    <Button
                                        onClick={() => onHandleCreate()}
                                        icon={<PlusOutlined />}
                                        style={{ float: 'right', }}
                                    >
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{ marginTop: '10px' }}>
                    <Col span={22} offset={1}>
                        <div className="table-container" >
                            <Table
                                columns={columns as any}
                                source={locations}
                                style={{ width: 'auto', 'overflowY': 'hidden' }}
                                rowKey="id"
                                size={'small'}
                                maxWidthPerCell={600}
                                height={400}
                                pagination={false}
                                className='project-table'
                            />
                        </div>
                    </Col>
                </Row>
            </Spin>

            <EditProjectLocationModel
                ref={refEditProjectLocationModel}
                onClose={() => {
                    fetchLocations();
                }}
            />

        </>
    )
}

Layout.propTypes = {

};

type LayoutProps = {

}