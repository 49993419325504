import React, { useState, useEffect, useRef } from 'react';
import DisplayLogic, { DisplayLogicHandle } from 'components/display-logic';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useApi } from 'hooks';
import { updateSurveyModuleDisplayLogicRequest, updateSurveyModuleSectionDisplayLogicRequest, updateSurveyModuleSectionQuestionGroupDisplayLogicRequest, getSurveyModuleRequest, getSurveyModuleSectionQuestionGroupRequest, getSurveyModuleSectionRequest, getSurveyModulesForDisplayLogicRequest } from 'api/surveys.api';

export default function Layout(props: any) {
    const messageKey = 'survey-module-display-logic-command-key';
    const currentUserProjectId = useSelector(getUserDefaultProjectId) as string;
    const { surveyId, moduleId, sectionId, questionGroupId } = useParams();

    const displayLogicHandleRef = useRef<DisplayLogicHandle>(null);

    const [existingRule, setExistingRule] = useState<any[]>([]);

    const [{
        loading: loadingGet,
        data: dataGet,
        isSuccess: isSuccessGet
    }, executeGetApi] = useApi<any, any>();

    const [{
        error: errorCommand,
        loading: loadingCommand,
        data: dataCommand,
        isSuccess: isSuccessCommand
    }, executeCommandApi] = useApi<any, any>([]);
    
    useEffect(() => {
        if (currentUserProjectId && surveyId && moduleId) {
            let params = { 'prior-to-module': moduleId } as any;
            if (sectionId) {
                if(questionGroupId){
                    params['prior-to-question-group'] = questionGroupId;
                    executeGetApi(getSurveyModuleSectionQuestionGroupRequest(currentUserProjectId, surveyId, moduleId, sectionId, questionGroupId));
                }
                else{
                    params['prior-to-section'] = sectionId;
                    executeGetApi(getSurveyModuleSectionRequest(currentUserProjectId, surveyId, moduleId, sectionId));
                }
            }
            else {
                executeGetApi(getSurveyModuleRequest(currentUserProjectId, surveyId, moduleId));
            }
        }
    }, [currentUserProjectId, surveyId, moduleId]);

    useEffect(() => {
        if (isSuccessCommand === true && dataCommand.id) {
            message.success({ content: 'Updated successfully', key: messageKey });
        }
        else if (isSuccessCommand === false) {
            message.error({ content: errorCommand, key: messageKey });
        }
    }, [dataCommand, isSuccessCommand]);

    useEffect(() => {
        if (isSuccessGet === true && dataGet.rule) {
            setExistingRule(dataGet.rule);
        }
    }, [dataGet, isSuccessGet]);

    return <DisplayLogic ref={displayLogicHandleRef} existingRule={existingRule} onSave={(data) => {
        if (currentUserProjectId && surveyId && moduleId) {
            message.loading({ content: 'Updating...', key: messageKey });
            if (questionGroupId) {
                executeCommandApi(updateSurveyModuleSectionQuestionGroupDisplayLogicRequest(currentUserProjectId, surveyId as string, moduleId as string, sectionId as string, questionGroupId as string, data));
            }
            else if (sectionId) {
                executeCommandApi(updateSurveyModuleSectionDisplayLogicRequest(currentUserProjectId, surveyId as string, moduleId as string, sectionId as string, data));
            }
            else {
                executeCommandApi(updateSurveyModuleDisplayLogicRequest(currentUserProjectId, surveyId as string, moduleId as string, data));
            }
        }
    }} isLoading={loadingCommand || loadingGet} />
}