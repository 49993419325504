import { createIdentityRequest } from 'api/auth.api';
import { IdentityInfo } from 'app-system';
import { configureWith } from 'providers/ApiClient';
import { ApplicationState } from 'reducers';
import { createAppAsyncThunk } from 'store';

export const fetchIdentity = createAppAsyncThunk<any, any>(
    'fetch_identity',
    async (_ : undefined, { getState }) => {
        let value = {} as IdentityInfo;
        let state = getState() as ApplicationState;
        let token = state?.auth?.accessToken
        if (token) {
            let request = createIdentityRequest();
            request.access_token = token

            const response = await configureWith(request);
            let { permissions, isPasswordChangeRequested, user, roles, projects } = response as any;
            if (Array.isArray(permissions)) {
                value.permissions = permissions.map((i: any) => i.code).reduce((a: any, v: any) => ({ ...a, [`${v}`]: true }), {})
            }
            else{
                value.permissions = [];
            }
            value.isPasswordChangeRequested = isPasswordChangeRequested;
            value.user = user;

            if(Array.isArray(roles)){
                value.roles = roles as any;
                
                let hasSystemRole = roles.some((i : any) => i.isSystem === true);
                value.hasSystemRole = hasSystemRole;
            }
            else{
                value.roles = [];
                value.hasSystemRole = false;
            }

            value.roles = (Array.isArray(roles) ? roles : []) as any;
            value.projects = (Array.isArray(projects) ? projects : []) as any;
        }

        return value as any
    })


