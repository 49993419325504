import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Row, Col, Form, Input, Button, Switch, Spin, Modal, Result, FormItemProps, Tabs, List, Typography } from 'antd';

import { createRoleRequest, getRoleRequest, updateRoleRequest } from 'api/roles.api';

import useApi from 'hooks/useApi';

import './edit-role.model.css';
import { useParams } from 'react-router-dom';

const { useForm } = Form;
const { TabPane } = Tabs;
const { Title } = Typography;

const Layout = React.forwardRef<EditRoleModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const { projectId } = useParams();
    
    const [form] = useForm();

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [isResultVisible, setIsResultVisible] = useState(false);

    const [role, setRole] = useState<any>();
    const [permissionGroups, setPermissionGroups] = useState<any>([]);

    const [{
        loading: loadingGetRole,
        data: dataGetRole,
        isSuccess: isGetRoleSuccess,
    }, executeGetRoleApi] = useApi<any, any>();

    const [{
        loading: loadingCreateRole,
        data: dataCreateRole,
        isSuccess: isRoleCreationSuccess,
        error: errorCreateRole
    }, executeCreateRoleApi] = useApi<any, any>();

    useImperativeHandle(ref, () => ({
        setVisible(state: boolean) {
            setIsVisible(state)
            if (state) {
                setIsCreation(true)
                form.resetFields();
                setIsResultVisible(false)
            }
        },
        openForEdit(id: string) {
            if (id) {
                setIsCreation(false)
                executeGetRoleApi(getRoleRequest(id))
                form.resetFields();
                setIsVisible(true)
                setIsResultVisible(false)
            }
        }
    }));

    useEffect(() => {
        if (!isVisible) {
            typeof onCloseCallback === 'function' && onCloseCallback()
        }
    }, [isVisible]);

    useEffect(() => {
        if (isGetRoleSuccess) {
            let { name, permissionGroups: rolePermissionGroups } = dataGetRole;
            form.setFields([
                {
                    name: 'name',
                    value: name,
                },
            ]);
            if (Array.isArray(rolePermissionGroups)) {
                setPermissionGroups(rolePermissionGroups);
            }
            setRole(dataGetRole);
        }
    }, [dataGetRole, isGetRoleSuccess]);

    useEffect(() => {
        if (isRoleCreationSuccess) {
            form.resetFields();
            setIsResultVisible(true);
        }
        else if (errorCreateRole) {
            form.setFields([
                {
                    name: 'name',
                    errors: [errorCreateRole],
                },
            ]);
        }
    }, [isRoleCreationSuccess, dataCreateRole, errorCreateRole]);

    function onSwitchChange(record: any, status: boolean) {
        setPermissionGroups((groups: any[]) => groups.map((group: any) => {
            let { permissions } = group;
            return {
                ...group,
                permissions: permissions.map((p: any) => {
                    if (p.id === record.id) {
                        p.isEnabled = status;
                    }
                    return p;
                })
            };
        }) as any)
    }

    function onFinish(values: any) {
        let data = {
            ...values,
            projectId,
            permissionGroups: permissionGroups
        }

        if (isCreation) {
            executeCreateRoleApi(createRoleRequest(data))
        }
        else {
            let updatedData = {
                ...role,
                ...data
            }
            executeCreateRoleApi(updateRoleRequest(updatedData))
        }
    };

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    return (
        <Modal
            title={`${isCreation ? 'Create New' : 'Update'} Role`}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={1100}
            style={{ top: 10 }}
        >
            {isResultVisible ? renderResult() : renderForm()}
        </Modal>
    );

    function renderForm() {
        let isSpinning = loadingCreateRole || loadingGetRole
        return (
            <Spin spinning={isSpinning}>
                <Row>
                    <Col span={20} offset={3}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={onFinish}
                            autoComplete="off"
                        >

                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please input your name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input />
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} >
                                    <Tabs defaultActiveKey="1" tabPosition={'top'} style={{ height: 220 }}>
                                        {permissionGroups.map((pg: any) => renderPermissionGroup(pg))}
                                    </Tabs>
                                </Col>
                            </Row>

                            <Form.Item wrapperCol={{ offset: 19, span: 4 }} style={{ 'marginTop': '5%' }}>
                                <Button type="primary" htmlType="submit">
                                    Update
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>
            </Spin>
        )
    }

    function renderResult() {
        return (
            <Result
                status="success"
                title={
                    <>
                        <h4>{`Role ${isCreation ? 'Created' : 'Updated'} Successfully`}</h4>
                        <h5>{isCreation && `name : ${dataCreateRole.name}`}</h5>
                    </>
                }
                extra={[
                    <Button key="back" onClick={() => setIsVisible(false)}>Close</Button>,
                ]}
            />
        );
    }

    function renderPermissionGroup(item: any) {
        let { id, description, permissions } = item;
        return (<TabPane tab={description} key={id}>
            <List
                grid={{ gutter: 4, column: 1 }}
                dataSource={permissions}
                renderItem={(item: any, index: number) => renderPermission(item, index)}
            />
        </TabPane>)
    }

    function renderPermission(item: any, index: number) {
        let { isEnabled, description } = item;
        return (<Row>
            <Col span={18} offset={4} >
                <Title level={5} style={{ display: 'inline-block', minWidth: '400px', fontWeight: 500 }} >{description}</Title>
                <Switch checked={isEnabled} onChange={(status) => onSwitchChange(item, status)} />
            </ Col>
        </Row>)
    }

})

Layout.propTypes = {

};

type LayoutProps = {
    onClose: () => void
}

export type EditRoleModelHandle = {
    setVisible: (state: boolean) => void,
    openForEdit: (id: string) => void,
}


export default Layout;