import React, { useEffect, useState } from 'react';
import { useApi, useTranslate } from 'hooks';
import { useAppDispatch } from 'store';
import { Col, Row, Select, Spin, Tabs } from 'antd';
import './index.scss';
import { setSectionHeadTitle } from 'reducers/system.reducer';
import { useSelector } from 'react-redux';
import { getUserDefaultProjectId } from 'selectors/auth.selectors';
import { getSurveysRequest } from 'api/surveys.api';

import SurveyAvailabilityDashboardContent from './survey-availability-dashboard-content';
import ModuleAvailabilityDashboardContent from './module-availability-dashboard-content';
import HouseholdRosterDashboardContent from './household-roster-dashboard-content';


const { Option } = Select;

export default function Layout(props: LayoutProps) {
    const dispatch = useAppDispatch();
    const translate = useTranslate();
    
    const currentUserProjectId = useSelector(getUserDefaultProjectId);
    

    return <Spin spinning={false}>
        <Row className='statistics' >
            <Col span={20} offset={2}>
                <Tabs>
                    <Tabs.TabPane tab="Survey availability dashboard" key="item-1">
                        <SurveyAvailabilityDashboardContent/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Module completion dashboard" key="item-2">
                        <ModuleAvailabilityDashboardContent/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Household Roster dashboard" key="item-3">
                        <HouseholdRosterDashboardContent/>
                    </Tabs.TabPane>
                </Tabs>
            </Col>
        </Row>
    </Spin>
}

Layout.propTypes = {

};

type LayoutProps = {

}