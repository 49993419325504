import { configureStore, Action, AsyncThunkPayloadCreator, AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { persistStore } from "redux-persist";
import { ThunkAction } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import logger from 'redux-logger';

import rootReducer, { ApplicationState } from 'reducers'
import persistedReducer from 'reducers/persist.reducer'
import { isDevelopment } from 'configs';
import { AsyncThunkConfig } from 'reduxjs-toolkit';

const store = configureStore({
    reducer: persistedReducer(rootReducer),
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleWares = getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
        });
        if (isDevelopment) {
            defaultMiddleWares.concat(logger);
        }
        return defaultMiddleWares;
    },
    devTools: isDevelopment,
})

const persistor = persistStore(store);

export const useAppDispatch = () => useDispatch()

export function createAppAsyncThunk<Returned, ThunkArg = any>(
    type: string,
    thunk: AsyncThunkPayloadCreator<Returned, ThunkArg>,
): AsyncThunk<Returned, ThunkArg, AsyncThunkConfig>  {
    return createAsyncThunk<Returned, ThunkArg, AsyncThunkConfig>(
        type,
        async (arg, thunkAPI) => {
            try {
                // do some stuff here that happens on every action
                return await thunk(arg, thunkAPI)
            } catch (err) {
                // do some stuff here that happens on every error
                return thunkAPI.rejectWithValue(null)
            }
        },
    )
}
    

export { store, persistor }

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, ApplicationState, unknown, Action>
export type AppDispatch = typeof store.dispatch



