import React, { useEffect, useState } from 'react';
import jQuery from 'jquery';

import './index.css';
import WrappedFormBuilder from 'components/form-builder';

(window as any).jQuery = jQuery;
(window as any).$ = jQuery;

export default function Layout(props: LayoutProps) {

    const [questions, setQuestions] = useState<any>([]);

    useEffect(() => {
        setQuestions([ ]);
    }, []);

    async function onSaveHandle(data: any) {
        console.log(data);
        console.log(JSON.stringify(data));
    }

    return (
        <div style={{ paddingBottom : '50px' }}>
            <WrappedFormBuilder
                key={'new-question-group'}
                questions={questions}
                surveyId={'test-survey'}
                onSave={onSaveHandle}
            />
        </div>
    );

}

Layout.propTypes = {

};

type LayoutProps = {

}