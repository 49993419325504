import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Row, Col, Form, Input, Button, Spin, Select, Modal, Result, FormItemProps, message, Tooltip, Typography } from 'antd';

import { createUserRequest, getUserRequest, updateUserRequest } from 'api/users.api';
import { getRolesRequest } from 'api/roles.api';

import useApi from 'hooks/useApi';

import './edit-user.model.css';

import { getProjectsRequest } from 'api/projects.api';
import usePermissions from 'hooks/usePermissions';
import { useSelector } from 'react-redux';
import { CopyOutlined, RedoOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const { useForm } = Form;
const { Option } = Select;
const { Title } = Typography;

const Layout = React.forwardRef<EditUserModelHandle, LayoutProps>(({ onClose: onCloseCallback }, ref) => {

    const [form] = useForm();
    const { user: currentUser, projects : userProjects } = useSelector((state : any) => state.auth);

    const { projectId } = useParams();

    const [roles, setRoles] = useState<any[]>([]);
    const [projects, setProjects] = useState<any[]>([]);
    const [candidateProjects, setCandidateProjects] = useState<any[]>([]);
    const [candidateReportingPersons, setCandidateReportingPersons] = useState<any[]>([]);

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isCreation, setIsCreation] = useState<boolean>(true);
    const [isResultVisible, setIsResultVisible] = useState<boolean>(false);
    const [hasSystemRoleSelected, setHasSystemRoleSelected] = useState<boolean>(false);
    const [user, setUser] = useState({});

    const [hasUserAssignPermission] = usePermissions(['permission-global:user:assign']);

    const [{
        loading: loadingCommandUser,
        data: dataCommandUser,
        isSuccess: isCommandSuccess,
        error: errorCommandUser
    }, executeCommandApi] = useApi<any, any>();

    const [{
        loading: loadingGetUser,
        data: dataGetUser,
        isSuccess: isUserGetSuccess
    }, executeGetUserApi] = useApi<any, any>();

    const [{
        data: dataGetRoles,
        isSuccess: isGetRolesSuccess
    }, executeGetRolesApi] = useApi<any, any>([]);

    const [{
        data: dataGetProjects,
        isSuccess: isGetProjectsSuccess
    }, executeGetProjectsApi] = useApi<any, any>([]);

    useImperativeHandle(ref, () => ({
        setVisible(state: boolean) {
            setIsVisible(state)
            if (state) {
                setIsCreation(true);
                form.resetFields();
                setIsResultVisible(false);
            }
        },
        openForEdit(id: string) {
            if (id) {
                setIsCreation(false);
                executeGetUserApi(getUserRequest(id));
                form.resetFields();
                setIsVisible(true);
                setIsResultVisible(false);
            }
        }
    }));

    useEffect(() => {
        if (isVisible) {
            let [ firstProject ] = userProjects;
            let roleParams = { 
                'project-id' : projectId || firstProject?.id
            }
            executeGetRolesApi(getRolesRequest(roleParams));

            let { id } = currentUser;
            let projectParams = { 'user-id': id };
            executeGetProjectsApi(getProjectsRequest(projectParams));
            
            setCandidateReportingPersons([currentUser]);
            form.setFields([
                {
                    name: 'reportingPersonId',
                    value: currentUser?.id,
                }
            ]);
        }
    }, [isVisible, currentUser]);

    useEffect(() => {
        if (isUserGetSuccess) {
            setUser(dataGetUser);
            let { roles: userRoles, projects, ...rest } = dataGetUser;
            let data = {
                ...rest
            }
            let hasSystemRoleId = false;
            if (Array.isArray(userRoles)) {
                let roleIds = userRoles.map(i => i.id);
                data.roleIds = roleIds;
                hasSystemRoleId = hasSystemRole(roles, roleIds);
                setHasSystemRoleSelected(hasSystemRoleId);
            }
            if (!hasSystemRoleId && Array.isArray(projects) && projects.length > 0) {
                data.projectId = projects[0].id;
            }
            form.setFieldsValue(data);
        }
    }, [dataGetUser, isUserGetSuccess]);

    useEffect(() => {
        if (isCommandSuccess === true) {
            setIsResultVisible(true);
        }
        else if (isCommandSuccess === false) {
            message.error(errorCommandUser);
        }
    }, [dataCommandUser, isCommandSuccess]);

    useEffect(() => {
        if (hasUserAssignPermission && !projectId) {
            setCandidateProjects(projects);
        }
        else {
            if (Array.isArray(projects)) {
                let project = null;
                if (projectId) {
                    // eslint-disable-next-line eqeqeq
                    project = projects.find((i: any) => i.id == projectId);
                }
                if (project === null && projects.length > 0) {
                    project = projects[0];
                }

                if (project) {
                    form.setFields([
                        {
                            name: 'projectId',
                            value: project?.id,
                        },
                        {
                            name: 'password',
                            value: project?.defaultPassword,
                        },
                    ]);
                }
            }
            setCandidateProjects(projects as any);
        }
    }, [projects, projectId, hasUserAssignPermission]);

    useEffect(() => {
        if (isGetRolesSuccess && Array.isArray(dataGetRoles)) {
            let items = dataGetRoles.map((i: any) => ({ ...i, isEnabled: true })) as any[];
            setRoles(items);
        }
    }, [dataGetRoles, isGetRolesSuccess]);

    useEffect(() => {
        if (isGetProjectsSuccess && Array.isArray(dataGetProjects)) {
            setProjects(dataGetProjects);
        }
    }, [dataGetProjects, isGetProjectsSuccess]);

    useEffect(() => {
        if (!isVisible) {
            typeof onCloseCallback === 'function' && onCloseCallback();
        }
    }, [isVisible]);

    function onModelCommand(state: boolean) {
        if (state) {
            form.resetFields();
            setIsResultVisible(false);
        }
    };

    function onFinish(values: any) {
        if (isCreation) {
            executeCommandApi(createUserRequest(values))
        }
        else {
            let data = {
                ...user,
                ...values
            }
            executeCommandApi(updateUserRequest(data))
        }
    };

    function onRoleChange(value: number[]) {
        let selecteRoles = roles.filter((i: any) => value.includes(i.id)) as any;
        let hasSystemRoleId = hasSystemRole(roles, value);
        setRoles((items: any) => {
            return items.map((i: any) => {
                return {
                    ...i,
                    isEnabled: !hasSystemRoleId
                }
            });
        });
        if (hasSystemRoleId) {
            let systemRoles = selecteRoles.filter((i: any) => i.isSystem === true);
            let ids = systemRoles.map((i: any) => i.id)
            form.setFields([
                {
                    name: 'roleIds',
                    value: ids,
                },
                {
                    name: 'projectId',
                    value: null,
                },
            ]);
        }
        setHasSystemRoleSelected(hasSystemRoleId);
    }

    function handleProjectChange(selectedProjectId: any) {
        let project = candidateProjects.find(i => i.id === selectedProjectId);
        if (project) {
            form.setFields([
                {
                    name: 'password',
                    value: project?.defaultPassword,
                    errors: []
                },
            ]);
        }
    }

    function hasSystemRole(items: any[], candidates: any[]) {
        let selecteRoles = items.filter((i: any) => candidates.includes(i.id)) as any;
        let hasSystemRole = selecteRoles.some((i: any) => i.isSystem === true);

        return hasSystemRole;
    }

    function regeneratePassword() {
        let value = generatePassword();
        form.setFields([
            {
                name: 'password',
                value: value,
                errors: []
            },
        ]);
    }

    function copyPassword() {
        let value = form.getFieldValue('password');
        navigator.clipboard.writeText(value)
    }

    function generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%&*",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const layout = {
        labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 20 }, md: { span: 20 }, lg: { span: 20 } }
    }

    const formItemProps = {
        labelCol: { ...layout.labelCol },
        wrapperCol: { ...layout.wrapperCol },
        labelAlign: "left"
    } as FormItemProps<any>

    return (
        <Modal
            title={(isCreation ? 'Create new user' : 'Edit user')}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsVisible(false)}
            footer={null}
            forceRender={true}
            width={800}
        >
            {isResultVisible ? renderResult() : renderForm()}
        </Modal>
    );

    function renderForm() {
        let isSpinning = loadingCommandUser || loadingGetUser
        return (
            <Spin spinning={isSpinning}>
                <Row className={'create-user-form'}>
                    <Col span={20} offset={3}>
                        <Form
                            name="basic"
                            form={form}
                            initialValues={{}}
                            onFinish={onFinish}
                            autoComplete="off"
                        >

                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[{ required: true, message: 'Please input your first name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input />
                                    </Form.Item>

                                </Col>
                                <Col span={12} >
                                    <Form.Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[{ required: true, message: 'Please input your last name!' }]}
                                        {...formItemProps}
                                    >
                                        <Input />
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        {...formItemProps}
                                        rules={[{
                                            type: 'email',
                                            message: "value is not valid email",
                                        }]}
                                        validateTrigger="onBlur"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    <Form.Item
                                        label="Contact Number"
                                        name="contactNumber"
                                        {...formItemProps}
                                        rules={[
                                            {
                                                pattern: /^(?:\d*)$/,
                                                message: "value should be in phone number format",
                                            },
                                            {
                                                max: 16,
                                                message: "value should be less than 16 digits",
                                            },
                                        ]}
                                        validateTrigger="onBlur"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Roles"
                                        name="roleIds"
                                        rules={[{ required: true, message: 'Please select roles!' }]}
                                        {...formItemProps}
                                    >

                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder={'select user roles'}
                                            defaultActiveFirstOption={false}
                                            onChange={onRoleChange}
                                        >
                                            {roles.map((d: any) => (<Option
                                                key={d.id}
                                                value={d.id}
                                                disabled={!d.isEnabled}
                                            >
                                                {d.name}
                                            </Option>))}
                                        </Select>

                                    </Form.Item>

                                </Col>

                                <Col span={12} >
                                    <Form.Item
                                        label="Project"
                                        name="projectId"
                                        rules={[{ required: !hasSystemRoleSelected, message: 'Please select project!' }]}
                                        {...formItemProps}
                                    >
                                        <Select
                                            placeholder={'select user project'}
                                            defaultActiveFirstOption={false}
                                            onChange={handleProjectChange}
                                            disabled={!hasUserAssignPermission || hasSystemRoleSelected}
                                        >
                                            {candidateProjects.map((d: any) => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} >
                                    <Form.Item
                                        label="Reporting person"
                                        name="reportingPersonId"
                                        rules={[{ required: true, message: 'Please select reporting person!' }]}
                                        {...formItemProps}
                                        validateTrigger="onBlur"
                                    >

                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder={'select user reporting person'}
                                            defaultActiveFirstOption={true}
                                        >
                                            {candidateReportingPersons.map((d: any) => <Option key={d.id} value={d.id}>{d.name}</Option>)}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                </Col>
                            </Row>

                            {isCreation && <>
                                <br />
                                <br />
                                <Row>
                                    <Col span={20} offset={1}>
                                        <Form.Item
                                            name="password"
                                            rules={[{ required: true, message: 'Please insert or generate password!' }]}
                                        >
                                            <Input
                                                prefix={<Title level={5} style={{ margin: '5px 50px' }}>Password</Title>}
                                                suffix={
                                                    <>
                                                        <Tooltip title="Regenerate">
                                                            <RedoOutlined onClick={regeneratePassword} style={{ margin: '0px 5px' }} />
                                                        </Tooltip>

                                                        <Tooltip title="Copy">
                                                            <CopyOutlined onClick={copyPassword} style={{ margin: '0px 5px' }} />
                                                        </Tooltip>
                                                    </>
                                                }
                                                allowClear={true}
                                                className='password-field'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>}

                            <Form.Item wrapperCol={{ offset: 19, span: 4 }} style={{ 'marginTop': '5%' }}>
                                <Button type="primary" htmlType="submit">
                                    {isCreation ? 'Create' : 'Update'}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>
            </Spin>
        )
    }

    function renderResult() {
        return (
            <Result
                status="success"
                title={
                    <div>
                        <h4>{`User ${isCreation ? 'Created' : 'Updated'} Successfully`}</h4>
                        <h5>{isCreation && `Username : ${dataCommandUser.username}`}</h5>
                    </div>
                }
                extra={[
                    <div key={'result-key'}>
                        {isCreation && <Button type="primary" key="create" onClick={() => onModelCommand(true)}>
                            Create Another User
                        </Button>}
                        <Button key="back" onClick={() => setIsVisible(false)} style={{ 'marginLeft' : '10px' }} >Close</Button>
                    </div>,
                ]}
            />
        );
    }

})

Layout.propTypes = {

};

type LayoutProps = {
    onClose: () => void
}

export type EditUserModelHandle = {
    setVisible: (state: boolean) => void,
    openForEdit: (id: string) => void,
}


export default Layout;