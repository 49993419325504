import React from 'react';

export default function Layout(props : LayoutProps) {

    return (
        <div> 
            <h1>Not found</h1>
            <h3>What you are looking for is not available</h3>
        </div>
    );
}

Layout.propTypes = { 
    
};

type LayoutProps = {
    
}