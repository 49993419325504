import MockAdapter from "axios-mock-adapter/types";

// register mock definitions with __mock__ index file
export default function name(adapter: MockAdapter) {

  adapter
    .onPost("/signin")
    .reply(function (config) {
      return [
        200,
        {
          status: 200,
          data: {
            isSuccess: true
          }
        },
      ];
    });


  adapter
    .onGet("/permissions")
    .reply(function (config) {
      return [
        200,
        {
          status: 200,
          data: {
            permissions: [],
            menu: {
              children: [
                { code: 'dashboard', name: 'Dashboard', link : 'dashboard' },
                { code: 'create-form', name: 'Create Form', link : 'create-form'  },
                {
                  code: 'users-maintenance',
                  name: 'Users maintenance',
                  children: [
                    { code: 'users', name: 'Users', link : 'users-maintenance/users' },
                    { code: 'roles', name: 'Roles', },
                  ]
                },
                {
                  code: 'reports',
                  name: 'Reports',
                  children: [
                    { code: 'report-1', name: 'Report 1', },
                    { code: 'report-2', name: 'Report 2', },
                  ]
                },
              ]
            }
          }
        },
      ];
    });

    adapter
    .onPost("/signup")
    .reply(function (config) {
      return [
        200,
        {
          isSuccess: true,
        },
      ];
    });

}
