import { getLocaleRequest, getLocaleTypesRequest } from 'api/locales.api';
import { configureWith } from 'providers/ApiClient';
import { ApplicationState } from 'reducers';
import { createAppAsyncThunk } from 'store';

export const changeLocale = createAppAsyncThunk<any, any>(
    'change_locale',
    async (type: string, { getState }) => {
        let state = getState() as ApplicationState;
        let types = state?.locale?.types as any;

        let data = {
            type,
            keys: []
        };

        if(types && types[type] && Array.isArray(types[type].keys) && types[type].keys.length > 0){
            return {
                ...data,
                keys: types[type].keys
            };
        }
        else {
            let request = getLocaleRequest(type);
            const response = await configureWith(request) as any;
    
            if (response[type]) {
                return {
                    ...data,
                    keys: response[type]
                };
            }
        }
        return data;
    })

export const fetchLocale = createAppAsyncThunk<any, any>(
    'fetch_locale',
    async (type: string) => {
        let request = getLocaleRequest(type);
        const response = await configureWith(request) as any;

        if (response[type]) {
            return {
                type: type,
                keys: response[type]
            } as any
        }

        return {} as any
    })

export const fetchLocaleTypes = createAppAsyncThunk<any, any>(
    'fetch_locale_type',
    async (_: string) => {
        let request = getLocaleTypesRequest();
        const response = await configureWith(request) as any;

        if (Array.isArray(response)) {
            return response;
        }

        return [] as any
    })

