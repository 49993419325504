import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { NavigateSignin } from 'constants/paths';
import { Spin } from 'antd';

export default function Layout(props : LayoutProps) {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(NavigateSignin)
    }, []);

    return (
        <Spin spinning={true}> </Spin>
    );
}

Layout.propTypes = { 
    
};

type LayoutProps = { 
    
}