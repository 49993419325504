import { ApiRequest } from "app-api";
import { createQueryParams } from "utils/create-query-params";

export function getProjectsRequest(data: any | void = null) : ApiRequest<any>{
    let url = '/v1/projects';
    if (data) {
        let params = createQueryParams({
            ...data
        })
        url = `/v1/projects${params}`;
    }
    return {
        method: 'GET',
        url
    };
}

export function getProjectRequest(id : string) : ApiRequest<any>{
    return {
        method : 'GET',
        url: `/v1/projects/${id}`
    };
}

export function deleteProjectRequest(id : string) : ApiRequest<any>{
    return {
        method : 'DELETE',
        url: `/v1/projects/${id}`
    };
}

export function createProjectRequest(data : any) : ApiRequest<any>{
    return {
        method : 'POST',
        url: `/v1/projects`,
        data: data
    };
}


export function updateProjectRequest(data : any) : ApiRequest<any>{
    return {
        method : 'PUT',
        url: `/v1/projects`,
        data: data
    };
}

export function getProjectDatasheetsRequest(projectId : string) : ApiRequest<any>{
    return {
        method: 'GET',
        url : `/v1/projects/${projectId}/datasheets`
    };
}
