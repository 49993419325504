import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Typography, Card } from 'antd';

import './index.css'

const { Title } = Typography;

export default function Layout({ caption, icon, path }: LayoutProps) {
    const navigate = useNavigate();

    function handleOnclick() {
        if (path) {
            navigate(path)
        }
    }

    function findImage(path : string) {
        try {
            return require(`../../assets/images/${path}.png`);
        } catch {

        }
    }

    return (
        <Card className='dashboard-tile' style={{ width: 180, minHeight: 180 }} onClick={() => handleOnclick()}>
            <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <img src={findImage(icon)} alt={caption} />
                </Col>

                <Col span={24} className='caption' >
                    <Title level={4} style={{ fontWeight: 400, textAlign: 'center' }}>{caption}</Title>
                </Col>
            </Row>
        </Card>
    );
}

Layout.propTypes = {
    caption: PropTypes.string.isRequired
};

type LayoutProps = {
    caption: string,
    icon: string
    path: string
}